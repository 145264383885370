import { func } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { loadAllClients } from '../../../../../state/action/scheduling/client.action';
import { createProject } from '../../../../../state/action/scheduling/project.action';

import ProjectForm from '../../../../../component/scheduling/project/form/ProjectForm';

export const CreateProjectDetailsContainer = ({ onCancel }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const clients = useSelector(({ client_store }) => client_store.clients);

  useEffect(() => {
    dispatch(loadAllClients());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNext = (payload, onComplete) => {
    dispatch(createProject(payload, navigate, onComplete));
  };

  return <ProjectForm onCancel={onCancel} onSubmit={handleNext} clients={clients} />;
};

CreateProjectDetailsContainer.propTypes = {
  onCancel: func.isRequired
};
