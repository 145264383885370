import { arrayOf, bool, func, instanceOf, node, oneOfType, shape } from 'prop-types';
import React, { useEffect, useLayoutEffect, useState } from 'react';

import { generateId } from '@neslotech/ui-utils';

import {
  DRILLABLE_ELEMENTS,
  getAllCSSMargins,
  getCssValue,
  getNodeBoxModel,
  getPlaceholderStyles
} from '../../../../tool/loader.helper';

import './skeleton-loader.scss';

/**
 * elementRef tells the loader which element to "drill" down
 * into and paint placeholders for.
 *
 * @returns {JSX.Element} children if loading = false
 * or dynamically generated div placeholders.
 *
 * @author bpower
 */
const SkeletonLoader = ({ children, loading, belowBreadcrumb, elementRef }) => {
  const [skeletonElements, setSkeletonElements] = useState([]);
  const [rootNodeMargins, setRootNodeMargins] = useState({});

  const getChildNodes = (element) => {
    if (DRILLABLE_ELEMENTS.includes(element.nodeName.toLowerCase())) {
      const childNodes = element.childNodes;
      let finalNodes = [];
      for (let i = 0; i < childNodes.length; i++) {
        // if a div/section is positioned relatively, we need
        // to preserve the positioning of its child nodes
        if (getCssValue(element, 'position') === 'relative') {
          finalNodes = [...finalNodes, { element, children: childNodes }];
          break;
        } else {
          finalNodes = [...finalNodes, ...getChildNodes(childNodes[i])];
        }
      }

      return finalNodes;
    }

    return [{ element }];
  };

  const calculateElements = () => {
    const currentEl = elementRef?.current;
    if (!currentEl) {
      return;
    }

    let elementsToReplace = [];

    for (let i = 0; i < currentEl.childNodes.length; i++) {
      const childNode = currentEl.childNodes[i];
      elementsToReplace = [...elementsToReplace, ...getChildNodes(childNode)];
    }

    const placeholders = (elementsToReplace ?? []).map(({ element, children }) => ({
      ...getNodeBoxModel(element),
      children: Array.from(children ?? []).map((childNode) => getNodeBoxModel(childNode))
    }));

    setSkeletonElements(placeholders);
  };

  const handleResize = () => {
    calculateElements();
  };

  // https://react.dev/reference/react/useLayoutEffect#reference
  // perform layout measurements before the browser
  // paints/repaints
  useLayoutEffect(() => {
    // the margin on the root skeleton node is
    // initially correct, but after a re-render,
    // elementRef becomes `id=skeleton` node,
    // causing all margins to be 0, in turn causing
    // content layout shift. So we calc "once" here
    // to avoid this behaviour.
    setRootNodeMargins(getAllCSSMargins(elementRef?.current));
  }, [elementRef]);

  useEffect(() => {
    calculateElements();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <div id="skeleton" className={!belowBreadcrumb ? '' : 'below-crumb'} style={rootNodeMargins}>
      {skeletonElements.map((element) => {
        const id = generateId();
        return (
          <div className="placeholder" key={id} style={getPlaceholderStyles(element)}>
            {element.children?.map((childEl) => (
              <div
                className="placeholder child"
                key={`${id}_${generateId()}`}
                style={getPlaceholderStyles(childEl)}
              />
            ))}
          </div>
        );
      })}
      {children}
    </div>
  );
};

SkeletonLoader.defaultProps = {
  loading: false,
  belowBreadcrumb: false
};

SkeletonLoader.propTypes = {
  children: oneOfType([node, arrayOf(node)]).isRequired,
  loading: bool,
  belowBreadcrumb: bool,
  elementRef: oneOfType([
    func, // this should handle custom/React components
    shape({ current: instanceOf(Element) }) // DOM native element
  ]).isRequired
};

export default SkeletonLoader;
