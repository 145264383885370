import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/ui-utils';

import { mapErrors } from '../../tool/error.util';
import {
  getAddUserRequest,
  getChangeUserRoleRequest,
  getLoadUsersRequest,
  getRemoveUserRequest
} from '../tool/api/user.endpoint';

import { addSystemNotice } from '../action/system.action';
import {
  ADD_USER,
  CHANGE_USER_ROLE,
  LOAD_USERS,
  REMOVE_USER,
  SET_ERRORS,
  SET_USER,
  SET_USERS,
  loadUsers
} from '../action/user.action';

export function* performLoadUsers({ onComplete }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadUsersRequest();

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_USERS, users: data });
    yield call(onComplete);
  } catch ({ response, config }) {
    yield put(addSystemNotice('The users could not be found.', SNACK_CRITICAL, config?.skipNotice));

    yield call(onComplete);
  }
}

export function* watchForLoadUsers() {
  yield takeLatest(LOAD_USERS, performLoadUsers);
}

export function* performAddUser({ payload, onSuccess, onComplete }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getAddUserRequest(payload);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_USER, user: data });

    yield put(
      addSystemNotice(
        `${payload.firstName} ${payload.lastName} was added successfully`,
        SNACK_SUCCESS
      )
    );

    yield put(loadUsers(onSuccess));
  } catch ({ response, config }) {
    yield put({ type: SET_ERRORS, errors: mapErrors(response.data) });
    yield put(addSystemNotice('The user could not be added.', SNACK_CRITICAL, config?.skipNotice));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForAddUser() {
  yield takeLatest(ADD_USER, performAddUser);
}

export function* performRemoveUser({ user, onComplete }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getRemoveUserRequest(user?.id);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);

    yield put(
      addSystemNotice(
        `${user.firstName} ${user.lastName} successfully removed from Neslo.`,
        SNACK_SUCCESS
      )
    );

    yield put(loadUsers(onComplete));
  } catch ({ response, config }) {
    yield put(
      addSystemNotice('The user could not be removed.', SNACK_CRITICAL, config?.skipNotice)
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForRemoveUser() {
  yield takeLatest(REMOVE_USER, performRemoveUser);
}

export function* performChangeUserRole({ user, onComplete }) {
  try {
    const payload = { role: user.role };
    // get endpoint and http request options
    const [endpoint, requestOptions] = getChangeUserRoleRequest(user?.id, payload);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);

    yield put(
      addSystemNotice(
        `${user.firstName} ${user.lastName}'s role was changed successfully.`,
        SNACK_SUCCESS
      )
    );

    yield put(loadUsers(onComplete));
  } catch ({ response }) {
    yield put(
      addSystemNotice(
        `An error occurred while attempting to change ${user.firstName} ${user.lastName}'s role. Please try again.`,
        SNACK_CRITICAL
      )
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForPerformChangeUserRole() {
  yield takeLatest(CHANGE_USER_ROLE, performChangeUserRole);
}

export default function* userSaga() {
  yield all([
    watchForLoadUsers(),
    watchForAddUser(),
    watchForRemoveUser(),
    watchForPerformChangeUserRole()
  ]);
}
