import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/ui-utils';

import { RESPONSE_STATUS } from '../../../tool/constant';
import { mapErrors } from '../../../tool/error.util';
import { getLoadCategoriesRequest } from '../../tool/api/kudos/kudos.endpoint';
import {
  getArchiveCategoryRequest,
  getCreateCategoryRequest,
  getReinstateCategoryRequest,
  getUpdateCategoryRequest
} from '../../tool/api/kudos/management.endpoint';

import { SET_CATEGORIES, SET_KUDOS_ERRORS } from '../../action/kudos/kudos.action';
import {
  ARCHIVE_KUDOS_CATEGORY,
  CREATE_KUDOS_CATEGORY,
  LOAD_KUDOS_CATEGORIES,
  REINSTATE_KUDOS_CATEGORY,
  SET_KUDOS_CATEGORIES,
  UPDATE_KUDOS_CATEGORY
} from '../../action/kudos/management.action';
import { addSystemNotice } from '../../action/system.action';

export function* performArchiveCategory({ categoryId, onComplete }) {
  try {
    const [endpoint, requestOptions] = getArchiveCategoryRequest(categoryId);

    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('The kudos category has been archived successfully.', SNACK_SUCCESS));
    yield put({ type: SET_CATEGORIES, categories: [] });
  } catch ({ response, config }) {
    yield put(
      addSystemNotice(
        'The kudos category could not be archived!',
        SNACK_CRITICAL,
        config?.skipNotice
      )
    );
  } finally {
    if (onComplete) {
      yield call(onComplete);
    }
  }
}

export function* watchForArchiveCategory() {
  yield takeLatest(ARCHIVE_KUDOS_CATEGORY, performArchiveCategory);
}

export function* performReinstateCategory({ categoryId, onComplete }) {
  try {
    const [endpoint, requestOptions] = getReinstateCategoryRequest(categoryId);

    yield call(axios, endpoint, requestOptions);

    yield put(
      addSystemNotice('The kudos category has been reinstated successfully.', SNACK_SUCCESS)
    );
    yield put({ type: SET_CATEGORIES, categories: [] });
  } catch ({ response, config }) {
    yield put(
      addSystemNotice(
        'The kudos category could not be reinstated!',
        SNACK_CRITICAL,
        config?.skipNotice
      )
    );
  } finally {
    if (onComplete) {
      yield call(onComplete);
    }
  }
}

export function* watchForReinstateCategory() {
  yield takeLatest(REINSTATE_KUDOS_CATEGORY, performReinstateCategory);
}

export function* performUpdateCategory({ categoryId, payload, onSuccess, onComplete }) {
  try {
    const [endpoint, requestOptions] = getUpdateCategoryRequest(categoryId, payload);

    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice(`${payload.name} changes have been saved.`, SNACK_SUCCESS));
    yield put({ type: SET_CATEGORIES, categories: [] });

    yield call(onSuccess);
  } catch ({ response, config }) {
    if (response?.status === RESPONSE_STATUS.CONFLICT) {
      const error = 'A category with this name already exists';
      yield put(addSystemNotice(error, SNACK_CRITICAL, config?.skipNotice));
      yield put({ type: SET_KUDOS_ERRORS, errors: { name: error } });
    } else {
      yield put(
        addSystemNotice(
          'The kudos category could not be updated!',
          SNACK_CRITICAL,
          config?.skipNotice
        )
      );
    }
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateCategory() {
  yield takeLatest(UPDATE_KUDOS_CATEGORY, performUpdateCategory);
}

export function* performCreateCategory({ payload, onSuccess, onError }) {
  try {
    const [endpoint, requestOptions] = getCreateCategoryRequest(payload);

    yield call(axios, endpoint, requestOptions);

    if (onSuccess) {
      yield call(onSuccess);
    }

    yield put(addSystemNotice(`${payload.name} created successfully.`, SNACK_SUCCESS));
    yield put({ type: SET_CATEGORIES, categories: [] });
  } catch ({ response, config }) {
    if (response?.status === RESPONSE_STATUS.CONFLICT) {
      const error = 'A category with this name already exists';
      yield put(addSystemNotice(error, SNACK_CRITICAL, config?.skipNotice));
      yield put({ type: SET_KUDOS_ERRORS, errors: { name: error } });
    } else {
      yield put({ type: SET_KUDOS_ERRORS, errors: mapErrors(response?.data) });
      yield put(
        addSystemNotice(
          'The kudos category could not be created!',
          SNACK_CRITICAL,
          config?.skipNotice
        )
      );
    }

    if (onError) {
      yield call(onError);
    }
  }
}

export function* watchForCreateCategory() {
  yield takeLatest(CREATE_KUDOS_CATEGORY, performCreateCategory);
}

export function* performLoadKudosCategories({ archived, onComplete }) {
  try {
    const [endpoint, requestOptions] = getLoadCategoriesRequest(archived);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_KUDOS_CATEGORIES, categories: data });
  } catch ({ response, config }) {
    yield put(
      addSystemNotice(
        'The kudos categories data could not be loaded!',
        SNACK_CRITICAL,
        config?.skipNotice
      )
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadKudosCategories() {
  yield takeLatest(LOAD_KUDOS_CATEGORIES, performLoadKudosCategories);
}

export default function* managementSaga() {
  yield all([
    watchForArchiveCategory(),
    watchForReinstateCategory(),
    watchForUpdateCategory(),
    watchForCreateCategory(),
    watchForLoadKudosCategories()
  ]);
}
