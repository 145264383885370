import { bool } from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { isValuePresent } from '@neslotech/ui-utils';

import { DEPARTMENT_OPTIONS, NONE } from '../../../../tool/constant';

import { useProfile } from '../../../../hook/useProfile';

import { Datepicker } from '../../../../common/component/datepicker/Datepicker';
import { FormRow } from '../../../../common/component/form/form-row/FormRow';
import { Input } from '../../../../common/component/input/Input';
import { Select } from '../../../../common/component/select/Select';
import { TextArea } from '../../../../common/component/textarea/TextArea';

const genderOptions = () => [
  {
    label: 'Male',
    value: 'MALE'
  },
  {
    label: 'Female',
    value: 'FEMALE'
  }
];

const lineManagerOptions = (users) => [
  {
    label: 'None',
    value: NONE
  },
  ...users
    .filter((user) => user.active)
    .map(({ person }) => ({
      label: `${person.firstName} ${person.lastName}`,
      value: person.id
    }))
];

const PersonalInfoForm = ({ isAdminEditing }) => {
  const { form, errors, onChange } = useProfile();

  const users = useSelector(({ user_store }) => user_store.users);

  const memoizedUsers = useMemo(() => (isAdminEditing ? users : []), [isAdminEditing, users]);

  const handleChange = (newState) =>
    onChange({
      personalInfo: {
        ...form?.personalInfo,
        ...newState
      }
    });

  return (
    <>
      <FormRow>
        <Input
          label="First Name"
          name="firstName"
          value={form?.personalInfo?.firstName}
          error={errors?.firstName}
          onChange={handleChange}
          required
        />
        <Input
          label="Second Name"
          name="secondName"
          value={form?.personalInfo?.secondName}
          onChange={handleChange}
        />
      </FormRow>
      <FormRow>
        <Input
          label="Last Name"
          name="lastName"
          value={form?.personalInfo?.lastName}
          error={errors?.lastName}
          onChange={handleChange}
          required
        />
        <Input
          label="Initials"
          name="initials"
          value={form?.personalInfo?.initials}
          onChange={handleChange}
        />
      </FormRow>
      <FormRow>
        <Select
          label="Gender"
          name="gender"
          options={genderOptions()}
          value={form?.personalInfo?.gender}
          onChange={handleChange}
          disabled={isAdminEditing}
        />
        <Input
          type="email"
          label="Alternative Email"
          name="alternativeEmail"
          value={form?.personalInfo?.alternativeEmail}
          error={errors?.alternativeEmail}
          onChange={handleChange}
          required
          disabled={isAdminEditing}
        />
      </FormRow>
      <FormRow>
        <Datepicker
          label="Birthday"
          name="birthday"
          value={form?.personalInfo?.birthday}
          onChange={handleChange}
          minDate={new Date(1900, 0)}
          maxDate={new Date()}
          yearDropdownItemNumber={100}
          disabled={isAdminEditing}
        />
        <Input
          label="Phone Number"
          name="phoneNumber"
          value={form?.personalInfo?.phoneNumber}
          error={errors?.phoneNumber}
          onChange={handleChange}
          required
          disabled={isAdminEditing}
        />
      </FormRow>
      <FormRow>
        <Input
          label="Identity Number"
          name="identityNumber"
          value={form?.personalInfo?.identityNumber}
          error={errors?.idNumber}
          required={!isValuePresent(form?.personalInfo?.passportNumber)}
          onChange={handleChange}
          disabled={isAdminEditing}
        />
        <Input
          label="Passport Number"
          name="passportNumber"
          value={form?.personalInfo?.passportNumber}
          error={errors?.passportNumber}
          onChange={handleChange}
          required={!isValuePresent(form?.personalInfo?.identityNumber)}
          disabled={isAdminEditing}
        />
      </FormRow>
      <FormRow>
        <Input
          label="Passport Country"
          name="passportCountry"
          value={form?.personalInfo?.passportCountry}
          error={errors?.passportCountry}
          onChange={handleChange}
          required={isValuePresent(form?.personalInfo?.passportNumber)}
          disabled={isAdminEditing}
        />
        <Input
          label="Tax Number"
          name="taxNumber"
          value={form?.personalInfo?.taxNumber}
          error={errors?.taxNumber}
          onChange={handleChange}
          required
        />
      </FormRow>
      <FormRow>
        <Input
          label="Job Title"
          name="jobTitle"
          value={form?.personalInfo?.jobTitle}
          error={errors?.jobTitle}
          onChange={handleChange}
          required
        />
        {isAdminEditing && (
          <Select
            label="Department"
            name="department"
            options={DEPARTMENT_OPTIONS}
            value={form?.personalInfo?.department}
            error={errors?.department}
            onChange={handleChange}
            required
          />
        )}
      </FormRow>
      {isAdminEditing && (
        <FormRow>
          <Select
            label="Line Manager"
            name="lineManager"
            value={form?.personalInfo?.lineManager}
            error={errors?.lineManager}
            options={lineManagerOptions(memoizedUsers)}
            onChange={handleChange}
          />
        </FormRow>
      )}
      <FormRow fullWidth>
        <TextArea
          label="Bio"
          name="bio"
          value={form?.personalInfo?.bio}
          error={errors?.personalBio}
          onChange={handleChange}
          disabled={isAdminEditing}
        />
      </FormRow>
    </>
  );
};

PersonalInfoForm.defaultProps = {
  isAdminEditing: false
};

PersonalInfoForm.propTypes = {
  isAdminEditing: bool
};

export default PersonalInfoForm;
