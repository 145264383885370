import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPostOptions,
  getHttpPutOptions
} from '@neslotech/ui-utils';

import { getAuthHeaders } from '../../../../tool/auth.util';

import { API_HOST } from '../index';

const usersEndpoint = `${API_HOST}/v1/users`;
const kudosEndpoint = `${API_HOST}/v1/kudos`;
const leadersEndpoint = `${API_HOST}/v1/points/leaders`;
const kudosNominationsEndpoint = `${kudosEndpoint}/nominations`;

const getUserEndpoint = (id) => `${usersEndpoint}/${id}`;
const getRemoveNominationMessageEndpoint = (id) => `${kudosNominationsEndpoint}/${id}/messages`;

const getKudosUserEndpoint = (id) => `${getUserEndpoint(id)}/kudos`;
const getPointsEndpoint = (id) => `${getUserEndpoint(id)}/points`;

const getMostFrequentCategoriesEndpoint = (userId) =>
  `${getKudosUserEndpoint(userId)}/categories/most-frequent`;
const getRemainingPointsEndpoint = (userId) => `${getKudosUserEndpoint(userId)}/remaining-points`;
const getPersonalNominationsEndpoint = (userId) => `${getKudosUserEndpoint(userId)}/nominations`;

const kudosCategoriesEndpoint = `${kudosEndpoint}/categories`;
const mostPopularCategoriesEndpoint = `${kudosCategoriesEndpoint}/nomination-frequency`;

export const getLoadMostFrequentCategoriesRequest = (userId) => [
  getMostFrequentCategoriesEndpoint(userId),
  getHttpGetOptions(getAuthHeaders())
];

export const getLoadRemainingPointsRequest = (userId) => [
  getRemainingPointsEndpoint(userId),
  getHttpGetOptions(getAuthHeaders())
];

export const getLoadRedeemablePointsRequest = (userId) => [
  getPointsEndpoint(userId),
  getHttpGetOptions(getAuthHeaders())
];

export const getLoadLeadersRequest = () => [leadersEndpoint, getHttpGetOptions(getAuthHeaders())];

export const getLoadPersonalNominationsRequest = (userId) => [
  getPersonalNominationsEndpoint(userId),
  getHttpGetOptions(getAuthHeaders())
];

export const getLoadCompanyNominationsRequest = () => [
  kudosNominationsEndpoint,
  getHttpGetOptions(getAuthHeaders())
];

export const getLoadMostPopularCategoriesRequest = () => [
  mostPopularCategoriesEndpoint,
  getHttpGetOptions(getAuthHeaders())
];

export const loadActiveCategoriesRequest = () => [
  kudosCategoriesEndpoint,
  getHttpGetOptions(getAuthHeaders())
];

export const getLoadCategoriesRequest = (archived) => [
  kudosCategoriesEndpoint,
  {
    ...getHttpGetOptions(getAuthHeaders()),
    params: { archived }
  }
];

export const getCreateKudosNominationRequest = (payload) => [
  kudosNominationsEndpoint,
  getHttpPostOptions(payload, getAuthHeaders())
];

export const getRemoveNominationMessageRequest = (nominationId) => [
  getRemoveNominationMessageEndpoint(nominationId),
  getHttpDeleteOptions(getAuthHeaders())
];

export const getToggleLike = (nominationId, likerId) => [
  `${kudosNominationsEndpoint}/${nominationId}/likes/${likerId}/toggle`,
  getHttpPutOptions(null, getAuthHeaders())
];

export const getCreateKudosNominationCommentRequest = (nominationId, payload) => [
  `${kudosNominationsEndpoint}/${nominationId}/comments`,
  getHttpPostOptions({ message: payload }, getAuthHeaders())
];

export const getCreateKudosCommentReplyRequest = (nominationId, commentId, payload) => [
  `${kudosNominationsEndpoint}/${nominationId}/comments/${commentId}/replies`,
  getHttpPostOptions({ message: payload }, getAuthHeaders())
];

export const getLoadNominationCommentsRequest = (nominationId) => [
  `${kudosNominationsEndpoint}/${nominationId}/comments`,
  getHttpGetOptions(getAuthHeaders())
];

export const getRemoveNominationCommentRequest = (nominationId, commentId) => [
  `${kudosNominationsEndpoint}/${nominationId}/comments/${commentId}`,
  getHttpDeleteOptions(getAuthHeaders())
];

export const getUpdateNominationCommentRequest = (nominationId, commentId, payload) => [
  `${kudosNominationsEndpoint}/${nominationId}/comments/${commentId}`,
  getHttpPutOptions({ message: payload }, getAuthHeaders())
];

export const getLoadNominationCommentRepliesRequest = (nominationId, commentId) => [
  `${kudosNominationsEndpoint}/${nominationId}/comments/${commentId}/replies`,
  getHttpGetOptions(getAuthHeaders())
];
