import { format, isToday, isWeekend, startOfToday } from 'date-fns';
import { arrayOf, bool, func } from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { generateKey } from '@neslotech/ui-utils';
import { generateId, getClassNames, isEmpty } from '@neslotech/ui-utils';

import { LEAVE_ALLOCATION, ROLES } from '../../../../../tool/constant';
import { USER_TYPE } from '../../../../../tool/prop-types';
import { calculateDaysOfWeek, filterAllocations } from '../../../../../tool/scheduling.util';

import { SchedulingAllocationContainer } from '../../../../../container/scheduling/allocation/SchedulingAllocation.container';
import { SchedulingAllocationFormContainer } from '../../../../../container/scheduling/allocation/form/SchedulingAllocationForm.container';

import './scheduling-calendar-view.scss';

export const SchedulingCalendarView = ({
  userHeights,
  scrollToToday,
  setScrollToToday,
  users = Array.from({ length: 6 }, (_) => ({ id: generateId() })),
  personal = false,
  loggedInUser
}) => {
  const calendarRef = useRef(null);
  const daysOfWeek = calculateDaysOfWeek();
  const [openModal, setOpenModal] = useState(false);
  const [newAllocation, setNewAllocation] = useState({});

  const allocations = useSelector(
    ({ scheduling_allocation_store }) => scheduling_allocation_store.allocations
  );

  useEffect(() => {
    setScrollToToday(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!scrollToToday) {
      return;
    }

    const today = startOfToday();
    const todayIndex = daysOfWeek.findIndex((day) => day.toDateString() === today.toDateString());
    const scrollElement = calendarRef.current;

    if (scrollElement) {
      const todayElement = scrollElement.children[todayIndex];
      if (todayElement) {
        scrollElement.scrollLeft = todayElement.offsetLeft;
      }
    }

    setScrollToToday(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToToday, daysOfWeek]);

  const userList = useMemo(() => (isEmpty(users) ? Array.from({ length: 6 }) : users), [users]);

  const handleModalOpen = (startDate, assignee) => {
    const currentDate = new Date();
    startDate.setHours(
      currentDate.getHours(),
      currentDate.getMinutes(),
      currentDate.getSeconds(),
      currentDate.getMilliseconds()
    );
    setNewAllocation({ startDate, assignee });
    setOpenModal(true);
  };

  return (
    <>
      <section className="scheduling-calendar__view">
        <div className="scheduling-calendar__week scrollable-container" ref={calendarRef}>
          {daysOfWeek.map((day, index) => (
            <div key={generateKey(day.toDateString(), index.toString())}>
              <div
                className={getClassNames('scheduling-calendar__day', {
                  weekend: isWeekend(day),
                  today: isToday(day)
                })}
              >
                <span>{format(day, 'd')}</span>
                <span>{format(day, 'cccc')}</span>
              </div>
              <div>
                {userList.map((user, index) => {
                  let height = 216;
                  if (userHeights && user) {
                    height = userHeights[user.id];
                  }

                  const allocationsForDay = filterAllocations(allocations, day, user?.id);
                  const taskAllocationsForDay = allocationsForDay.filter(
                    (allocation) => allocation.allocationType !== LEAVE_ALLOCATION
                  ).length;

                  const hasOvertime =
                    allocationsForDay.reduce((accum, allocation) => accum + allocation.hours, 0) >
                    8;
                  const overtimeHeight = height - 216;
                  const overtimeTop = 216 + taskAllocationsForDay * 27 - taskAllocationsForDay * 10;

                  const isOwnDay = user?.id === loggedInUser.id;
                  const isAdmin = loggedInUser?.role === ROLES.ADMIN;

                  return (
                    <div key={generateKey(user?.id ?? 'User', index.toString())}>
                      <div
                        role="button"
                        style={{
                          height: `${height}px`,
                          cursor: isAdmin || isOwnDay ? 'pointer' : 'default'
                        }}
                        className={getClassNames('scheduling-calendar__tasks', {
                          weekend: isWeekend(day),
                          personal
                        })}
                        onClick={(e) => {
                          const targetClass = e.target.classList;
                          if (
                            targetClass.contains('scheduling-calendar__tasks') ||
                            targetClass.contains('scheduling-calendar__overtime')
                          ) {
                            if (isAdmin || isOwnDay) {
                              handleModalOpen(day, user.id);
                            }
                          }
                        }}
                      >
                        {hasOvertime && (
                          <div
                            className="scheduling-calendar__overtime"
                            style={{ top: `${overtimeTop}px`, height: `${overtimeHeight}px` }}
                          />
                        )}
                        {user && <SchedulingAllocationContainer day={day} userId={user.id} />}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </section>
      {openModal && (
        <SchedulingAllocationFormContainer
          onClose={() => setOpenModal(false)}
          selectedTab="Allocation"
          allocation={newAllocation}
        />
      )}
    </>
  );
};

SchedulingCalendarView.propTypes = {
  users: arrayOf(USER_TYPE),
  personal: bool,
  scrollToToday: bool.isRequired,
  setScrollToToday: func.isRequired,
  loggedInUser: USER_TYPE
};
