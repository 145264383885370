import {
  SET_ALL_SCHEDULING_PERMISSIONS,
  SET_USER_SCHEDULING_PERMISSION,
  UPDATE_REALTIME_PERMISSIONS
} from '../../action/scheduling/scheduling-permissions.action';

export const initialState = {
  permissions: [],
  allLoaded: false,
  userLoaded: false
};

export function schedulingPermissionsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ALL_SCHEDULING_PERMISSIONS:
      const { permissions } = action;
      return {
        ...state,
        allLoaded: true,
        permissions
      };
    case SET_USER_SCHEDULING_PERMISSION:
      return {
        ...state,
        userLoaded: true
      };
    case UPDATE_REALTIME_PERMISSIONS: {
      const { event } = action;
      return {
        permissions: event.permissions.map((permission) => ({
          userId: permission
        })),
        allLoaded: event.permissions.length > 0,
        userLoaded: event.permissions.includes(event.createdBy)
      };
    }
    default:
      return state;
  }
}
