import {
  CLEAR_ERRORS,
  SET_CONTEXT_USER,
  SET_ERRORS,
  SET_USER_BASIC_PROFILE,
  SET_USER_PROFILE
} from '../action/profile.action';

export const initialState = {
  profile: undefined,
  errors: undefined
};

export function profileReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_PROFILE:
      const { profile } = action;
      return {
        ...state,
        profile
      };
    case SET_USER_BASIC_PROFILE: {
      const { profile } = action;
      return {
        ...state,
        basicProfile: profile
      };
    }
    case SET_CONTEXT_USER: {
      const { contextUser } = action;
      return {
        ...state,
        contextUser
      };
    }
    case SET_ERRORS: {
      const { errors } = action;
      return {
        ...state,
        errors
      };
    }
    case CLEAR_ERRORS: {
      return {
        ...state,
        errors: undefined
      };
    }
    default:
      return state;
  }
}
