import { generateId } from '@neslotech/ui-utils';

import { ADD_SYSTEM_NOTICE, REMOVE_SYSTEM_NOTICE } from '../action/system.action';

export const initialState = {
  notices: []
};

export function systemReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_SYSTEM_NOTICE:
      const { title, skipNotice = false, alertType } = action;

      if (skipNotice) {
        return state;
      }

      const noticeExists = state.notices.some((notice) => notice.title === title);
      if (noticeExists) {
        return state;
      }

      return {
        ...state,
        notices: [...state.notices, { id: generateId(), title, alertType }]
      };
    case REMOVE_SYSTEM_NOTICE:
      const { id } = action;
      return {
        ...state,
        notices: state.notices.filter((notice) => notice.id !== id)
      };
    default:
      return state;
  }
}
