import { arrayOf, bool, node, number, oneOfType, shape, string } from 'prop-types';
import React, { useMemo } from 'react';
import { usePagination, useSortBy, useTable } from 'react-table';

import { getClassNames, isEmpty } from '@neslotech/ui-utils';

import { ReactComponent as SortBothIcon } from '../../../icon/sort-both-icon.svg';
import { ReactComponent as SortBottomIcon } from '../../../icon/sort-down-icon.svg';
import { ReactComponent as SortTopIcon } from '../../../icon/sort-up-icon.svg';

import { Loader } from '../loader/Loader';
import NoResults from '../no-results/NoResults';
import { PaginationControls } from '../pagination/PaginationControls.jsx';

import './table.scss';

const renderSortingIcons = (column) => {
  if (!column.canSort) {
    return;
  }

  const isSortedDesc = column.isSortedDesc ? <SortBottomIcon /> : <SortTopIcon />;
  return column.isSorted ? isSortedDesc : <SortBothIcon />;
};

/**
 * Renders the header row, applying react-table properties to `tr` and `th`.
 * @param {Object[]} headerGroups - Header row, containing header column properties
 * @param {Object} headerModifiers - Object with keys being the column id,
 *  containing class modifiers for the header column.
 */
const renderHeaders = (headerGroups, headerModifiers) =>
  headerGroups.map((headerGroup) => {
    const { key, ...restHGroupProps } = headerGroup.getHeaderGroupProps();

    return (
      <tr key={key} {...restHGroupProps}>
        {headerGroup.headers.map((column) => {
          const { key: thKey, ...restThProps } = column.getHeaderProps(
            column.getSortByToggleProps()
          );
          return (
            <th
              key={thKey}
              {...restThProps}
              className={getClassNames('table-heading', headerModifiers[column.id])}
            >
              {column.render('Header')}
              <span className="table-heading__sort-icon">{renderSortingIcons(column)}</span>
            </th>
          );
        })}
      </tr>
    );
  });

/**
 * Renders each data row, and their `td` elements, applying react-table properties.
 * @param {Object[]} page - A collection of data rows, contained within the current page
 * @param {function} prepareRow - react-table provided function to execute on a row before display
 * @param {Object} columnModifiers - Object with keys being the column id,
 *  containing class modifiers for a column of data.
 */
const renderRows = (page, prepareRow, columnModifiers) =>
  page.map((row) => {
    prepareRow(row);

    const rowProps = row.getRowProps();
    const { onClick } = row.original;
    return (
      <tr className="table-row" key={rowProps.key} role={rowProps.role} onClick={onClick}>
        {row.cells.map((cell) => {
          const { key: tdKey, ...restTdProps } = cell.getCellProps();

          return (
            <td
              key={tdKey}
              {...restTdProps}
              className={getClassNames('table-cell', columnModifiers[cell.column.id])}
            >
              {cell.render('Cell')}
            </td>
          );
        })}
      </tr>
    );
  });

/** Only render pagination controls if there is more than one page. */
const renderPaginationControls = ({
  canPreviousPage,
  canNextPage,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  pageIndex,
  pageSize
}) =>
  pageCount > 1 && (
    <div className="table__pagination">
      <PaginationControls
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageCount={pageCount}
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        gotoPage={gotoPage}
      />
    </div>
  );

export const Table = ({
  cols,
  rowData,
  headerModifiers,
  columnModifiers,
  pageLength,
  emptyTitle,
  emptySubtitle,
  loading
}) => {
  // react-table requires us to use memoisation on the columns and data to gain speed optimisations
  const columns = useMemo(() => cols, [cols]);
  const data = useMemo(() => rowData, [rowData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageSize,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: pageLength }
    },
    useSortBy,
    usePagination
  );

  const { key, ...restOfProps } = getTableProps();
  const { key: tBodyKey, ...restTBodyProps } = getTableBodyProps();

  if (loading) {
    return (
      <div className="table__loader">
        <Loader dark />
      </div>
    );
  }

  return (
    <>
      <table key={key} {...restOfProps} className="table">
        <thead className="table__header">{renderHeaders(headerGroups, headerModifiers)}</thead>
        <tbody key={tBodyKey} {...restTBodyProps} className="table__body">
          {renderRows(page, prepareRow, columnModifiers)}
        </tbody>
      </table>
      {isEmpty(page) && <NoResults emptySubtitle={emptySubtitle} emptyTitle={emptyTitle} />}
      {renderPaginationControls({
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        pageIndex,
        pageSize
      })}
    </>
  );
};

Table.defaultProps = {
  rowData: [],
  headerModifiers: {},
  columnModifiers: {},
  pageLength: 10,
  emptyTitle: 'There are no results found',
  emptySubtitle: 'Try editing your search to see more results.',
  loading: false
};

Table.propTypes = {
  cols: arrayOf(
    shape({
      Header: oneOfType([string, node]).isRequired,
      accessor: string.isRequired
    })
  ).isRequired,
  rowData: arrayOf(shape({})),
  headerModifiers: shape({}),
  columnModifiers: shape({}),
  pageLength: number,
  emptyTitle: string,
  emptySubtitle: string,
  loading: bool
};
