import { func } from 'prop-types';
import React from 'react';

import { TIMESHEET_TYPE } from '../../../../tool/prop-types';

import { Button } from '../../../../common/component/button/Button';

import { TimesheetTable } from '../table/TimesheetTable';

import './timesheet-form.scss';

export const TimesheetForm = ({ onSave, onCancel, timesheet }) => (
  <div className="scheduling-timesheet-form">
    <h5>Submit Time</h5>
    <TimesheetTable timesheet={timesheet} />
    <div className="scheduling-timesheet-form__actions">
      <Button label="Submit" onClick={onSave} />
      <Button label="Cancel" secondary onClick={onCancel} />
    </div>
  </div>
);

TimesheetForm.propTypes = {
  timesheet: TIMESHEET_TYPE.isRequired,
  onCancel: func.isRequired,
  onSave: func.isRequired
};
