import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';

import { ApiRequest, HttpVerb, SNACK_CRITICAL } from '@neslotech/ui-utils';

import { getAuthHeaders } from '../../../../tool/auth.util';
import { SCHEDULING_ENDPOINT } from '../../../tool/api/scheduling/scheduling-permissions.endpoint';

import {
  CREATE_TASK_ALLOCATION,
  CREATE_USER_TASK_ALLOCATION,
  REMOVE_TASK_ALLOCATION,
  UPDATE_TASK_ALLOCATION
} from '../../../action/scheduling/task-allocation/task-allocation.action';
import { addSystemNotice } from '../../../action/system.action';

export function* performCreateTaskAllocation({ taskAllocation, onSuccess, onComplete }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      `${SCHEDULING_ENDPOINT}/task-allocations`,
      HttpVerb.POST,
      getAuthHeaders(),
      taskAllocation
    );

    yield call(axios, endpoint, axiosOptions);

    yield call(onSuccess);
  } catch ({ response, config }) {
    yield put(
      addSystemNotice(
        'The was an error while creating the time allocation',
        SNACK_CRITICAL,
        config?.skipNotice
      )
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForCreateTaskAllocation() {
  yield takeLatest(CREATE_TASK_ALLOCATION, performCreateTaskAllocation);
}

export function* performCreateUserTaskAllocation({
  userId,
  taskAllocation,
  onSuccess,
  onComplete
}) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      `${SCHEDULING_ENDPOINT}/users/${userId}/task-allocations`,
      HttpVerb.POST,
      getAuthHeaders(),
      taskAllocation
    );

    yield call(axios, endpoint, axiosOptions);

    yield call(onSuccess);
  } catch ({ response, config }) {
    yield put(
      addSystemNotice(
        'There was an error while creating the time allocation',
        SNACK_CRITICAL,
        config?.skipNotice
      )
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForCreateUserTaskAllocation() {
  yield takeLatest(CREATE_USER_TASK_ALLOCATION, performCreateUserTaskAllocation);
}

export function* performUpdateTaskAllocation({
  taskAllocationId,
  userId,
  taskAllocation,
  onSuccess,
  onComplete
}) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      `${SCHEDULING_ENDPOINT}/users/${userId}/task-allocations/${taskAllocationId}`,
      HttpVerb.PUT,
      getAuthHeaders(),
      taskAllocation
    );

    yield call(axios, endpoint, axiosOptions);

    yield call(onSuccess);
  } catch ({ response, config }) {
    yield put(
      addSystemNotice(
        'There was an error while editing time allocation',
        SNACK_CRITICAL,
        config?.skipNotice
      )
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateTaskAllocation() {
  yield takeLatest(UPDATE_TASK_ALLOCATION, performUpdateTaskAllocation);
}

export function* performRemoveTaskAllocation({ taskAllocationId, userId, onSuccess, onComplete }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      `${SCHEDULING_ENDPOINT}/users/${userId}/task-allocations/${taskAllocationId}`,
      HttpVerb.DELETE,
      getAuthHeaders(),
      {}
    );

    yield call(axios, endpoint, axiosOptions);

    yield call(onSuccess);
  } catch ({ response, config }) {
    yield put(
      addSystemNotice(
        'There was an error while removing the time allocation',
        SNACK_CRITICAL,
        config?.skipNotice
      )
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForRemoveTaskAllocation() {
  yield takeLatest(REMOVE_TASK_ALLOCATION, performRemoveTaskAllocation);
}

export default function* taskAllocationSaga() {
  yield all([
    watchForCreateTaskAllocation(),
    watchForCreateUserTaskAllocation(),
    watchForUpdateTaskAllocation(),
    watchForRemoveTaskAllocation()
  ]);
}
