import { bool, func, node } from 'prop-types';
import React from 'react';

import { getClassNames } from '@neslotech/ui-utils';

import './input-action.scss';

const InputAction = ({ action, onActionClick, disabled, error }) =>
  action && (
    <button className={getClassNames('input__action', { disabled, error })} onClick={onActionClick}>
      <span>{action}</span>
    </button>
  );

InputAction.defaultProps = {
  action: undefined,
  disabled: false,
  error: false
};

InputAction.propTypes = {
  action: node,
  onActionClick: func.isRequired,
  disabled: bool,
  error: bool
};

export default InputAction;
