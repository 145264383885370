import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';

import { ApiRequest, HttpVerb, SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/ui-utils';

import { getAuthHeaders } from '../../../tool/auth.util';
import { CLIENTS_ENDPOINT, getClientEndpoint } from '../../tool/api/scheduling/clients.endpoint';

import {
  ARCHIVE_CLIENT,
  CREATE_CLIENT,
  LOAD_ALL_CLIENTS,
  LOAD_CLIENT,
  REINSTATE_CLIENT,
  SET_CLIENT,
  SET_CLIENTS,
  UNLINK_PROJECT,
  UPDATE_CLIENT,
  loadAllClients
} from '../../action/scheduling/client.action';
import { addSystemNotice } from '../../action/system.action';

export function* performLoadAllClients({ onComplete }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      CLIENTS_ENDPOINT,
      HttpVerb.GET,
      getAuthHeaders()
    );

    const { data } = yield call(axios, endpoint, axiosOptions);

    yield put({ type: SET_CLIENTS, clients: data });
    yield call(onComplete);
  } catch ({ response, config }) {
    yield put(addSystemNotice('Failed to load clients', SNACK_CRITICAL, config?.skipNotice));

    yield call(onComplete);
  }
}

export function* watchForLoadAllClients() {
  yield takeLatest(LOAD_ALL_CLIENTS, performLoadAllClients);
}

export function* performArchiveClient({ clientId, onComplete }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      getClientEndpoint(clientId),
      HttpVerb.DELETE,
      getAuthHeaders(),
      {}
    );

    yield call(axios, endpoint, axiosOptions);

    yield put(addSystemNotice('The client has been archived successfully', SNACK_SUCCESS));
    yield put(loadAllClients());
  } catch ({ response, config }) {
    yield put(
      addSystemNotice('There was an error archiving the client', SNACK_CRITICAL, config?.skipNotice)
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForArchiveClient() {
  yield takeLatest(ARCHIVE_CLIENT, performArchiveClient);
}

export function* performReinstateClient({ clientId, onComplete }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      `${getClientEndpoint(clientId)}/reinstate`,
      HttpVerb.PUT,
      getAuthHeaders(),
      {}
    );

    yield call(axios, endpoint, axiosOptions);

    yield put(addSystemNotice('The client has been reinstated successfully', SNACK_SUCCESS));
    yield put(loadAllClients());
  } catch ({ response, config }) {
    yield put(
      addSystemNotice(
        'There was an error reinstating the client',
        SNACK_CRITICAL,
        config?.skipNotice
      )
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForReinstateClient() {
  yield takeLatest(REINSTATE_CLIENT, performReinstateClient);
}

export function* performCreateClient({ client, navigate, onComplete }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      CLIENTS_ENDPOINT,
      HttpVerb.POST,
      getAuthHeaders(),
      client
    );

    yield call(axios, endpoint, axiosOptions);

    yield call(navigate, '/scheduling/clients');
  } catch ({ response, config }) {
    yield put(
      addSystemNotice('There was an error creating the client', SNACK_CRITICAL, config?.skipNotice)
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForCreateClient() {
  yield takeLatest(CREATE_CLIENT, performCreateClient);
}

export function* performLoadClient({ id, onComplete }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      getClientEndpoint(id),
      HttpVerb.GET,
      getAuthHeaders()
    );

    const { data } = yield call(axios, endpoint, axiosOptions);

    yield put({ type: SET_CLIENT, client: data });

    yield call(onComplete);
  } catch ({ response, config }) {
    yield put(addSystemNotice('Failed to load client', SNACK_CRITICAL, config?.skipNotice));

    yield call(onComplete);
  }
}

export function* watchForLoadClient() {
  yield takeLatest(LOAD_CLIENT, performLoadClient);
}

export function* performUpdateClient({ id, client, navigate, onComplete }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      getClientEndpoint(id),
      HttpVerb.PUT,
      getAuthHeaders(),
      client
    );

    yield call(axios, endpoint, axiosOptions);

    yield put(addSystemNotice('The client has been updated successfully', SNACK_SUCCESS));

    yield call(navigate, '/scheduling/clients');
  } catch ({ response, config }) {
    yield put(
      addSystemNotice('There was an error updating the client', SNACK_CRITICAL, config?.skipNotice)
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateClient() {
  yield takeLatest(UPDATE_CLIENT, performUpdateClient);
}

export function* performUnlinkProject({ clientId, projectId, onComplete }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      `${getClientEndpoint(clientId)}/projects/${projectId}`,
      HttpVerb.DELETE,
      getAuthHeaders(),
      {}
    );

    yield call(axios, endpoint, axiosOptions);

    yield put(addSystemNotice('The project has been unlinked successfully', SNACK_SUCCESS));
  } catch ({ response, config }) {
    yield put(
      addSystemNotice('System error, please try again', SNACK_CRITICAL, config?.skipNotice)
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUnlinkProject() {
  yield takeLatest(UNLINK_PROJECT, performUnlinkProject);
}

export default function* clientSaga() {
  yield all([
    watchForLoadAllClients(),
    watchForArchiveClient(),
    watchForReinstateClient(),
    watchForCreateClient(),
    watchForLoadClient(),
    watchForUpdateClient(),
    watchForUnlinkProject()
  ]);
}
