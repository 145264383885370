import { noOp } from '@neslotech/ui-utils';

const STORE_NAME = '[LEAVE_CATEGORY_STORE]';

export const LOAD_ALL_LEAVE_CATEGORIES = `${STORE_NAME} Load all leave categories`;
export const SET_LEAVE_CATEGORIES = `${STORE_NAME} Set leave categories`;
export const loadAllLeaveCategories = (onComplete = noOp) => ({
  type: LOAD_ALL_LEAVE_CATEGORIES,
  onComplete
});

export const CREATE_LEAVE_CATEGORY = `${STORE_NAME} Create leave category`;
export const createLeaveCategory = (leaveCategory, onComplete) => ({
  type: CREATE_LEAVE_CATEGORY,
  leaveCategory,
  onComplete
});

export const ARCHIVE_LEAVE_CATEGORY = `${STORE_NAME} Archive leave category`;
export const archiveLeaveCategory = (leaveCategoryId, navigate, onComplete) => ({
  type: ARCHIVE_LEAVE_CATEGORY,
  leaveCategoryId,
  navigate,
  onComplete
});

export const REINSTATE_LEAVE_CATEGORY = `${STORE_NAME} Reinstate leave category`;
export const reinstateLeaveCategory = (leaveCategoryId, navigate, onComplete) => ({
  type: REINSTATE_LEAVE_CATEGORY,
  leaveCategoryId,
  navigate,
  onComplete
});

export const UPDATE_LEAVE_CATEGORY = `${STORE_NAME} Update leave category`;
export const updateLeaveCategory = (leaveCategoryId, leaveCategory, onComplete) => ({
  type: UPDATE_LEAVE_CATEGORY,
  leaveCategoryId,
  leaveCategory,
  onComplete
});
