import {
  CLEAR_PROJECT,
  SET_PROJECT,
  SET_PROJECTS,
  SET_PROJECTS_METADATA
} from '../../action/scheduling/project.action';

export const initialState = {
  projects: [],
  project: undefined,
  projectsMetadata: []
};

export function projectReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROJECTS:
      const { projects } = action;

      const updatedProjects = [...projects];
      updatedProjects.forEach((project) => {
        if (!project.client) {
          project.client = { name: 'Internal' };
        }
      });

      return {
        ...state,
        projects: updatedProjects
      };
    case SET_PROJECT:
      const { project } = action;

      const updatedProject = { ...project };
      if (!updatedProject.client) {
        updatedProject.client = { name: 'Internal' };
      }

      return {
        ...state,
        project: updatedProject
      };
    case CLEAR_PROJECT:
      return {
        ...state,
        project: undefined
      };
    case SET_PROJECTS_METADATA:
      const { projectsMetadata } = action;
      return {
        ...state,
        projectsMetadata
      };
    default:
      return state;
  }
}
