import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getAuthTokenCookie } from '../../../../tool/auth.util';

import { verifyToken } from '../../../../state/action/auth.action';

import ProfileProvider from '../../../../provider/Profile.provider';

import CompleteProfileLayout from '../../../../component/layout/complete-profile/CompleteProfileLayout';
import ProfileDetailsSection from '../../../../component/profile/details/section/ProfileDetailsSection';

const CompleteProfileDetailsContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profile = useSelector(({ profile_store }) => profile_store.contextUser);

  useEffect(() => {
    if (!profile) {
      dispatch(verifyToken({ token: getAuthTokenCookie() }, navigate, null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  return (
    <CompleteProfileLayout>
      <ProfileProvider profile={profile}>
        <ProfileDetailsSection />
      </ProfileProvider>
    </CompleteProfileLayout>
  );
};

export default CompleteProfileDetailsContainer;
