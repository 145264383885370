import { noOp } from '@neslotech/ui-utils';

const STORE_NAME = '[SCHEDULING_ALLOCATION_STORE]';

export const LOAD_ALL_ALLOCATIONS = `${STORE_NAME} Load all allocations`;
export const SET_ALLOCATIONS = `${STORE_NAME} Set allocations`;
export const loadAllAllocations = (onComplete = noOp) => ({
  type: LOAD_ALL_ALLOCATIONS,
  onComplete
});

export const ADD_REALTIME_ALLOCATION = `${STORE_NAME} add allocation in realtime`;
export const addRealtimeAllocation = (event) => ({
  type: ADD_REALTIME_ALLOCATION,
  event
});

export const UPDATE_REALTIME_ALLOCATION = `${STORE_NAME} update allocation in realtime`;
export const updateRealtimeAllocation = (event) => ({
  type: UPDATE_REALTIME_ALLOCATION,
  event
});

export const REMOVE_REALTIME_ALLOCATION = `${STORE_NAME} remove allocation in realtime`;
export const removeRealtimeAllocation = (event) => ({
  type: REMOVE_REALTIME_ALLOCATION,
  event
});
