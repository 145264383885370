import { bool, string } from 'prop-types';
import React from 'react';

import { getClassNames } from '@neslotech/ui-utils';

import './input-label.scss';

const InputLabel = ({ label, id, disabled, active, filled, error, light }) => (
  <label
    htmlFor={id}
    className={getClassNames('input__label', { disabled, active, filled, error, light })}
  >
    {label}
  </label>
);

InputLabel.defaultProps = {
  disabled: false,
  active: false,
  filled: false,
  error: false,
  light: false,
  id: ''
};

InputLabel.propTypes = {
  label: string.isRequired,
  id: string,
  disabled: bool,
  active: bool,
  filled: bool,
  error: bool,
  light: bool
};

export default InputLabel;
