import { arrayOf, bool, func, shape, string } from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isEmpty, noOp } from '@neslotech/ui-utils';

import {
  ALLOCATION_REMOVED,
  LEAVE_ALLOCATION_CREATED,
  LEAVE_ALLOCATION_UPDATED,
  TASK_ALLOCATION_CREATED,
  TASK_ALLOCATION_UPDATED,
  TASK_CREATED
} from '../../../../tool/pusher.helper';

import { ROLES } from '../../../../tool/constant';
import { LEAVE_ALLOCATION_TYPE } from '../../../../tool/prop-types';

import {
  createLeaveAllocation,
  createUserLeaveAllocation,
  removeLeaveAllocation,
  updateLeaveAllocation
} from '../../../../state/action/scheduling/leave-allocation/leave-allocation.action';
import { loadAllProjectsMetadata } from '../../../../state/action/scheduling/project.action';
import {
  addRealtimeAllocation,
  removeRealtimeAllocation,
  updateRealtimeAllocation
} from '../../../../state/action/scheduling/scheduling-allocation.action';
import { loadAllLeaveCategories } from '../../../../state/action/scheduling/settings/leave-category.action';
import {
  createTaskAllocation,
  createUserTaskAllocation,
  removeTaskAllocation,
  updateTaskAllocation
} from '../../../../state/action/scheduling/task-allocation/task-allocation.action';
import {
  addRealtimeTask,
  createTask,
  loadTasks
} from '../../../../state/action/scheduling/task.action';

import { ChannelProvider } from '../../../../provider/Channel.provider';

import { useChannel } from '../../../../hook/useChannel';
import { useProgressLoader } from '../../../../hook/useProgressLoader';
import { useSchedulingPermissions } from '../../../../hook/useSchedulingPermissions';

import { SchedulingAllocationForm } from '../../../../component/scheduling/allocation/form/SchedulingAllocationForm';

const SchedulingAllocationFormWithChannel = ({
  onSubmitLeaveAllocation,
  onClose,
  categories = [],
  isAdmin = false,
  users = [],
  loggedInUserId,
  openedTab,
  projects = [],
  tasks = [],
  onAddTaskAllocation,
  setProjectId,
  allocation = {},
  isEditing,
  onRemoveLeaveAllocation,
  onRemoveTaskAllocation,
  onAddProjectTask
}) => {
  const dispatch = useDispatch();

  const { bindToEvent } = useChannel();

  useEffect(() => {
    bindToEvent(TASK_ALLOCATION_CREATED, (data) => dispatch(addRealtimeAllocation(data)));
    bindToEvent(TASK_ALLOCATION_UPDATED, (data) => dispatch(updateRealtimeAllocation(data)));
    bindToEvent(LEAVE_ALLOCATION_CREATED, (data) => dispatch(addRealtimeAllocation(data)));
    bindToEvent(LEAVE_ALLOCATION_UPDATED, (data) => dispatch(updateRealtimeAllocation(data)));
    bindToEvent(ALLOCATION_REMOVED, (data) => dispatch(removeRealtimeAllocation(data)));
    bindToEvent(TASK_CREATED, (data) => dispatch(addRealtimeTask(data)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SchedulingAllocationForm
      users={users}
      categories={categories}
      isAdmin={isAdmin}
      onSubmitLeaveAllocation={onSubmitLeaveAllocation}
      onAddTaskAllocation={onAddTaskAllocation}
      onClose={onClose}
      loggedInUserId={loggedInUserId}
      openedTab={openedTab}
      tasks={tasks}
      projects={projects}
      setProjectId={setProjectId}
      allocation={allocation}
      isEditing={isEditing}
      onRemoveLeaveAllocation={onRemoveLeaveAllocation}
      onRemoveTaskAllocation={onRemoveTaskAllocation}
      onAddProjectTask={onAddProjectTask}
    />
  );
};

SchedulingAllocationFormWithChannel.propTypes = {
  onClose: func.isRequired,
  openedTab: string.isRequired,
  isAdmin: bool,
  users: arrayOf(
    shape({
      firstName: string,
      lastName: string,
      id: string
    })
  ),
  categories: arrayOf(
    shape({
      name: string,
      id: string
    })
  ),
  tasks: arrayOf(
    shape({
      title: string,
      id: string
    })
  ),
  projects: arrayOf(
    shape({
      name: string,
      id: string
    })
  ),
  loggedInUserId: string.isRequired,
  onSubmitLeaveAllocation: func.isRequired,
  onAddTaskAllocation: func.isRequired,
  allocation: LEAVE_ALLOCATION_TYPE,
  isEditing: bool,
  onRemoveLeaveAllocation: func.isRequired,
  onRemoveTaskAllocation: func.isRequired,
  onAddProjectTask: func.isRequired
};

export const SchedulingAllocationFormContainer = ({
  onClose,
  selectedTab,
  allocation = {},
  isEditing = false
}) => {
  const [projectId, setProjectId] = useState(allocation.projectId);

  const dispatch = useDispatch();
  const { mappedSchedulingUsers } = useSchedulingPermissions();
  const { setBusyState } = useProgressLoader();

  const user = useSelector(({ profile_store }) => profile_store.contextUser);
  const leaveCategories = useSelector(
    ({ leave_category_store }) => leave_category_store.categories
  );
  const projects = useSelector(({ project_store }) => project_store.projectsMetadata);
  const tasks = useSelector(({ task_store }) => task_store.tasks);

  const isAdmin = user.role === ROLES.ADMIN;

  const handleAddLeaveAllocation = (leaveAllocation, userId) => {
    setBusyState(true);

    if (isAdmin && userId) {
      dispatch(
        createUserLeaveAllocation(userId, leaveAllocation, onClose, () => setBusyState(false))
      );
    } else {
      dispatch(createLeaveAllocation(leaveAllocation, onClose, () => setBusyState(false)));
    }
  };

  const handleUpdateLeaveAllocation = (leaveAllocation, userId) => {
    setBusyState(true);
    dispatch(
      updateLeaveAllocation(allocation.id, userId, leaveAllocation, onClose, () =>
        setBusyState(false)
      )
    );
  };

  const handleRemoveLeaveAllocation = () => {
    setBusyState(true);
    dispatch(
      removeLeaveAllocation(allocation.id, allocation.assignee, onClose, () => setBusyState(false))
    );
  };

  const handleRemoveTaskAllocation = () => {
    setBusyState(true);
    dispatch(
      removeTaskAllocation(allocation.id, allocation.assignee, onClose, () => setBusyState(false))
    );
  };

  const handleUpdateTaskAllocation = (taskAllocation, userId) => {
    setBusyState(true);
    dispatch(
      updateTaskAllocation(allocation.id, userId, taskAllocation, onClose, () =>
        setBusyState(false)
      )
    );
  };

  const handleAddTaskAllocation = (taskAllocation, userId) => {
    setBusyState(true);

    if (isAdmin && userId) {
      dispatch(
        createUserTaskAllocation(userId, taskAllocation, onClose, () => setBusyState(false))
      );
    } else {
      dispatch(createTaskAllocation(taskAllocation, onClose, () => setBusyState(false)));
    }
  };

  const handleAddProjectTask = (data, onSuccess) => {
    setBusyState(true);
    dispatch(createTask(projectId, data, onSuccess, () => setBusyState(false)));
  };

  useEffect(() => {
    if (projectId) {
      dispatch(loadTasks(projectId, noOp));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (isEmpty(projects)) {
      dispatch(loadAllProjectsMetadata());
    }

    if (isEmpty(leaveCategories)) {
      dispatch(loadAllLeaveCategories());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ChannelProvider channelName="scheduling-calendar-updates">
      <SchedulingAllocationFormWithChannel
        users={mappedSchedulingUsers}
        categories={leaveCategories}
        isAdmin={isAdmin}
        onSubmitLeaveAllocation={isEditing ? handleUpdateLeaveAllocation : handleAddLeaveAllocation}
        onAddTaskAllocation={isEditing ? handleUpdateTaskAllocation : handleAddTaskAllocation}
        onRemoveLeaveAllocation={handleRemoveLeaveAllocation}
        onRemoveTaskAllocation={handleRemoveTaskAllocation}
        onClose={onClose}
        loggedInUserId={user.id}
        openedTab={selectedTab}
        tasks={tasks}
        projects={projects}
        setProjectId={setProjectId}
        allocation={allocation}
        isEditing={isEditing}
        onAddProjectTask={handleAddProjectTask}
      />
    </ChannelProvider>
  );
};

SchedulingAllocationFormContainer.propTypes = {
  onClose: func.isRequired,
  selectedTab: string.isRequired
};
