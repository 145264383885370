import { bool, func, node, string } from 'prop-types';
import React from 'react';

import { noOp } from '@neslotech/ui-utils';

import { Button } from '../../component/button/Button';

import { ReactComponent as CloseIcon } from '../../../icon/close-icon.svg';

import { Modal } from './Modal';

import './dialog.scss';

const Dialog = ({
  heading,
  loading,
  isOpen,
  message,
  primaryText,
  secondaryText,
  onPrimaryClick,
  onSecondaryClick,
  onClose
}) => {
  return (
    <Modal>
      <dialog open={isOpen} className="dialog-layout">
        <header className="dialog-layout__header">
          <span className="dialog-layout__close" onClick={onClose} role="dialog" onKeyDown={noOp}>
            <CloseIcon />
          </span>
        </header>
        <div className="dialog-layout__content">
          <span className="dialog-layout__heading">{heading}</span>
          <p className="dialog-layout__message">{message}</p>
          <div className="dialog-layout__actions">
            {primaryText && (
              <Button label={primaryText} onClick={onPrimaryClick} loading={loading} />
            )}
            {secondaryText && <Button label={secondaryText} onClick={onSecondaryClick} secondary />}
          </div>
        </div>
      </dialog>
    </Modal>
  );
};

Dialog.defaultProps = {
  loading: false,
  isOpen: false,
  message: undefined,
  heading: '',
  primaryText: '',
  secondaryText: '',
  onPrimaryClick: noOp,
  onSecondaryClick: noOp,
  onClose: noOp
};

Dialog.propTypes = {
  loading: bool,
  isOpen: bool,
  message: node,
  heading: string,
  primaryText: string,
  secondaryText: string,
  onPrimaryClick: func,
  onSecondaryClick: func,
  onClose: func
};

export default Dialog;
