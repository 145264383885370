import { func } from 'prop-types';
import React from 'react';

import { getClassNames, noOp } from '@neslotech/ui-utils';

import { useFilters } from '../../../hook/useFilter';

import { ReactComponent as MinusIcon } from '../../../icon/minus-icon.svg';
import { ReactComponent as PlusIcon } from '../../../icon/plus-icon.svg';

import './filters.scss';

export const Filters = ({ onApplyFilters }) => {
  const { options, setOptions } = useFilters();

  const handleOptionClick = (optionName) => {
    const found = options.find((option) => option.name === optionName);
    if (!found) {
      return;
    }

    found.active = !found.active;

    setOptions([...options]);
  };

  const handleSelect = (optionName, value) => {
    const found = options.find((option) => option.name === optionName);
    if (found.singleSelect) {
      found.values.forEach((value) => {
        value.selected = false;
      });
    }

    const foundValue = found.values.find((foundValue) => foundValue.value === value);
    foundValue.selected = !foundValue.selected;

    setOptions([...options]);
    onApplyFilters();
  };

  const FilterOption = ({ option: { name, values, active } }) => (
    <li
      className={getClassNames('filters__tree-option', {
        selected: values.some((value) => value.selected),
        active
      })}
    >
      <button onClick={() => handleOptionClick(name)}>
        <span>{name}</span>
        <span>{active ? <MinusIcon /> : <PlusIcon />}</span>
      </button>
      <ul className={getClassNames('filters__tree-values', { active })}>
        {values.map((value) => (
          <FilterValue key={value.value} option={{ name, values, active }} value={value} />
        ))}
      </ul>
    </li>
  );

  const FilterValue = ({ value, option }) => (
    <li
      className={getClassNames('filters__value', { selected: value.selected })}
      onClick={() => handleSelect(option.name, value.value)}
      onKeyDown={noOp}
    >
      <span>{value.value}</span>
    </li>
  );

  return (
    <section className="filters">
      <ul className="filters__tree">
        {options?.map((option) => (
          <FilterOption key={option.name} option={option} />
        ))}
      </ul>
    </section>
  );
};

Filters.propTypes = {
  onApplyFilters: func.isRequired
};
