import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { loadProfile } from '../../../state/action/profile.action';
import { loadUsers } from '../../../state/action/user.action';

import EditProfileProvider from '../../../provider/EditProfile.provider';

import { useAuth } from '../../../hook/useAuth';
import { useDevice } from '../../../hook/useDevice';

import AppLayout from '../../../component/layout/AppLayout';
import ProfileDetailsSection from '../../../component/profile/details/section/ProfileDetailsSection';

const EditProfileContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const stateLoading = useSelector(({ profile_store }) => profile_store.loading);

  const [loading, setLoading] = useState(stateLoading ?? true);

  const { id, onLogout } = useAuth();
  const { userId } = useParams();

  const adminEdit = useMemo(() => id !== userId, [id, userId]);

  const { mobile } = useDevice();

  const stopLoading = () => setLoading(false);

  useEffect(() => {
    // The user should be kicked out if the ID param and the logged-in user do not match and if the user is not an admin.
    if (!adminEdit && id !== userId) {
      onLogout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminEdit, id, userId]);

  useEffect(() => {
    dispatch(loadProfile(userId, stopLoading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    if (adminEdit) {
      dispatch(loadUsers(navigate, stopLoading));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppLayout showBreadcrumb={!mobile} fullWidthBreadcrumb>
      <EditProfileProvider userId={userId}>
        <ProfileDetailsSection editMode adminEditMode={adminEdit} loading={loading} />
      </EditProfileProvider>
    </AppLayout>
  );
};

export default EditProfileContainer;
