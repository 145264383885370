import { SNACK_INFO } from '@neslotech/ui-utils';

export const ADD_SYSTEM_NOTICE = '[SYSTEM_STORE] Add system notice';
export const addSystemNotice = (title, alertType = SNACK_INFO, skipNotice = false) => ({
  type: ADD_SYSTEM_NOTICE,
  title,
  alertType,
  skipNotice
});

export const REMOVE_SYSTEM_NOTICE = '[SYSTEM_STORE] Remove system notice';
export const removeSystemNotice = (id) => ({
  type: REMOVE_SYSTEM_NOTICE,
  id
});
