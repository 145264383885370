export const NONE = 'none';
export const USER_AVATAR_ALT = 'The uploaded avatar of a user';
export const SOCIAL_TYPE = Object.freeze({
  GIT_HUB: 'GIT_HUB',
  BEHANCE: 'BEHANCE',
  LINKED_IN: 'LINKED_IN'
});

export const VIEW_OPTIONS = Object.freeze([
  { label: 'Personal View', value: 'personal_view' },
  { label: 'Team View', value: 'team_view' }
]);

export const TIMESHEET_STATUS = Object.freeze({
  SUBMITTED: 'Submitted',
  APPROVED: 'Approved'
});

export const PROFILE_TABS = Object.freeze({
  PERSONAL_INFO: 'personalInfo',
  PHYSICAL_ADDRESS: 'physicalAddress',
  BANK_DETAILS: 'bankDetails',
  EMERGENCY_CONTACT: 'emergencyContact',
  IMAGE_UPLOAD: 'imageUpload',
  SOCIAL_LINKS: 'socialLinks'
});

export const ROLES = Object.freeze({
  ADMIN: 'ADMIN',
  EMPLOYEE: 'EMPLOYEE',
  OPERATIONS: 'OPERATIONS'
});

export const RESPONSE_STATUS = Object.freeze({
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409
});

export const PROFILE_TAB_OPTIONS = Object.freeze([
  {
    label: 'Personal Info',
    value: PROFILE_TABS.PERSONAL_INFO,
    enabled: true
  },
  {
    label: 'Physical Address',
    value: PROFILE_TABS.PHYSICAL_ADDRESS,
    enabled: false
  },
  {
    label: 'Bank Details',
    value: PROFILE_TABS.BANK_DETAILS,
    enabled: false
  },
  {
    label: 'Emergency Contact',
    value: PROFILE_TABS.EMERGENCY_CONTACT,
    enabled: false
  },
  {
    label: 'Image Upload',
    value: PROFILE_TABS.IMAGE_UPLOAD,
    enabled: false
  },
  {
    label: 'Social Links',
    value: PROFILE_TABS.SOCIAL_LINKS,
    enabled: false
  }
]);

export const LEAVE_ALLOCATION = 'LEAVE';

export const EDIT_PROFILE_TAB_OPTIONS = Object.freeze(
  PROFILE_TAB_OPTIONS.map((option) => ({
    ...option,
    enabled: true
  }))
);

export const DEPARTMENT_OPTIONS = Object.freeze([
  {
    label: 'Engineers',
    value: 'Engineers'
  },
  {
    label: 'Designers',
    value: 'Designers'
  },
  {
    label: 'Executives',
    value: 'Executives'
  },
  {
    label: 'Administration',
    value: 'Administration'
  }
]);

export const ROLE_OPTIONS = Object.freeze([
  {
    label: 'Admin',
    value: ROLES.ADMIN
  },
  {
    label: 'Employee',
    value: ROLES.EMPLOYEE
  },
  {
    label: 'Operations',
    value: ROLES.OPERATIONS
  }
]);
