import { noOp } from '@neslotech/ui-utils';

const STORE_NAME = '[CLIENT_STORE]';

export const LOAD_ALL_CLIENTS = `${STORE_NAME} Load all clients`;
export const SET_CLIENTS = `${STORE_NAME} Set clients`;
export const loadAllClients = (onComplete = noOp) => ({
  type: LOAD_ALL_CLIENTS,
  onComplete
});

export const ARCHIVE_CLIENT = `${STORE_NAME} Archive client`;
export const archiveClient = (clientId, onComplete) => ({
  type: ARCHIVE_CLIENT,
  clientId,
  onComplete
});

export const REINSTATE_CLIENT = `${STORE_NAME} Reinstate client`;
export const reinstateClient = (clientId, onComplete) => ({
  type: REINSTATE_CLIENT,
  clientId,
  onComplete
});

export const CREATE_CLIENT = `${STORE_NAME} Create client`;
export const createClient = (client, navigate, onComplete) => ({
  type: CREATE_CLIENT,
  client,
  navigate,
  onComplete
});

export const SET_CLIENT = `${STORE_NAME} Set client`;
export const LOAD_CLIENT = `${STORE_NAME} Load client`;
export const loadClient = (id, onComplete) => ({
  type: LOAD_CLIENT,
  id,
  onComplete
});

export const UPDATE_CLIENT = `${STORE_NAME} Update client`;
export const updateClient = (id, client, navigate, onComplete) => ({
  type: UPDATE_CLIENT,
  id,
  client,
  navigate,
  onComplete
});

export const UNLINK_PROJECT = `${STORE_NAME} Unlink project`;
export const unlinkProject = (clientId, projectId, onComplete) => ({
  type: UNLINK_PROJECT,
  clientId,
  projectId,
  onComplete
});

export const UNLINK_REALTIME_PROJECT = `${STORE_NAME} unlink project in realtime`;
export const unlinkRealtimeProject = (event) => ({
  type: UNLINK_REALTIME_PROJECT,
  event
});
