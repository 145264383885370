import { bool, func, shape, string } from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import {
  createLeaveCategory,
  updateLeaveCategory
} from '../../../../../state/action/scheduling/settings/leave-category.action';

import { useProgressLoader } from '../../../../../hook/useProgressLoader';

import { LeaveCategoryForm } from '../../../../../component/scheduling/leave-category/form/LeaveCategoryForm';

export const LeaveCategoryFormContainer = ({ onClose, leaveCategory = {}, isEditMode = false }) => {
  const dispatch = useDispatch();
  const { setBusyState } = useProgressLoader();

  const handleSubmit = (category) => {
    setBusyState(true);

    const onComplete = () => {
      setBusyState(false);
      onClose();
    };

    if (isEditMode) {
      dispatch(updateLeaveCategory(leaveCategory.id, category, onComplete));
    } else {
      dispatch(createLeaveCategory(category, onComplete));
    }
  };

  return (
    <LeaveCategoryForm
      onSubmit={handleSubmit}
      onClose={onClose}
      category={leaveCategory}
      isEditMode={isEditMode}
    />
  );
};

LeaveCategoryFormContainer.propTypes = {
  onClose: func.isRequired,
  isEditMode: bool,
  leaveCategory: shape({
    id: string,
    name: string,
    colour: string
  })
};
