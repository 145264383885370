export const NOTIFICATION_TABS = Object.freeze({
  ALL: 'all',
  UNREAD: 'unread'
});

export const NOTIFICATION_TYPE = Object.freeze({
  REMOVE_NOMINATION_MESSAGE: 'REMOVE_NOMINATION_MESSAGE',
  REMOVE_NOMINATION_COMMENT: 'REMOVE_NOMINATION_COMMENT',
  REMOVE_NOMINATION: 'REMOVE_NOMINATION',
  TIMESHEET_SUBMITTED: 'TIMESHEET_SUBMITTED',
  TIMESHEET_REJECTED: 'TIMESHEET_REJECTED',
  TIMESHEET_PROCESSED: 'TIMESHEET_PROCESSED'
});

export const createNotificationTabs = (count) =>
  Object.freeze([
    {
      label: `All (${count})`,
      value: NOTIFICATION_TABS.ALL
    },
    {
      label: 'Unread',
      value: NOTIFICATION_TABS.UNREAD
    }
  ]);
