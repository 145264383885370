import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { isEmpty } from '@neslotech/ui-utils';

import { mapTimesheetProject } from '../../../tool/scheduling.util';

import { loadDirectoryUsers } from '../../../state/action/directory.action';
import { loadAllProjectsMetadata } from '../../../state/action/scheduling/project.action';
import {
  approveTimesheet,
  loadTimesheet,
  rejectTimesheet
} from '../../../state/action/scheduling/timesheet/timesheet.action';

import { useProgressLoader } from '../../../hook/useProgressLoader';

import { Loader } from '../../../common/component/loader/Loader';
import { Timesheet } from '../../../component/scheduling/timesheet/Timesheet';

const TimesheetContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setBusyState } = useProgressLoader();
  const { id } = useParams();

  const [mappedTimesheet, setMappedTimesheet] = useState();
  const timesheet = useSelector(({ timesheet_store }) => timesheet_store.timesheet);
  const stateTimesheetLoading = useSelector(({ timesheet_store }) => timesheet_store.loading);

  const projects = useSelector(({ project_store }) => project_store.projectsMetadata);

  const directoryUsers = useSelector(({ directory_store }) => directory_store.directoryUsers);

  const [timesheetLoading, setTimesheetLoading] = useState(stateTimesheetLoading ?? true);
  const [directoryLoading, setDirectoryLoading] = useState(false);
  const [projectsLoading, setProjectsLoading] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(loadTimesheet(id, () => setTimesheetLoading(false)));
    }

    if (isEmpty(directoryUsers)) {
      setDirectoryLoading(true);
      dispatch(loadDirectoryUsers(() => setDirectoryLoading(false)));
    }

    if (isEmpty(projects)) {
      setProjectsLoading(true);
      dispatch(loadAllProjectsMetadata(() => setProjectsLoading(false)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setMappedTimesheet(mapTimesheetProject(timesheet, projects, directoryUsers));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects, timesheet, directoryUsers]);

  const handleApprove = (id) => {
    setBusyState(true);
    dispatch(
      approveTimesheet(
        id,
        () => navigate('/scheduling/settings/timesheets'),
        () => setBusyState(false)
      )
    );
  };

  const handleReject = (id, payload) => {
    setBusyState(true);
    dispatch(
      rejectTimesheet(
        id,
        payload,
        () => navigate('/scheduling/settings/timesheets'),
        () => setBusyState(false)
      )
    );
  };

  if (timesheetLoading || directoryLoading || projectsLoading) {
    return (
      <div id="timesheet-loader">
        <Loader dark />
      </div>
    );
  }

  if (!mappedTimesheet) {
    return;
  }

  return (
    <Timesheet onApprove={handleApprove} onReject={handleReject} timesheet={mappedTimesheet} />
  );
};

export default TimesheetContainer;
