import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { loadBasicProfile } from '../../../state/action/profile.action';

import { useAuth } from '../../../hook/useAuth';

import AppLayout from '../../../component/layout/AppLayout';
import BasicProfile from '../../../component/user/profile/BasicProfile';

const BasicProfileContainer = () => {
  const dispatch = useDispatch();

  const basicProfile = useSelector(({ profile_store }) => profile_store.basicProfile);
  const stateLoading = useSelector(({ profile_store }) => profile_store.loading);

  const [loading, setLoading] = useState(stateLoading ?? true);

  const { userId } = useParams();
  const { id } = useAuth();

  const editable = useMemo(() => {
    return basicProfile?.id === id;
  }, [basicProfile, id]);

  useEffect(() => {
    dispatch(loadBasicProfile(userId, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <AppLayout fullWidthBreadcrumb>
      <BasicProfile profile={basicProfile} editable={editable} loading={loading} />
    </AppLayout>
  );
};

export default BasicProfileContainer;
