import { bool, func, number, oneOfType, shape, string } from 'prop-types';
import React from 'react';

import { getClassNames, noOp } from '@neslotech/ui-utils';

import './category-tag.scss';

const colorDisabled = '#2a2a2a';

const CategoryTag = ({ category, outline, hover, disabled, onClick }) => {
  return (
    <button
      className={getClassNames('category-tag', { outline, hover, disabled })}
      onClick={disabled ? noOp : onClick}
      style={{
        backgroundColor: outline ? 'transparent' : disabled ? colorDisabled : category.colorCode,
        color: outline ? '#2a2a2a' : category.textColorCode,
        border: `1px solid ${disabled ? colorDisabled : category.colorCode}`
      }}
    >
      <span>{category.name}</span>
      <span>+{category.points}</span>
    </button>
  );
};

CategoryTag.defaultProps = {
  outline: false,
  hover: false,
  onClick: noOp
};

CategoryTag.propTypes = {
  category: oneOfType([
    shape({
      colorCode: string,
      textColorCode: string,
      name: string,
      points: number
    }),
    string
  ]).isRequired,
  outline: bool,
  hover: bool,
  onClick: func
};

export default CategoryTag;
