import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  archiveLeaveCategory,
  loadAllLeaveCategories,
  reinstateLeaveCategory
} from '../../../state/action/scheduling/settings/leave-category.action';

import { useProgressLoader } from '../../../hook/useProgressLoader';

import { LeaveCategoryList } from '../../../component/scheduling/leave-category/LeaveCategoryList';

export const LeaveCategoryListContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setBusyState } = useProgressLoader();

  const leaveCategories = useSelector(
    ({ leave_category_store }) => leave_category_store.categories
  );
  const stateLoading = useSelector(({ leave_category_store }) => leave_category_store.loading);

  const [loading, setLoading] = useState(stateLoading ?? true);

  useEffect(() => {
    dispatch(loadAllLeaveCategories(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleArchive = (leaveCategoryId, onComplete) => {
    setBusyState(true);
    dispatch(
      archiveLeaveCategory(leaveCategoryId, navigate, () => {
        onComplete();
        setBusyState(false);
      })
    );
  };

  const handleReinstate = (leaveCategoryId) => {
    setBusyState(true);
    dispatch(reinstateLeaveCategory(leaveCategoryId, navigate, () => setBusyState(false)));
  };

  return (
    <LeaveCategoryList
      categories={leaveCategories}
      loading={loading}
      onArchive={handleArchive}
      onReinstate={handleReinstate}
    />
  );
};
