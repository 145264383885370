import { bool } from 'prop-types';
import React from 'react';

import { getClassNames } from '@neslotech/ui-utils';

import { PROFILE_TABS } from '../../../../tool/constant';

import { useProfile } from '../../../../hook/useProfile';

import ProfileImageUpload from '../../image/ProfileImageUpload';
import BankDetailsForm from '../form/BankDetailsForm';
import EmergencyContactForm from '../form/EmergencyContactForm';
import PersonalInfoForm from '../form/PersonalInfoForm';
import PhysicalAddressForm from '../form/PhysicalAddressForm';
import ProfileDetailsForm from '../form/ProfileDetailsForm';
import SocialLinksForm from '../form/SocialLinksForm';
import ProfileDetailsTabs from '../tabs/ProfileDetailsTabs';

import './profile-details-section.scss';

const RenderedForm = ({ adminEditMode }) => {
  const { selectedTab } = useProfile();

  switch (selectedTab) {
    case PROFILE_TABS.PERSONAL_INFO:
      return <PersonalInfoForm isAdminEditing={adminEditMode} />;
    case PROFILE_TABS.PHYSICAL_ADDRESS:
      return <PhysicalAddressForm disabled={adminEditMode} />;
    case PROFILE_TABS.BANK_DETAILS:
      return <BankDetailsForm disabled={adminEditMode} />;
    case PROFILE_TABS.EMERGENCY_CONTACT:
      return <EmergencyContactForm disabled={adminEditMode} />;
    case PROFILE_TABS.SOCIAL_LINKS:
      return <SocialLinksForm disabled={adminEditMode} />;
    case PROFILE_TABS.IMAGE_UPLOAD:
      return <ProfileImageUpload disabled={adminEditMode} />;
    default:
      throw Error(`Unknown tab value selected: ${selectedTab}`);
  }
};

const ProfileDetailsSection = ({ editMode, adminEditMode, loading }) => {
  const { selectedTab, showUndo } = useProfile();

  return (
    <section className={getClassNames('profile-details-section', { 'edit-mode': editMode })}>
      <aside>
        <ProfileDetailsTabs editMode={editMode} />
      </aside>
      <div
        className={getClassNames('profile-details-section__details', {
          image: selectedTab === PROFILE_TABS.IMAGE_UPLOAD
        })}
      >
        <ProfileDetailsForm
          editMode={editMode}
          adminEditMode={adminEditMode}
          showUndo={showUndo ? showUndo() : false}
          retrieveLoading={loading}
        >
          <RenderedForm adminEditMode={adminEditMode} />
        </ProfileDetailsForm>
      </div>
    </section>
  );
};

ProfileDetailsSection.defaultProps = {
  editMode: false,
  adminEditMode: false,
  loading: false
};

ProfileDetailsSection.propTypes = {
  editMode: bool,
  adminEditMode: bool,
  loading: bool
};

export default ProfileDetailsSection;
