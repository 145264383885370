import { arrayOf, node, oneOfType } from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { SchedulingMenu } from '../../../scheduling/menu/SchedulingMenu';
import { SchedulingSettingsMenu } from '../../../scheduling/menu/settings/SchedulingSettingsMenu';
import AppLayout from '../../AppLayout';

import './scheduling-settings-layout.scss';

export const SchedulingSettingsLayout = ({ children }) => {
  const contextUser = useSelector(({ profile_store }) => profile_store.contextUser);
  const location = useLocation();
  const path = location.pathname;
  const timesheetPage =
    path.startsWith('/scheduling/settings/timesheets') &&
    path.length > '/scheduling/settings/timesheets'.length;

  return (
    <AppLayout fullWidthBreadcrumb withSideMenu>
      <div className="scheduling-settings-layout">
        <SchedulingMenu role={contextUser?.role} />
        {!timesheetPage && <SchedulingSettingsMenu role={contextUser?.role} />}
        <section>{children}</section>
      </div>
    </AppLayout>
  );
};

SchedulingSettingsLayout.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired
};
