import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { SNACK_SUCCESS } from '@neslotech/ui-utils';

import { PROFILE_TABS, PROFILE_TAB_OPTIONS } from '../tool/constant';
import { toBase64 } from '../tool/file.util';

import { activateProfile, clearErrors, saveProfileInfo } from '../state/action/profile.action';
import { addSystemNotice } from '../state/action/system.action';

import { ProfileContext } from '../context/Profile.context';

import { useAuth } from '../hook/useAuth';
import { useProgressLoader } from '../hook/useProgressLoader';

const formify = (profile) => ({
  personalInfo: {
    firstName: profile?.firstName,
    lastName: profile?.lastName,
    alternativeEmail: profile?.alternateEmail
  },
  image: profile?.image,
  defaultImage: profile?.defaultImage
});

const ProfileProvider = ({ profile, children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setBusyState } = useProgressLoader();

  const { id } = useAuth();

  const [form, setForm] = useState(formify(profile));
  const [selectedTab, setSelectedTab] = useState(PROFILE_TABS.PERSONAL_INFO);
  const [loading, setLoading] = useState(false);

  const errors = useSelector(({ profile_store }) => profile_store.errors);

  useEffect(() => {
    if (!!profile) {
      setForm(formify(profile));
    }
  }, [profile]);

  const onChange = (newState) => setForm({ ...form, ...newState });

  const onActivateSuccess = () => {
    dispatch(
      addSystemNotice('Your profile information has been captured successfully', SNACK_SUCCESS)
    );
    setBusyState(false);
    navigate('/dashboard');
  };

  /*
   Should only update the tab on successful save of current form section, this ensures required fields
   will be populated and validated correctly before moving to the next step.
  */
  const onSaveInfoSuccess = () => {
    const tabValues = Object.values(PROFILE_TABS);

    const nextTabIndex = tabValues.indexOf(selectedTab) + 1;
    if (nextTabIndex === tabValues.length) {
      dispatch(activateProfile(id, onActivateSuccess, stopLoading));
      return;
    }

    setBusyState(false);
    setSelectedTab(tabValues[nextTabIndex]);

    const nextTab = PROFILE_TAB_OPTIONS[nextTabIndex];
    nextTab.enabled = true;
  };

  const onBackClick = () => {
    const tabValues = Object.values(PROFILE_TABS);

    const prevTabIndex = tabValues.indexOf(selectedTab) - 1;
    setSelectedTab(tabValues[prevTabIndex]);
  };

  const stopLoading = () => {
    setLoading(false);
    setBusyState(false);
  };

  const onNextClick = () => {
    setLoading(true);
    setBusyState(true);
    dispatch(saveProfileInfo(id, form, selectedTab, onSaveInfoSuccess, stopLoading));
    dispatch(clearErrors());
  };

  const handleFileChange = async (file) => {
    const base64 = await toBase64(file);
    setForm({ ...form, image: base64 });
  };

  const value = {
    form,
    tabs: PROFILE_TAB_OPTIONS,
    selectedTab,
    setSelectedTab,
    loading,
    errors,
    onChange,
    onBackClick,
    onNextClick,
    handleFileChange
  };

  return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
};

export default ProfileProvider;
