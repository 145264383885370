import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { isEmpty } from '@neslotech/ui-utils';

import { KUDOS_TABS } from '../component/kudos/dashboard/kudos.helper';
import {
  KUDOS_LEADERBOARD,
  KUDOS_NOMINATION,
  KUDOS_NOMINATION_COMMENT,
  KUDOS_NOMINATION_COMMENT_REMOVE,
  KUDOS_NOMINATION_COMMENT_UPDATE,
  KUDOS_NOMINATION_DELETE,
  KUDOS_NOMINATION_LIKE,
  KUDOS_NOMINATION_REMOVE,
  KUDOS_NOMINATION_REPLY,
  KUDOS_POPULAR_CATEGORIES
} from '../tool/pusher.helper';

import { mapLeadersToUser, mapNominations } from '../tool/kudos.util';

import {
  setRealtimeNominationComment,
  setRealtimeNominationCommentRemove,
  setRealtimeNominationCommentUpdate,
  setRealtimeNominationReply
} from '../state/action/kudos/comment.action';
import {
  addRealtimeNomination,
  addRealtimeNominationDelete,
  addRealtimeNominationMessageRemove,
  loadPersonalNominations,
  removeNominationMessage,
  setRealtimeLeaders,
  setRealtimeNominationLike,
  setRealtimePopularCategories
} from '../state/action/kudos/kudos.action';

import { KudosContext } from '../context/Kudos.context';

import { useAuth } from '../hook/useAuth';
import { useChannel } from '../hook/useChannel';
import { useDevice } from '../hook/useDevice';

const KudosProvider = ({ children }) => {
  const dispatch = useDispatch();

  const { mobile } = useDevice();
  const { id } = useAuth();
  const { bindToEvent } = useChannel();

  const [selectedTab, setSelectedTab] = useState(KUDOS_TABS.COMPANY_TIMELINE);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { leaders, companyNominations, personalNominations, basicProfile, directoryUsers } =
    useSelector(
      ({ kudos_store, profile_store, directory_store }) => ({
        leaders: kudos_store.leaders,
        companyNominations: kudos_store.companyNominations,
        personalNominations: kudos_store.personalNominations,
        basicProfile: profile_store.contextUser,
        directoryUsers: directory_store.directoryUsers
      }),
      //Prevent re-render on each useSelector call
      shallowEqual
    );

  const [loadingPersonalNominations, setPersonalNominationsLoading] = useState(false);

  useEffect(() => {
    bindToEvent(KUDOS_NOMINATION, (data) => dispatch(addRealtimeNomination(data)));
    bindToEvent(KUDOS_NOMINATION_REMOVE, (data) =>
      dispatch(addRealtimeNominationMessageRemove(data))
    );
    bindToEvent(KUDOS_NOMINATION_DELETE, (data) => dispatch(addRealtimeNominationDelete(data)));
    bindToEvent(KUDOS_LEADERBOARD, (data) => dispatch(setRealtimeLeaders(data)));
    bindToEvent(KUDOS_POPULAR_CATEGORIES, (data) => dispatch(setRealtimePopularCategories(data)));
    bindToEvent(KUDOS_NOMINATION_LIKE, (data) =>
      dispatch(setRealtimeNominationLike(basicProfile.id, data))
    );
    bindToEvent(KUDOS_NOMINATION_COMMENT, (data) =>
      dispatch(setRealtimeNominationComment(directoryUsers, data))
    );
    bindToEvent(KUDOS_NOMINATION_COMMENT_UPDATE, (data) =>
      dispatch(setRealtimeNominationCommentUpdate(data))
    );
    bindToEvent(KUDOS_NOMINATION_COMMENT_REMOVE, (data) =>
      dispatch(setRealtimeNominationCommentRemove(data))
    );
    bindToEvent(KUDOS_NOMINATION_REPLY, (data) =>
      dispatch(setRealtimeNominationReply(directoryUsers, data))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoadPersonalNominations = () => {
    if (!!id && isEmpty(personalNominations)) {
      setPersonalNominationsLoading(true);
      dispatch(loadPersonalNominations(id, () => setPersonalNominationsLoading(false)));
    }
  };

  const handleRemoveNominationMessage = (id, userId) => {
    const user = directoryUsers.find((user) => user.id === userId);

    if (!user) {
      throw Error('The user could not be found');
    }

    dispatch(removeNominationMessage(id, user));
  };

  const mappedLeaders = useMemo(() => {
    if (isEmpty(directoryUsers)) {
      return [];
    }

    return mapLeadersToUser(leaders, directoryUsers);
  }, [directoryUsers, leaders]);

  const mappedCompanyNominations = useMemo(() => {
    if (!basicProfile || isEmpty(directoryUsers)) {
      return [];
    }

    return mapNominations(id, companyNominations, directoryUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyNominations, basicProfile, directoryUsers]);

  const mappedPersonalNominations = useMemo(() => {
    if (!basicProfile || isEmpty(directoryUsers)) {
      return [];
    }

    return mapNominations(id, personalNominations, directoryUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalNominations, basicProfile, directoryUsers]);

  const handleSetSelectedTab = (tab) => {
    if (tab === KUDOS_TABS.PERSONAL_TIMELINE) {
      handleLoadPersonalNominations();
    }

    setSelectedTab(tab);
  };

  const value = {
    mobile,
    leaders: mappedLeaders,
    companyNominations: mappedCompanyNominations,
    personalNominations: mappedPersonalNominations,
    loadingPersonalNominations: loadingPersonalNominations,
    selectedTab,
    setSelectedTab: handleSetSelectedTab,
    isModalOpen,
    setIsModalOpen,
    handleRemoveNominationMessage,
    basicProfile
  };

  return <KudosContext.Provider value={value}>{children}</KudosContext.Provider>;
};

export default KudosProvider;
