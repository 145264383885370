import axios from 'axios';
import { useEffect } from 'react';

import { RESPONSE_STATUS } from '../tool/constant';

export const useAxiosInterceptor = (navigate) => {
  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (!error.response) {
          return Promise.reject(error);
        }

        const status = error.response.status;
        const skipForbidden = error.config?.skipForbidden;

        error.config = { ...error.config, skipNotice: false };

        if (status === RESPONSE_STATUS.UNAUTHORIZED) {
          error.config = { ...error.config, skipNotice: true };
          navigate('/');
        } else if (status === RESPONSE_STATUS.FORBIDDEN && !skipForbidden) {
          error.config = { ...error.config, skipNotice: true };
          navigate('/dashboard');
        }

        return Promise.reject(error);
      }
    );

    // Cleans up the interceptor when component unmounts
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
