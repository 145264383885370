import { arrayOf, bool, func, shape, string } from 'prop-types';
import React, { useMemo, useState } from 'react';

import { isValuePresent } from '@neslotech/ui-utils';

import { TASK_ALLOCATION_TYPE } from '../../../../../tool/prop-types';

import { FormAction } from '../../../../../common/component/form/form-action/FormAction';

import { Datepicker } from '../../../../../common/component/datepicker/Datepicker';
import { Form } from '../../../../../common/component/form/Form';
import { FormRow } from '../../../../../common/component/form/form-row/FormRow';
import { Input } from '../../../../../common/component/input/Input';
import { SearchOptions } from '../../../../../common/component/input/search/options/SearchOptions';
import { TextArea } from '../../../../../common/component/textarea/TextArea';

import { ReactComponent as ChevronIcon } from '../../../../../icon/chevron-icon.svg';

import { SearchOptionsWithAdd } from '../../../search-with-add/SearchOptionsWithAdd';

import './task-allocation-form.scss';

const REQUIRED_FIELDS = Object.freeze(['hours', 'startDate', 'projectId', 'taskId']);

const DEFAULT_FORM_STATE = {
  hours: 8,
  startDate: new Date(),
  description: '',
  projectId: '',
  taskId: '',
  endDate: ''
};

export const TaskAllocationForm = ({
  onSubmit,
  onClose,
  projects,
  tasks,
  isAdmin,
  users,
  loggedInUserId,
  setProjectId,
  allocation = {},
  isEditing = false,
  onRemove,
  onAddProjectTask
}) => {
  const projectsOptions = projects.map((project) => ({
    value: project.id,
    label: project.name
  }));

  const tasksOptions = tasks.map((task) => ({
    value: task.id,
    label: task.title
  }));

  const usersOptions = users.map((user) => ({
    value: user.id,
    label: `${user.firstName} ${user.lastName}`
  }));

  const initialFormState = {
    hours: allocation.hours || DEFAULT_FORM_STATE.hours,
    startDate: allocation.startDate ? new Date(allocation.startDate) : DEFAULT_FORM_STATE.startDate,
    endDate: allocation.endDate ? new Date(allocation.endDate) : DEFAULT_FORM_STATE.endDate,
    description: allocation.description || DEFAULT_FORM_STATE.description,
    projectId: allocation.projectId || DEFAULT_FORM_STATE.projectId,
    taskId: allocation.taskId || DEFAULT_FORM_STATE.taskId
  };

  const [form, setForm] = useState(initialFormState);

  const isSubmitDisabled = useMemo(
    () => REQUIRED_FIELDS.some((field) => !isValuePresent(form[field])),
    [form]
  );

  const [selectedUserId, setSelectedUserId] = useState(allocation.assignee || loggedInUserId);

  const handleInputChange = (newState) => {
    if (newState.projectId) {
      setProjectId(newState.projectId);
    }
    setForm({ ...form, ...newState });
  };
  const handleUserChange = (user) => setSelectedUserId(user.userId);

  const handleModalClose = (e) => {
    e.stopPropagation();
    onClose();
    setProjectId(null);
  };

  return (
    <section className="task-allocation-form">
      <Form>
        <div className="task-allocation-form__time-wrapper">
          <FormRow>
            <div className="task-allocation-form__time-inputs">
              <Input name="hours" value={form.hours} onChange={handleInputChange} required />
              <span>Hours/Day</span>
            </div>
            <Datepicker
              label="Start Date"
              name="startDate"
              minDate={new Date(2021, 0)}
              maxDate={form.endDate}
              yearDropdownItemNumber={100}
              value={form.startDate}
              onChange={handleInputChange}
              required
            />
            <Datepicker
              label="End Date"
              name="endDate"
              minDate={form.startDate || new Date(1900, 0)}
              yearDropdownItemNumber={100}
              value={form.endDate}
              onChange={handleInputChange}
            />
          </FormRow>
          <div className="task-allocation-form__time-actions">
            <span>Doesn’t Repeat</span>
            <ChevronIcon />
          </div>
        </div>
        <div className="task-allocation-form__time-content">
          <FormRow fullWidth>
            <SearchOptions
              label="Project"
              secondaryLabel="Project"
              name="projectId"
              options={projectsOptions}
              value={form.projectId}
              onChange={handleInputChange}
              buttonAlignEnd
            />
          </FormRow>
          <FormRow fullWidth>
            <SearchOptionsWithAdd
              label="Tasks"
              secondaryLabel="Tasks"
              name="taskId"
              options={tasksOptions}
              value={form.taskId}
              onChange={handleInputChange}
              buttonAlignEnd
              onAdd={onAddProjectTask}
            />
          </FormRow>
          <FormRow>
            <TextArea
              label="Additional Information"
              name="description"
              value={form.description}
              onChange={handleInputChange}
            />
          </FormRow>
          {isAdmin && (
            <FormRow fullWidth>
              <SearchOptions
                label="Assigned To"
                secondaryLabel="Assigned To"
                name="userId"
                options={usersOptions}
                value={selectedUserId}
                onChange={handleUserChange}
                buttonAlignEnd
              />
            </FormRow>
          )}
        </div>
      </Form>
      <div className="task-allocation-form__actions">
        <FormAction
          label={isEditing ? 'Update' : 'Create'}
          disabled={isSubmitDisabled}
          onClick={() => onSubmit(form, selectedUserId)}
        />
        <FormAction label="Cancel" secondary onClick={(e) => handleModalClose(e)} />
        {isEditing && <button onClick={onRemove}>Delete</button>}
      </div>
    </section>
  );
};

TaskAllocationForm.propTypes = {
  onSubmit: func.isRequired,
  onClose: func.isRequired,
  isAdmin: bool.isRequired,
  users: arrayOf(
    shape({
      firstName: string,
      lastName: string,
      id: string
    })
  ).isRequired,
  tasks: arrayOf(
    shape({
      title: string,
      id: string
    })
  ).isRequired,
  projects: arrayOf(
    shape({
      name: string,
      id: string
    })
  ).isRequired,
  loggedInUserId: string.isRequired,
  allocation: TASK_ALLOCATION_TYPE,
  isEditing: bool,
  onRemove: func.isRequired,
  onAddProjectTask: func.isRequired
};
