import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isEmpty } from '@neslotech/ui-utils';

import { mapTimesheetProject } from '../../../../tool/scheduling.util';

import { loadAllProjectsMetadata } from '../../../../state/action/scheduling/project.action';
import {
  createTimesheet,
  loadUserTimesheet
} from '../../../../state/action/scheduling/timesheet/timesheet.action';

import { useAuth } from '../../../../hook/useAuth';
import { useProgressLoader } from '../../../../hook/useProgressLoader';

import { Loader } from '../../../../common/component/loader/Loader';
import { TimesheetForm } from '../../../../component/scheduling/timesheet/form/TimesheetForm';

export const CreateTimesheetContainer = ({ onClose }) => {
  const { id } = useAuth();
  const dispatch = useDispatch();
  const { setBusyState } = useProgressLoader();

  const timesheet = useSelector(({ timesheet_store }) => timesheet_store.userTimesheet);
  const stateTimesheetLoading = useSelector(({ timesheet_store }) => timesheet_store.loading);

  const projects = useSelector(({ project_store }) => project_store.projectsMetadata);

  const [timesheetLoading, setTimesheetLoading] = useState(stateTimesheetLoading ?? true);
  const [projectsLoading, setProjectsLoading] = useState(false);

  const handleSubmit = () => {
    setBusyState(true);
    dispatch(createTimesheet(id, onClose, () => setBusyState(false)));
  };

  useEffect(() => {
    dispatch(loadUserTimesheet(id, () => setTimesheetLoading(false), onClose));

    if (isEmpty(projects)) {
      setProjectsLoading(true);
      dispatch(loadAllProjectsMetadata(() => setProjectsLoading(false)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mappedTimesheet = useMemo(
    () => mapTimesheetProject(timesheet, projects),
    [projects, timesheet]
  );

  if (timesheetLoading || projectsLoading) {
    return <Loader dark />;
  }

  return <TimesheetForm onSave={handleSubmit} timesheet={mappedTimesheet} onCancel={onClose} />;
};
