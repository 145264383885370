import { arrayOf, bool, func } from 'prop-types';
import React, { createRef, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getClassNames } from '@neslotech/ui-utils';

import { FILTER_OPTIONS } from '../../../employee-directory/employee-directory.helper';

import { Tooltip } from '../../../../common/layout/portal/tooltip/Tooltip';
import { USER_AVATAR_ALT } from '../../../../tool/constant';
import { filterElement } from '../../../../tool/filter.util';
import { USER_TYPE } from '../../../../tool/prop-types';
import {
  calculateDaysOfWeek,
  calculateHeight,
  filterAllocations
} from '../../../../tool/scheduling.util';

import { useFilters } from '../../../../hook/useFilter';

import { Dropdown } from '../../../../common/component/dropdown/Dropdown';
import { FiltersWrapper } from '../../../../common/component/filter/FiltersWrapper';
import NoResults from '../../../../common/component/no-results/NoResults';

import { ReactComponent as ChevronIcon } from '../../../../icon/chevron-icon.svg';

import { SchedulingCalendarView } from './view/SchedulingCalendarView';

import './scheduling-calendar.scss';

export const SchedulingCalendar = ({
  users = [],
  personal = false,
  scrollToToday,
  setScrollToToday,
  loggedInUser
}) => {
  const allocations = useSelector(
    ({ scheduling_allocation_store }) => scheduling_allocation_store.allocations
  );
  const LOCAL_STORAGE_KEY = 'scheduling_filters';

  const savedFilters = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));

  const { filters, setDefaultOptions } = useFilters();

  let defaultFilters = FILTER_OPTIONS;

  useEffect(() => {
    if (savedFilters) {
      defaultFilters.forEach((filter) => {
        const savedFilter = savedFilters[filter.name.toLowerCase()];
        if (savedFilter) {
          filter.values.forEach((value) => {
            value.selected = savedFilter.filterValues.includes(value.value);
          });
        }
      });
    }

    setDefaultOptions(defaultFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filters) {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(filters));
    }
  }, [filters]);

  const filteredUsers = useMemo(
    () => users.filter((user) => filterElement(user, filters || savedFilters)),
    [users, filters, savedFilters]
  );

  const userHeights = useMemo(() => {
    return filteredUsers.reduce((accum, user) => {
      const daysOfWeek = calculateDaysOfWeek();
      const hoursForWeek = daysOfWeek.map((day) => {
        const filteredAllocations = filterAllocations(allocations, day, user?.id);
        return Object.values(
          filteredAllocations.reduce((accum, allocation) => {
            if (!accum[day]) {
              accum[day] = 0;
            }

            accum[day] += allocation.hours;
            return accum;
          }, {})
        ).filter((totalHours) => totalHours > 0);
      });

      accum[user.id] = calculateHeight(Math.max(...Object.values(hoursForWeek)));
      return accum;
    }, {});
  }, [allocations, filteredUsers]);

  const tooltipRefs = useMemo(() => {
    if (filteredUsers) {
      return filteredUsers.map(() => createRef());
    }

    return [];
  }, [filteredUsers]);

  return (
    <section className="scheduling-calendar">
      <div className="scheduling-calendar__members">
        <header>
          <Dropdown dropdownChildren={<FiltersWrapper />} placement="bottom-start">
            <div className="scheduling-calendar__filter">
              <span>Filter</span>
              <ChevronIcon />
            </div>
          </Dropdown>
          <span className="scheduling-calendar__members--time">25.2h</span>
        </header>
        {filteredUsers.length > 0 ? (
          filteredUsers.map((user, index) => {
            return (
              <div
                className={getClassNames('scheduling-calendar__user-info', { personal })}
                key={user.id}
                style={{
                  height: `${userHeights[user.id]}px`
                }}
              >
                <img referrerPolicy="no-referrer" src={user.image} alt={USER_AVATAR_ALT} />
                <div>
                  <span className="scheduling-calendar__name">{`${user.firstName} ${user.lastName}`}</span>
                  <span className="scheduling-calendar__job-title">{user.jobTitle}</span>
                  <span className="scheduling-calendar__department">{user.department}</span>
                </div>
                <span className="scheduling-calendar__members--time" ref={tooltipRefs[index]}>
                  25.2h
                </span>
                <Tooltip parent={tooltipRefs[index]} offset={60}>
                  <div className="scheduling-calendar__tooltip">
                    <div>
                      <span
                        // TODO: Change this color when time calculations are implemented
                        style={{ color: '#EB5D3E' }}
                      >
                        25h
                      </span>
                      <span>Scheduled</span>
                    </div>

                    <div>
                      <span>7h</span>
                      <span>Leave</span>
                    </div>
                  </div>
                </Tooltip>
              </div>
            );
          })
        ) : (
          <div className="scheduling-calendar__empty-state">
            <NoResults
              emptyTitle="There were no results found"
              emptySubtitle="Try removing some filters to see more results."
            />
          </div>
        )}
      </div>
      <SchedulingCalendarView
        userHeights={userHeights}
        users={filteredUsers}
        personal={personal}
        scrollToToday={scrollToToday}
        setScrollToToday={setScrollToToday}
        loggedInUser={loggedInUser}
      />
    </section>
  );
};

SchedulingCalendar.propTypes = {
  users: arrayOf(USER_TYPE),
  personal: bool,
  scrollToToday: bool.isRequired,
  setScrollToToday: func.isRequired,
  loggedInUser: USER_TYPE
};
