import {
  ADD_REALTIME_NOTIFICATION,
  SET_ALL_NOTIFICATIONS,
  SET_NOTIFICATIONS_BUSY
} from '../action/notification.action';

export const initialState = {
  allNotifications: [],
  loading: false
};

export function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ALL_NOTIFICATIONS:
      const { allNotifications } = action;
      return {
        ...state,
        allNotifications
      };
    case ADD_REALTIME_NOTIFICATION:
      const { notification } = action;
      return {
        ...state,
        allNotifications: [notification, ...state.allNotifications]
      };
    case SET_NOTIFICATIONS_BUSY:
      return {
        ...state,
        loading: action.loading
      };
    default:
      return state;
  }
}
