import { bool } from 'prop-types';
import React, { useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { MONTHS, getClassNames, howLongAgo, noOp, toNumericDateString } from '@neslotech/ui-utils';

import { SOCIAL_TYPE } from '../../../tool/constant';
import { PROFILE_TYPE } from '../../../tool/prop-types';

import { useDevice } from '../../../hook/useDevice';

import { Card } from '../../../common/component/card/Card';
import SkeletonLoader from '../../../common/component/loader/skeleton/SkeletonLoader';

import { ReactComponent as BehanceIcon } from '../../../icon/behance-icon.svg';
import { ReactComponent as CloseIcon } from '../../../icon/close-icon.svg';
import { ReactComponent as CopyToClipboardIcon } from '../../../icon/copy-to-clipboard-icon.svg';
import { ReactComponent as EditIcon } from '../../../icon/edit-icon.svg';
import { ReactComponent as EnvelopeIcon } from '../../../icon/envelope-icon.svg';
import { ReactComponent as GithubIcon } from '../../../icon/github-icon.svg';
import { ReactComponent as LinkedInIcon } from '../../../icon/linkedin-icon.svg';
import { ReactComponent as PhoneIcon } from '../../../icon/phone-icon.svg';
import { ReactComponent as UserIcon } from '../../../icon/user-icon.svg';

import './basic-profile.scss';

const renderBirthday = (birthday) => {
  if (!birthday) {
    return <span>Not Available</span>;
  }

  const date = new Date(birthday);

  return (
    <>
      <span>{toNumericDateString(date)}</span>
      <span>{MONTHS[date.getMonth()]}</span>
    </>
  );
};

const renderSocialIcons = (details) => {
  switch (details.type) {
    case SOCIAL_TYPE.GIT_HUB:
      return (
        <a key={details.type} href={details.link} target="_blank" rel="noreferrer">
          <GithubIcon />
        </a>
      );
    case SOCIAL_TYPE.BEHANCE:
      return (
        <a key={details.type} href={details.link} target="_blank" rel="noreferrer">
          <BehanceIcon />
        </a>
      );
    case SOCIAL_TYPE.LINKED_IN:
      return (
        <a key={details.type} href={details.link} target="_blank" rel="noreferrer">
          <LinkedInIcon />
        </a>
      );
    default:
      throw Error('The social link type provided is not known.');
  }
};

const JobTitleAndDepartment = ({ profile }) => {
  const { mobile } = useDevice();

  if (mobile) {
    return (
      <div>
        <small>{profile?.jobTitle}</small>
        <small>{profile?.department ?? 'Department'}</small>
      </div>
    );
  }

  return (
    <small>
      {profile?.jobTitle} | {profile?.department ?? 'Department'}
    </small>
  );
};

const ContactActions = ({ profile }) => {
  const [opened, setOpened] = useState(false);
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    const copyText = `${profile.emergencyContact}\n${profile.emergencyContactNumber}`;

    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 800);
    });
  };

  return (
    <section className="basic-profile__contact-actions">
      {copied && <span>Copied to clipboard</span>}
      <ContactAction
        hidden={opened}
        icon={<EnvelopeIcon />}
        title="Email"
        onClick={() => (window.location.href = `mailto:${profile.email}`)}
      />
      <ContactAction
        hidden={opened}
        icon={<PhoneIcon />}
        title="Phone"
        onClick={() => (window.location.href = `tel:${profile.phoneNumber}`)}
      />
      <ContactAction open={opened} icon={<UserIcon />} title="SOS" onClick={() => setOpened(true)}>
        <section className="contact-action__details">
          <span>{profile?.emergencyContact}</span>
          <span>{profile?.emergencyContactNumber}</span>
        </section>
        <section className="contact-action__actions">
          <CopyToClipboardIcon onClick={copyToClipboard} />
          <PhoneIcon
            onClick={() => (window.location.href = `tel:${profile.emergencyContactNumber}`)}
          />
          <CloseIcon
            onClick={(e) => {
              e.stopPropagation();
              setOpened(false);
            }}
          />
        </section>
      </ContactAction>
    </section>
  );
};

const ContactAction = ({ icon, title, onClick, open, hidden, children }) => {
  if (hidden) {
    return null;
  }

  return (
    <article
      className={getClassNames('contact-action', { open })}
      onKeyDown={noOp}
      onClick={onClick}
    >
      {!open && (
        <>
          {icon}
          <span>{title}</span>
        </>
      )}
      {open && children}
    </article>
  );
};

const BasicProfile = ({ profile, editable, loading }) => {
  const { mobile } = useDevice();
  const skeletonRef = useRef();

  const timeAtNeslo = useMemo(() => {
    return howLongAgo(profile?.joinedOn)?.split(' ');
  }, [profile?.joinedOn]);

  return (
    <SkeletonLoader belowBreadcrumb loading={loading} elementRef={skeletonRef}>
      <section className="basic-profile" ref={skeletonRef}>
        <div className="basic-profile__header">
          {(profile?.socialLinks ?? []).map((details) => renderSocialIcons(details))}
        </div>
        <img
          src={profile?.image ?? profile?.defaultImage}
          alt={`The uploaded profile of the user ${profile?.fullName}`}
          referrerPolicy="no-referrer"
        />
        <div className="basic-profile__personal-details">
          <h5>{profile?.fullName}</h5>
          <JobTitleAndDepartment profile={profile} />
          {editable && (
            <Link to={`/users/${profile?.id}/profile/edit`}>
              <EditIcon />
            </Link>
          )}
        </div>
        <div className="basic-profile__contact-details">
          {!mobile && (
            <>
              <div className="basic-profile__contact">
                <EnvelopeIcon />
                <div>
                  <small>Email</small>
                  <span>{profile?.email}</span>
                </div>
              </div>
              <div className="basic-profile__contact">
                <PhoneIcon />
                <div>
                  <small>Phone</small>
                  <span>{profile?.phoneNumber}</span>
                </div>
              </div>
              <div className="basic-profile__contact">
                <UserIcon />
                <div>
                  <small>Emergency Contact</small>
                  <span>
                    <span>{profile?.emergencyContact}</span>
                    <span>{profile?.emergencyContactNumber}</span>
                  </span>
                </div>
              </div>
            </>
          )}
          <div className="basic-profile__socials">
            {(profile?.socialLinks ?? []).map((details) => (
              <div className="basic-profile__contact" key={details.type}>
                {renderSocialIcons(details)}
              </div>
            ))}
          </div>
        </div>
        {mobile && <ContactActions profile={profile} />}
        <p>{profile?.bio}</p>
        <div className="basic-profile__cards">
          <Card grey cardTitle={`Joined on: ${new Date(profile?.joinedOn).toLocaleDateString()}`}>
            <h5>Time at Neslo</h5>
            <div className="basic-profile__counter">
              <span>{timeAtNeslo[0]}</span>
              <span>{timeAtNeslo[1]}</span>
            </div>
          </Card>
          <Card grey>
            <h5>Projects</h5>
            <div className="basic-profile__counter">
              <span>0</span>
            </div>
          </Card>
          <Card grey>
            <h5>Birthday</h5>
            <div
              className={getClassNames('basic-profile__counter', {
                'not-available': !profile?.birthday
              })}
            >
              {renderBirthday(profile?.birthday)}
            </div>
          </Card>
        </div>
      </section>
    </SkeletonLoader>
  );
};

BasicProfile.defaultProps = {
  profile: undefined,
  editable: true,
  loading: false
};

BasicProfile.propTypes = {
  profile: PROFILE_TYPE,
  editable: bool,
  loading: bool
};

export default BasicProfile;
