import { arrayOf, bool } from 'prop-types';
import React, { useEffect, useMemo, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { isEmpty } from '@neslotech/ui-utils';

import { FILTER_OPTIONS, getEmployeeProfileURL, mapUsers } from './employee-directory.helper';

import { USER_TYPE } from '../../tool/prop-types';

import { useDevice } from '../../hook/useDevice';
import { useFilters } from '../../hook/useFilter';

import { Card } from '../../common/component/card/Card';
import { MobileCard } from '../../common/component/card/MobileCard';
import SkeletonLoader from '../../common/component/loader/skeleton/SkeletonLoader';
import NoResults from '../../common/component/no-results/NoResults';

import { FilterLayout } from '../../common/layout/filter/FilterLayout';

import './employee-directory.scss';

const FlipCard = ({ user, children: [front, back] }) => (
  <Link to={getEmployeeProfileURL(user.id)}>
    <article className="flip-card">
      <div className="flip-card__inner">
        <section className="flip-card__front">{front}</section>
        <section className="flip-card__back">{back}</section>
      </div>
    </article>
  </Link>
);

const EmployeeCards = ({ users, loading }) => {
  const { search, filters, setDefaultOptions } = useFilters();
  const skeletonRef = useRef();

  const filteredUsers = useMemo(() => {
    return mapUsers(users, search, filters);
  }, [users, search, filters]);

  useEffect(() => {
    setDefaultOptions(FILTER_OPTIONS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [FILTER_OPTIONS]);

  return (
    <SkeletonLoader loading={loading} elementRef={skeletonRef}>
      <ul className="employee-directory__cards" ref={skeletonRef}>
        {filteredUsers.map((user) => (
          <FlipCard key={user.id} user={user}>
            <Card borderless>
              <section className="employee-directory__employee-details">
                <header className="employee-directory__image">
                  <img
                    src={user.image ?? user.defaultImage}
                    referrerPolicy="no-referrer"
                    alt="Profile icon"
                  />
                </header>
                <h2>
                  {user.firstName} {user.lastName}
                </h2>
                <span>{user.jobTitle}</span>
                <p>{user.department}</p>
              </section>
            </Card>
            <Card borderless>
              <section className="employee-directory__bio">
                <p>{user.bio ?? 'Not Available'}</p>
              </section>
            </Card>
          </FlipCard>
        ))}
        {isEmpty(filteredUsers) && (
          <NoResults
            emptyTitle="There were no results found"
            emptySubtitle={
              filters
                ? 'Try removing some filters to see more results'
                : 'Try editing your search to see more results'
            }
          />
        )}
      </ul>
    </SkeletonLoader>
  );
};

const EmployeeMobileCards = ({ users, loading }) => {
  const navigate = useNavigate();
  const skeletonRef = useRef();

  const { search, filters, setDefaultOptions } = useFilters();

  useEffect(() => {
    setDefaultOptions(FILTER_OPTIONS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [FILTER_OPTIONS]);

  const filteredUsers = useMemo(() => {
    return mapUsers(users, search, filters);
  }, [users, search, filters]);

  if (isEmpty(filteredUsers)) {
    return (
      <NoResults
        emptyTitle="There were no results found"
        emptySubtitle={
          filters
            ? 'Try removing some filters to see more results'
            : 'Try editing your search to see more results'
        }
      />
    );
  }

  return (
    <SkeletonLoader loading={loading} elementRef={skeletonRef}>
      <ul className="employee-directory-mobile-cards" ref={skeletonRef}>
        {filteredUsers.map((user) => (
          <MobileCard onClick={() => navigate(getEmployeeProfileURL(user.id))} key={user.id}>
            <img
              referrerPolicy="no-referrer"
              src={user?.image ?? user?.defaultImage}
              alt={`${user?.firstName} ${user?.lastName}`}
            />
            <section className="employee-directory-mobile-cards__wrapper">
              <span>{`${user?.firstName} ${user?.lastName}`}</span>
              <span>{user?.jobTitle}</span>
              <span>{user?.department}</span>
            </section>
          </MobileCard>
        ))}
      </ul>
    </SkeletonLoader>
  );
};

const EmployeeDirectory = ({ users, loading }) => {
  const { mobile } = useDevice();

  return (
    <article className="employee-directory">
      <FilterLayout label="Search for something">
        {mobile ? (
          <EmployeeMobileCards users={users} loading={loading} />
        ) : (
          <EmployeeCards users={users} loading={loading} />
        )}
      </FilterLayout>
    </article>
  );
};

EmployeeDirectory.propTypes = {
  users: arrayOf(USER_TYPE),
  loading: bool
};

EmployeeDirectory.defaultProps = {
  users: [],
  loading: false
};

export default EmployeeDirectory;
