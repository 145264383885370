import { func } from 'prop-types';
import React, { useState } from 'react';

import { getClassNames, noOp } from '@neslotech/ui-utils';

import { ROLE_OPTIONS } from '../../../../tool/constant';

import { useDevice } from '../../../../hook/useDevice';

import { Button } from '../../../../common/component/button/Button';
import { Form } from '../../../../common/component/form/Form';
import { FormRow } from '../../../../common/component/form/form-row/FormRow';
import { Input } from '../../../../common/component/input/Input';
import { Select } from '../../../../common/component/select/Select';

import './add-user.scss';

const AddUser = ({ errors, onAddClick, onClose }) => {
  const [form, setForm] = useState({});

  const { mobile } = useDevice();

  const handleChange = (newState) => {
    setForm({ ...form, ...newState });
  };

  const handleOnAddClick = () => {
    onAddClick(form);
  };

  return (
    <article className={getClassNames('add-user', { mobile })}>
      {!mobile && (
        <>
          <h3>Add new employee</h3>
          <hr />
          <span>Add a new employee to the Neslo App.</span>
        </>
      )}
      <Form>
        <FormRow>
          <Input
            label="First Name"
            name="firstName"
            value={form.firstName}
            error={errors?.firstName}
            onChange={handleChange}
          />
          <Input
            label="Last Name"
            name="lastName"
            value={form.lastName}
            error={errors?.lastName}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow>
          <Select
            options={ROLE_OPTIONS}
            label="User Role"
            name="role"
            value={form.role}
            error={errors?.role}
            onChange={handleChange}
          />
          <Input
            label="Username"
            name="username"
            value={form.username}
            error={errors?.firstName}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow>
          <Input
            label="Alternative Email Address"
            name="alternateEmail"
            type="email"
            value={form.alternateEmail}
            error={errors?.alternateEmailAddress}
            onChange={handleChange}
          />
        </FormRow>
      </Form>
      {!mobile && <hr />}
      <section className="add-user__actions">
        <Button label="Add" onClick={handleOnAddClick} />
        {!mobile && <Button label="Cancel" onClick={onClose} secondary />}
      </section>
    </article>
  );
};

AddUser.defaultProps = {
  onAddClick: noOp,
  onClose: noOp
};

AddUser.propTypes = {
  onAddClick: func,
  onClose: func
};

export default AddUser;
