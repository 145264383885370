import { bool, func, number, string } from 'prop-types';
import React, { useState } from 'react';

import { getClassNames, isEmpty, noOp } from '@neslotech/ui-utils';

import { ReactComponent as MinusIcon } from '../../../icon/minus-icon.svg';
import { ReactComponent as PlusIcon } from '../../../icon/plus-icon.svg';

import InputError from '../input/error/InputError';

import './number-picker.scss';

const NumberPicker = ({ id, value, name, label, error, disabled, onChange, min, max }) => {
  const [focused, setFocused] = useState(false);

  const clamp = (number) => {
    //In the case of an empty string
    if (isEmpty(number)) {
      return min;
    }

    return Math.min(Math.max(parseInt(number), min), max);
  };

  const handleIncrement = (e, increment) => {
    e.preventDefault();
    if (!disabled) {
      onChange(clamp(value + increment));
    }
  };

  return (
    <div className="number-picker">
      <label htmlFor={id ?? name} className="number-picker__label">
        {label}
      </label>
      <div className="number-picker__field-container">
        <fieldset>
          <button
            type="button"
            className={getClassNames('number-picker__increment', { focused })}
            onClick={(e) => handleIncrement(e, -1)}
          >
            <MinusIcon />
          </button>
          <input
            id={id ?? name}
            className={getClassNames('number-picker__input', { focused })}
            type="number"
            value={value}
            min={min}
            max={max}
            disabled={disabled}
            onChange={disabled ? noOp : (e) => onChange(clamp(e.target.value))}
            onFocus={disabled ? noOp : () => setFocused(true)}
            onBlur={disabled ? noOp : () => setFocused(false)}
          />
          <button
            type="button"
            className={getClassNames('number-picker__increment', { focused })}
            onClick={(e) => handleIncrement(e, 1)}
          >
            <PlusIcon />
          </button>
        </fieldset>
      </div>
      {error && <InputError error={error} />}
    </div>
  );
};

NumberPicker.defaultProps = {
  id: '',
  value: undefined,
  label: '',
  error: '',
  disabled: false,
  onChange: noOp
};

NumberPicker.protoTypes = {
  id: string,
  value: number,
  label: string,
  error: string,
  disabled: bool,
  onChange: func,
  min: number.isRequired,
  max: number.isRequired
};

export default NumberPicker;
