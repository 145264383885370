import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ROLES } from '../../tool/constant';

import { loadDirectoryUsers } from '../../state/action/directory.action';
import { loadAllProjectsMetadata } from '../../state/action/scheduling/project.action';
import { loadUsers } from '../../state/action/user.action';

import AdminDashboardContainer from './AdminDashboard.container';
import EmployeeDashboardContainer from './EmployeeDashboard.container';

import AppLayout from '../../component/layout/AppLayout';

const DashboardContainer = () => {
  const dispatch = useDispatch();

  const profile = useSelector(
    ({ profile_store }) => profile_store.contextUser ?? profile_store.profile
  );

  const profileStateLoading = useSelector(({ profile_store }) => profile_store.loading);

  const users = useSelector(({ user_store }) => user_store.users);

  const directoryUsers = useSelector(({ directory_store }) => directory_store.directoryUsers);

  const projects = useSelector(({ project_store }) => project_store.projectsMetadata);

  const [usersLoading, setUsersLoading] = useState(true);
  const [directoryLoading, setDirectoryLoading] = useState(true);
  const [projectsLoading, setProjectsLoading] = useState(true);

  useEffect(() => {
    if (profile?.role === ROLES.ADMIN) {
      dispatch(loadUsers(() => setUsersLoading(false)));
    }
    dispatch(loadAllProjectsMetadata(() => setProjectsLoading(false)));
    dispatch(loadDirectoryUsers(() => setDirectoryLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const loading = profileStateLoading || usersLoading || directoryLoading || projectsLoading;

  const renderDashboard = () => {
    if (profile?.role === ROLES.ADMIN) {
      return (
        <AdminDashboardContainer
          profile={profile}
          users={users}
          directoryUsers={directoryUsers}
          projects={projects}
          loading={loading}
        />
      );
    }

    return (
      <EmployeeDashboardContainer
        profile={profile}
        directoryUsers={directoryUsers}
        loading={profileStateLoading}
      />
    );
  };

  return <AppLayout showBreadcrumb={false}>{renderDashboard()}</AppLayout>;
};

export default DashboardContainer;
