import { arrayOf, bool, func } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { deepClone, noOp } from '@neslotech/ui-utils';

import { getSkeletonPlaceholders } from '../../../../tool/loader.helper';

import { CHECKBOX_TABLE_ROW_TYPE, CHECKBOX_TABLE_USER_TYPE } from '../../../../tool/prop-types';

import { useFilters } from '../../../../hook/useFilter';

import { Table } from '../../../../common/component/table/Table';

import Checkbox from '../Checkbox';

import './user-checkbox-table.scss';

const CheckboxTableCell = ({ row, rows, setRows, emitSelectedRows }) => {
  const allRowsChecked = useMemo(() => rows.every((row) => row.checked), [rows]);

  const handleSelectAllCheckbox = () => {
    const clonedRows = deepClone(rows);
    for (const row of clonedRows) {
      row.checked = !allRowsChecked;
    }

    setRows(clonedRows);
    emitSelectedRows(clonedRows);
  };

  const handleCheckbox = (selectedRow) => {
    const clonedRows = deepClone(rows);

    const tableRow = clonedRows.find((row) => row.id === selectedRow.id);
    tableRow.checked = !tableRow.checked;

    setRows(clonedRows);
    emitSelectedRows(clonedRows);
  };

  const isFirstRow = row.id === '0';
  const indeterminate =
    isFirstRow && rows.some((row) => row.checked) && !rows.every((row) => row.checked);
  return (
    <Checkbox
      indeterminate={indeterminate}
      checked={isFirstRow ? allRowsChecked : row.original.checked}
      onChange={isFirstRow ? handleSelectAllCheckbox : () => handleCheckbox(row.original)}
    />
  );
};

CheckboxTableCell.propTypes = {
  row: CHECKBOX_TABLE_ROW_TYPE,
  rows: arrayOf(CHECKBOX_TABLE_USER_TYPE),
  setRows: func,
  emitSelectedRows: func
};

const UserCheckboxTable = ({
  rows: propRows = [],
  loading = false,
  selectedRowsChanged = noOp
}) => {
  const [rows, setRows] = useState([]);
  const { filters } = useFilters();

  // map prop rows to internal rows
  useEffect(() => {
    const latestRows = [];

    for (const propRow of propRows) {
      const tableRow = rows.find((row) => row.id === propRow.id);

      if (!tableRow) {
        // use initial "checked-ness"
        // from prop
        latestRows.push({ ...propRow });
      } else {
        // if the row already exists in state,
        // then get/retain it's current table
        // row "checked-ness" (& ignore the
        // "checked-ness" passed in as prop).
        latestRows.push({ ...propRow, checked: tableRow.checked });
      }
    }

    setRows(latestRows);
    emitSelectedRows(latestRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propRows]);

  const emitSelectedRows = (rowsArg) => {
    selectedRowsChanged(rowsArg.filter((row) => row.checked));
  };

  const mappedRows = useMemo(() => {
    if (loading) {
      return getSkeletonPlaceholders([], 8);
    }

    const finalRows = Array.isArray(rows) ? [...rows] : [];
    if (finalRows.length > 0) {
      finalRows.unshift({ firstName: 'Select All', lastName: '', department: '' });
    }

    return finalRows;
  }, [rows, loading]);

  return (
    <section className="user-checkbox-table">
      <Table
        cols={[
          {
            Header: '',
            accessor: 'checkbox',
            disableSortBy: true,
            Cell: ({ row }) => (
              <CheckboxTableCell
                row={row}
                rows={rows}
                emitSelectedRows={emitSelectedRows}
                setRows={setRows}
              />
            )
          },
          { Header: 'First Name', disableSortBy: true, accessor: 'firstName' },
          { Header: 'Last Name', disableSortBy: true, accessor: 'lastName' },
          { Header: 'Department', disableSortBy: true, accessor: 'department' }
        ]}
        pageLength={1000}
        rowData={mappedRows}
        emptySubtitle={
          filters
            ? 'Try removing some filters to see more results'
            : 'Try editing your search to see more results'
        }
      />
    </section>
  );
};

UserCheckboxTable.propTypes = {
  rows: arrayOf(CHECKBOX_TABLE_USER_TYPE),
  loading: bool,
  selectedRowsChanged: func
};

export default UserCheckboxTable;
