import { bool, func, node, number, oneOf, oneOfType, string } from 'prop-types';
import React, { createRef, useEffect, useMemo, useState } from 'react';

import { getClassNames, noOp } from '@neslotech/ui-utils';

import InputAction from '../action/InputAction';

import InputError from '../error/InputError';
import InputLabel from '../label/InputLabel';

import './search-input.scss';

const getChangeValue = (name, value) => (name ? { [name]: value } : value);

export const SearchInput = ({
  id,
  type = 'text',
  name,
  label,
  secondaryLabel,
  error,
  onChange = noOp,
  onFocus = noOp,
  action,
  onActionClick = noOp,
  disabled = false,
  borderless = false,
  required = false,
  focused,
  value,
  onSearchChange = noOp,
  searchValue,
  light = false
}) => {
  const wrapperRef = createRef();

  const [active, setActive] = useState(false);
  const [filled, setFilled] = useState(!!value);

  const memoizedLabel = useMemo(() => {
    return value ? secondaryLabel : required ? `${label}*` : label;
  }, [required, label, value, secondaryLabel]);

  useEffect(() => {
    setFilled(!!value);
  }, [value]);

  const handleChange = (e) => {
    onChange(getChangeValue(name, e.target.value));
  };

  const handleSearchChange = (e) => {
    if (focused) {
      return;
    }
    onSearchChange(e.target.value);
  };

  const handleFocus = () => {
    wrapperRef.current?.focus();
    setActive(true);
    onFocus();
    onSearchChange(null);
  };

  const handleBlur = () => {
    setActive(false);
    setFilled(!!value);
  };

  return (
    <div
      className={getClassNames('search-input', {
        error,
        active,
        filled,
        disabled,
        borderless
      })}
    >
      <fieldset ref={wrapperRef} className="search-input__wrapper">
        <input
          id={`${name}Search`}
          onChange={disabled ? noOp : handleSearchChange}
          onFocus={disabled || focused ? noOp : handleFocus}
          onBlur={disabled ? noOp : handleBlur}
          name={`${name}Search`}
          value={active ? searchValue : ''}
          disabled={disabled}
          autoComplete="off"
        />
        <input
          id={id ?? name}
          onChange={disabled ? noOp : handleChange}
          type={type}
          name={name}
          value={active ? '' : value}
          placeholder={!active ? memoizedLabel : ''}
          required={required}
          disabled={disabled}
        />
        <InputLabel
          label={secondaryLabel}
          id={id ?? name}
          disabled={disabled}
          active={active}
          filled={filled}
          error={!!error}
          light={light}
        />
        {!error && (
          <InputAction
            action={action}
            onActionClick={disabled ? noOp : onActionClick}
            disabled={disabled}
          />
        )}
      </fieldset>
      {!!error && <InputError error={error} />}
    </div>
  );
};

SearchInput.propTypes = {
  id: string.isRequired,
  type: oneOf(['text', 'number']),
  name: string.isRequired,
  label: string,
  secondaryLabel: string,
  error: string,
  onChange: func,
  onFocus: func,
  onActionClick: func,
  action: oneOfType([string, node]),
  disabled: bool,
  required: bool,
  borderless: bool,
  value: oneOfType([number, string]),
  onSearchChange: func,
  searchValue: string.isRequired
};
