import { arrayOf, node, oneOfType } from 'prop-types';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';

import { getClassNames } from '@neslotech/ui-utils';

import './side-portal.scss';

export const SidePortal = ({ children, open }) => {
  const mount = document.getElementById('side-portal-root');

  const overlay = document.createElement('section');
  overlay.setAttribute('class', 'side-portal__overlay');

  const portalEl = document.createElement('section');
  portalEl.setAttribute('class', 'side-portal');

  const portalContent = document.createElement('section');
  portalContent.setAttribute('class', 'side-portal__content');

  useEffect(() => {
    if (open) {
      portalEl.setAttribute('class', getClassNames('side-portal', { open }));
      overlay.setAttribute('class', getClassNames('side-portal__overlay', { open }));
    }

    mount.appendChild(overlay);
    overlay.appendChild(portalEl);
    portalEl.appendChild(portalContent);

    return () => {
      mount.removeChild(overlay);
    };
  }, [portalEl, mount, overlay, portalContent, open]);

  return createPortal(children, portalContent);
};

SidePortal.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired
};
