import { getHttpGetOptions, getHttpPostOptions } from '@neslotech/ui-utils';

import { getAuthHeaders } from '../../../../tool/auth.util';

import { API_HOST } from '../index';

export const SCHEDULING_ENDPOINT = `${API_HOST}/v1/scheduling`;
const PERMISSIONS_ENDPOINT = `${API_HOST}/v1/scheduling/permissions`;

export const getSaveSchedulingPermissionsRequest = (payload) => [
  PERMISSIONS_ENDPOINT,
  getHttpPostOptions({ users: payload }, getAuthHeaders())
];

export const getLoadAllSchedulingPermissionsRequest = () => [
  PERMISSIONS_ENDPOINT,
  getHttpGetOptions(getAuthHeaders())
];
