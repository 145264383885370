import { arrayOf, number, shape, string } from 'prop-types';

import { isEmpty } from '@neslotech/ui-utils';

import { Card } from '../../../common/component/card/Card';

import { ReactComponent as ArrowIcon } from '../../../icon/arrow-icon.svg';

import Blip from '../../blip/Blip';
import PointsStat from './PointsStat';

import './points-card.scss';

const PointsCardCategories = ({ categories }) => {
  if (isEmpty(categories)) {
    return (
      <p className="points-card__categories-empty-message">
        Oh no, you don't have any nominations yet. <br />
        Your most nominated categories will show up here
      </p>
    );
  }

  return (
    <div className="points-card__categories">
      {categories.map((item) => (
        <Blip
          colour={item.category.colorCode ?? '#000'}
          label={item.category.name}
          secondaryLabel={`x${item.frequency}`}
          testId={item.category.id}
          key={item.category.id}
        />
      ))}
    </div>
  );
};

PointsCardCategories.propTypes = {
  categories: arrayOf(
    shape({
      category: shape({
        colorCode: string,
        name: string
      }),
      frequency: number
    })
  ).isRequired
};

const PointsCard = ({ stats, categories }) => {
  return (
    <section className="points-card">
      <Card transparent>
        <header className="points-card__header">
          <span className="points-card__title">My Points</span>
          <ArrowIcon className="points-card__arrow" />
        </header>
        <div className="points-card__stats">
          {stats.map((stat) => (
            <PointsStat key={stat.id} stat={stat} />
          ))}
        </div>
        <span className="points-card__subtitle">Your most nominated categories:</span>
        <PointsCardCategories categories={categories} />
      </Card>
    </section>
  );
};

PointsCard.propTypes = {
  stats: arrayOf(
    shape({
      id: number,
      value: string,
      description: string
    })
  ).isRequired,
  categories: arrayOf(
    shape({
      category: shape({
        colorCode: string,
        name: string
      }),
      frequency: number
    })
  ).isRequired
};

export default PointsCard;
