import { getHttpGetOptions } from '@neslotech/ui-utils';

import { getAuthHeaders } from '../../../tool/auth.util';

import { API_HOST } from './index';

const getUsersDirectoryUsersEndpoint = () => `${API_HOST}/v1/directory`;

export const getLoadDirectoryUsersRequest = () => [
  getUsersDirectoryUsersEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];
