import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';

import { ApiRequest, HttpVerb, SNACK_CRITICAL } from '@neslotech/ui-utils';

import { getAuthHeaders } from '../../../../tool/auth.util';
import { SCHEDULING_ENDPOINT } from '../../../tool/api/scheduling/scheduling-permissions.endpoint';

import {
  CREATE_LEAVE_ALLOCATION,
  CREATE_USER_LEAVE_ALLOCATION,
  REMOVE_LEAVE_ALLOCATION,
  UPDATE_LEAVE_ALLOCATION
} from '../../../action/scheduling/leave-allocation/leave-allocation.action';
import { addSystemNotice } from '../../../action/system.action';

export function* performCreateLeaveAllocation({ leaveAllocation, onSuccess, onComplete }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      `${SCHEDULING_ENDPOINT}/leave-allocations`,
      HttpVerb.POST,
      getAuthHeaders(),
      leaveAllocation
    );

    yield call(axios, endpoint, axiosOptions);

    yield call(onSuccess);
  } catch ({ response, config }) {
    yield put(
      addSystemNotice(
        'The was an error while creating time off allocation',
        SNACK_CRITICAL,
        config?.skipNotice
      )
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForCreateLeaveAllocation() {
  yield takeLatest(CREATE_LEAVE_ALLOCATION, performCreateLeaveAllocation);
}

export function* performCreateUserLeaveAllocation({
  userId,
  leaveAllocation,
  onSuccess,
  onComplete
}) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      `${SCHEDULING_ENDPOINT}/users/${userId}/leave-allocations`,
      HttpVerb.POST,
      getAuthHeaders(),
      leaveAllocation
    );

    yield call(axios, endpoint, axiosOptions);

    yield call(onSuccess);
  } catch ({ response, config }) {
    yield put(
      addSystemNotice(
        'There was an error while assigning time off for the user',
        SNACK_CRITICAL,
        config?.skipNotice
      )
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForCreateUserLeaveAllocation() {
  yield takeLatest(CREATE_USER_LEAVE_ALLOCATION, performCreateUserLeaveAllocation);
}

export function* performUpdateLeaveAllocation({
  leaveAllocationId,
  userId,
  leaveAllocation,
  onSuccess,
  onComplete
}) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      `${SCHEDULING_ENDPOINT}/users/${userId}/leave-allocations/${leaveAllocationId}`,
      HttpVerb.PUT,
      getAuthHeaders(),
      leaveAllocation
    );

    yield call(axios, endpoint, axiosOptions);

    yield call(onSuccess);
  } catch ({ response }) {
    yield put(
      addSystemNotice(
        'There was an error while updating the time off allocation for the user',
        SNACK_CRITICAL
      )
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateLeaveAllocation() {
  yield takeLatest(UPDATE_LEAVE_ALLOCATION, performUpdateLeaveAllocation);
}

export function* performRemoveLeaveAllocation({
  leaveAllocationId,
  userId,
  onSuccess,
  onComplete
}) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      `${SCHEDULING_ENDPOINT}/users/${userId}/leave-allocations/${leaveAllocationId}`,
      HttpVerb.DELETE,
      getAuthHeaders(),
      {}
    );

    yield call(axios, endpoint, axiosOptions);

    yield call(onSuccess);
  } catch ({ response, config }) {
    yield put(
      addSystemNotice(
        'There was an error while removing the time off allocation',
        SNACK_CRITICAL,
        config?.skipNotice
      )
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForRemoveLeaveAllocation() {
  yield takeLatest(REMOVE_LEAVE_ALLOCATION, performRemoveLeaveAllocation);
}

export default function* leaveAllocationSaga() {
  yield all([
    watchForCreateLeaveAllocation(),
    watchForCreateUserLeaveAllocation(),
    watchForUpdateLeaveAllocation(),
    watchForRemoveLeaveAllocation()
  ]);
}
