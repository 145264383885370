import React from 'react';
import { Link } from 'react-router-dom';

import { isEmpty } from '@neslotech/ui-utils';

import { useKudos } from '../../../hook/useKudos';

import { Card } from '../../../common/component/card/Card';

import './leaderboard.scss';

const Leaders = ({ leaders }) => {
  if (isEmpty(leaders)) {
    return (
      <div className="kudos-leaderboard__empty-message">
        <p>There aren’t enough nominations for a leaderboard.</p>
        <p>Start nominating your co-workers to see the leaderboard grow.</p>
      </div>
    );
  }

  return leaders.map((profile, index) => (
    <div key={profile.id}>
      <img src={profile.image} referrerPolicy="no-referrer" alt="uploaded by the user" />
      <Link to={`/employees/directory/${profile.id}/profile`}>
        <span>{profile.name}</span>
      </Link>
      <span>#{index + 1}</span>
    </div>
  ));
};

const Leaderboard = () => {
  const { leaders } = useKudos();

  return (
    <section className="kudos-leaderboard">
      <Card transparent>
        <h1>My Leaderboard</h1>
        <Leaders leaders={leaders} />
      </Card>
    </section>
  );
};

export default Leaderboard;
