import { SET_KUDOS_CATEGORIES } from '../../action/kudos/management.action';

export const initialState = {
  categories: []
};

export function kudosManagementReducer(state = initialState, action) {
  switch (action.type) {
    case SET_KUDOS_CATEGORIES:
      const { categories } = action;
      return {
        ...state,
        categories
      };
    default:
      return state;
  }
}
