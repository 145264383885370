import { arrayOf, func, shape, string } from 'prop-types';
import React, { useState } from 'react';

import { isValuePresent } from '@neslotech/ui-utils';

import { FormAction } from '../../../../common/component/form/form-action/FormAction';

import { ReactComponent as PlusIcon } from '../../../../icon/plus-icon.svg';
import { ReactComponent as TrashIcon } from '../../../../icon/trash-icon.svg';

import './task-list.scss';

const TaskList = ({ tasks = [], onBack, onSave, onAdd, onRemove }) => {
  const [inputValue, setInputValue] = useState('');

  const handleAddTask = () => {
    if (!isValuePresent(inputValue)) {
      return;
    }

    onAdd(inputValue, () => setInputValue(''));
  };

  const handleRemoveTask = (id) => {
    onRemove(id);
  };

  return (
    <section id="task-list">
      <h1>Tasks</h1>
      <fieldset>
        <input
          placeholder="Add Task"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <button onClick={handleAddTask}>
          <PlusIcon />
        </button>
      </fieldset>

      <ul>
        {tasks.map(({ id, title }) => (
          <li key={id}>
            <span>{title}</span>
            <button onClick={() => handleRemoveTask(id)}>
              <TrashIcon />
            </button>
          </li>
        ))}
      </ul>
      <div>
        <FormAction label="Back" secondary onClick={onBack} />
        <FormAction label="Save" onClick={onSave} />
      </div>
    </section>
  );
};

TaskList.propTypes = {
  onBack: func.isRequired,
  onSave: func.isRequired,
  onAdd: func.isRequired,
  onRemove: func.isRequired,
  tasks: arrayOf(
    shape({
      id: string.isRequired,
      title: string.isRequired
    })
  )
};

export default TaskList;
