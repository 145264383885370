import { func } from 'prop-types';
import React, { useEffect } from 'react';

import { noOp } from '@neslotech/ui-utils';

import { useDevice } from '../../../hook/useDevice';
import { useProgressLoader } from '../../../hook/useProgressLoader';

import { Modal } from '../../../common/layout/portal/Modal';
import { ModalLayout } from '../../../common/layout/portal/ModalLayout';
import CategoryCard from '../category-card/CategoryCard';

import './add-kudos-category.scss';

const AddKudosCategory = ({ category, errors, onClose, onFocus, onSave }) => {
  const { mobile } = useDevice();
  const { setFullScreen } = useProgressLoader();

  useEffect(() => {
    setFullScreen(true);

    return () => setFullScreen(false);
  }, [setFullScreen]);

  if (mobile) {
    return (
      <div className="add-category__modal">
        <span className="add-category__title">Add a new Kudos category</span>
        <CategoryCard
          actionText="Save"
          cancel
          buttonLarge
          focused
          onClose={onClose}
          category={category}
          noPadding
          errors={errors}
          onActionClick={onSave}
          onFocus={onFocus}
        />
      </div>
    );
  }

  return (
    <Modal className="add-category">
      <ModalLayout title="Add a new Kudos category" onClose={onClose} customClass="add-category">
        <CategoryCard
          actionText="Save"
          cancel
          buttonLarge
          focused
          onClose={onClose}
          category={category}
          noPadding
          errors={errors}
          onActionClick={onSave}
          onFocus={onFocus}
        />
      </ModalLayout>
    </Modal>
  );
};

AddKudosCategory.defaultProps = {
  onClose: noOp
};

AddKudosCategory.propTypes = {
  onClose: func
};

export default AddKudosCategory;
