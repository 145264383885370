import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/ui-utils';

import { mapErrors } from '../../../tool/error.util';
import {
  getCreateKudosNominationRequest,
  getLoadCompanyNominationsRequest,
  getLoadLeadersRequest,
  getLoadMostFrequentCategoriesRequest,
  getLoadMostPopularCategoriesRequest,
  getLoadPersonalNominationsRequest,
  getLoadRedeemablePointsRequest,
  getLoadRemainingPointsRequest,
  getRemoveNominationMessageRequest,
  getToggleLike,
  loadActiveCategoriesRequest
} from '../../tool/api/kudos/kudos.endpoint';

import {
  ADD_REALTIME_PERSONAL_NOMINATION,
  CREATE_KUDOS_NOMINATION,
  LOAD_CATEGORIES,
  LOAD_COMPANY_NOMINATIONS,
  LOAD_LEADERS,
  LOAD_MOST_FREQUENT_CATEGORIES,
  LOAD_MOST_POPULAR_CATEGORIES,
  LOAD_PERSONAL_NOMINATIONS,
  LOAD_REDEEMABLE_POINTS,
  LOAD_REMAINING_POINTS,
  REMOVE_NOMINATION_MESSAGE,
  SET_ALL_TIME_POINTS,
  SET_CATEGORIES,
  SET_COMPANY_NOMINATIONS,
  SET_KUDOS_ERRORS,
  SET_LEADERS,
  SET_MOST_FREQUENT_CATEGORIES,
  SET_MOST_POPULAR_CATEGORIES,
  SET_PERSONAL_NOMINATIONS,
  SET_REDEEMABLE_POINTS,
  SET_REMAINING_POINTS,
  TOGGLE_LIKE
} from '../../action/kudos/kudos.action';
import { addSystemNotice } from '../../action/system.action';

export function* performLoadMostFrequentCategories({ userId }) {
  try {
    const [endpoint, requestOptions] = getLoadMostFrequentCategoriesRequest(userId);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_MOST_FREQUENT_CATEGORIES, frequentCategories: data });
  } catch ({ response, config }) {
    yield put(
      addSystemNotice(
        'The kudos points data could not be loaded!',
        SNACK_CRITICAL,
        config?.skipNotice
      )
    );
  }
}

export function* watchForLoadMostFrequentCategories() {
  yield takeLatest(LOAD_MOST_FREQUENT_CATEGORIES, performLoadMostFrequentCategories);
}

export function* performLoadRemainingPoints({ userId, onComplete }) {
  try {
    const [endpoint, requestOptions] = getLoadRemainingPointsRequest(userId);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_REMAINING_POINTS, remainingPoints: data.remainingPoints });
  } catch ({ response, config }) {
    yield put(
      addSystemNotice(
        'The kudos points data could not be loaded!',
        SNACK_CRITICAL,
        config?.skipNotice
      )
    );
  } finally {
    if (onComplete) {
      yield call(onComplete);
    }
  }
}

export function* watchForLoadRemainingPoints() {
  yield takeLatest(LOAD_REMAINING_POINTS, performLoadRemainingPoints);
}

export function* performLoadRedeemablePoints({ userId }) {
  try {
    const [endpoint, requestOptions] = getLoadRedeemablePointsRequest(userId);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_REDEEMABLE_POINTS, redeemablePoints: data.accumulatedPoints });
    // TODO Remove once the redeem points functionality is implemented.
    yield put({ type: SET_ALL_TIME_POINTS, allTimePoints: data.accumulatedPoints });
  } catch ({ response, config }) {
    yield put(
      addSystemNotice(
        'The kudos points data could not be loaded!',
        SNACK_CRITICAL,
        config?.skipNotice
      )
    );
  }
}

export function* watchForLoadRedeemablePoints() {
  yield takeLatest(LOAD_REDEEMABLE_POINTS, performLoadRedeemablePoints);
}

export function* performLoadLeaders({ onComplete }) {
  try {
    const [endpoint, requestOptions] = getLoadLeadersRequest();

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_LEADERS, leaders: data });
  } catch ({ response, config }) {
    yield put(
      addSystemNotice(
        'There was an error while retrieving the leaderboard.',
        SNACK_CRITICAL,
        config?.skipNotice
      )
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadLeaders() {
  yield takeLatest(LOAD_LEADERS, performLoadLeaders);
}

export function* performLoadMostPopularCategories({ onComplete }) {
  try {
    const [endpoint, requestOptions] = getLoadMostPopularCategoriesRequest();

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_MOST_POPULAR_CATEGORIES, popularCategories: data });
  } catch ({ response, config }) {
    yield put(
      addSystemNotice(
        'The kudos popular categories data could not be loaded!',
        SNACK_CRITICAL,
        config?.skipNotice
      )
    );
  } finally {
    if (onComplete) {
      yield call(onComplete);
    }
  }
}

export function* watchForLoadMostPopularCategories() {
  yield takeLatest(LOAD_MOST_POPULAR_CATEGORIES, performLoadMostPopularCategories);
}

export function* performLoadPersonalNominations({ userId, onComplete }) {
  try {
    const [endpoint, requestOptions] = getLoadPersonalNominationsRequest(userId);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_PERSONAL_NOMINATIONS, personalNominations: data });
  } catch ({ response, config }) {
    yield put(
      addSystemNotice(
        'The kudos personal nominations data could not be loaded!',
        SNACK_CRITICAL,
        config?.skipNotice
      )
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadPersonalNominations() {
  yield takeLatest(LOAD_PERSONAL_NOMINATIONS, performLoadPersonalNominations);
}

export function* performLoadCompanyNominations({ onComplete }) {
  try {
    const [endpoint, requestOptions] = getLoadCompanyNominationsRequest();

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_COMPANY_NOMINATIONS, companyNominations: data });
  } catch ({ response, config }) {
    yield put(
      addSystemNotice(
        'There was an error while retrieving the Nominations.',
        SNACK_CRITICAL,
        config?.skipNotice
      )
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadCompanyNominations() {
  yield takeLatest(LOAD_COMPANY_NOMINATIONS, performLoadCompanyNominations);
}

export function* performLoadCategories({ onComplete }) {
  try {
    const [endpoint, requestOptions] = loadActiveCategoriesRequest();

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_CATEGORIES, categories: data });
  } catch ({ response, config }) {
    yield put(
      addSystemNotice(
        'The kudos categories data could not be loaded!',
        SNACK_CRITICAL,
        config?.skipNotice
      )
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadCategories() {
  yield takeLatest(LOAD_CATEGORIES, performLoadCategories);
}

export function* performCreateKudosNomination({ payload, onSuccess, onError }) {
  try {
    const [endpoint, requestOptions] = getCreateKudosNominationRequest(payload);

    const { data } = yield call(axios, endpoint, requestOptions);

    if (onSuccess) {
      yield call(onSuccess);
    }

    yield put({ type: ADD_REALTIME_PERSONAL_NOMINATION, nomination: data });

    yield put(addSystemNotice('Your kudos nomination was created successfully.', SNACK_SUCCESS));
  } catch ({ response, config }) {
    yield put({ type: SET_KUDOS_ERRORS, errors: mapErrors(response?.data) });
    yield put(
      addSystemNotice(
        'Your kudos nomination could not be created.',
        SNACK_CRITICAL,
        config?.skipNotice
      )
    );
    if (onError) {
      yield call(onError);
    }
  }
}

export function* watchForCreateKudosNominationRequest() {
  yield takeLatest(CREATE_KUDOS_NOMINATION, performCreateKudosNomination);
}

export function* performRemoveNominationMessage({ nominationId, user, onComplete }) {
  try {
    const [endpoint, requestOptions] = getRemoveNominationMessageRequest(nominationId);

    yield call(axios, endpoint, requestOptions);

    yield put(
      addSystemNotice(
        `${user.firstName} ${user.lastName}'s message has been removed.`,
        SNACK_SUCCESS
      )
    );
  } catch ({ response, config }) {
    yield put(
      addSystemNotice('System error, please try again.', SNACK_CRITICAL, config?.skipNotice)
    );
  } finally {
    if (onComplete) {
      yield call(onComplete);
    }
  }
}

export function* watchForRemoveNominationMessage() {
  yield takeLatest(REMOVE_NOMINATION_MESSAGE, performRemoveNominationMessage);
}

export function* performToggleLike({ nominationId, likerId, onComplete, onFail }) {
  try {
    const [endpoint, requestOptions] = getToggleLike(nominationId, likerId);

    yield call(axios, endpoint, requestOptions);
  } catch ({ response }) {
    yield call(onFail);
  } finally {
    yield call(onComplete);
  }
}

export function* watchForToggleLike() {
  yield takeLatest(TOGGLE_LIKE, performToggleLike);
}

export default function* kudosSaga() {
  yield all([
    watchForLoadMostFrequentCategories(),
    watchForLoadRemainingPoints(),
    watchForLoadRedeemablePoints(),
    watchForLoadLeaders(),
    watchForLoadMostPopularCategories(),
    watchForLoadCompanyNominations(),
    watchForLoadPersonalNominations(),
    watchForLoadCategories(),
    watchForCreateKudosNominationRequest(),
    watchForRemoveNominationMessage(),
    watchForToggleLike()
  ]);
}
