import { arrayOf, bool, elementType, func, number, shape, string } from 'prop-types';
import React, { useMemo, useState } from 'react';

import { getClassNames } from '@neslotech/ui-utils';

import { Loader } from '../../common/component/loader/Loader';

import { StepperLayout } from '../layout/stepper/StepperLayout';

import './stepper.scss';

export const Stepper = ({ steps, onCancel, onComplete, loading }) => {
  const [stepId, setStepId] = useState(1);

  const handleNext = () => {
    const newStep = stepId + 1;
    const matchingStep = steps.find((step) => step.id === newStep);
    // matching step will be undefined when we are trying to navigate
    // to the next step but there are no more steps available.
    if (!matchingStep) {
      onComplete();
      return;
    }

    setStepId(matchingStep.id);
  };

  const handleBack = () => {
    const newStep = stepId - 1;
    if (newStep < 1) {
      return;
    }
    setStepId(newStep);
  };

  const CurrentStep = useMemo(() => {
    const currentStep = steps.find((step) => step.id === stepId);
    if (!currentStep) {
      return <></>;
    }

    return currentStep.component;
  }, [steps, stepId]);

  return (
    <StepperLayout>
      <section className="stepper">
        <menu className="stepper__menu">
          {steps.map(({ id, enabled, title }) => (
            <li
              key={id}
              className={getClassNames('stepper__menu-item', {
                selected: id === stepId,
                disabled: !enabled
              })}
            >
              <button disabled={!enabled} onClick={() => setStepId(id)}>
                {title}
              </button>
            </li>
          ))}
        </menu>
        <section>
          {loading && <Loader dark />}
          {!loading && <CurrentStep onCancel={onCancel} onNext={handleNext} onBack={handleBack} />}
        </section>
      </section>
    </StepperLayout>
  );
};

Stepper.propTypes = {
  steps: arrayOf(
    shape({
      id: number.isRequired,
      title: string.isRequired,
      component: elementType.isRequired,
      enabled: bool.isRequired
    })
  ).isRequired,
  onComplete: func.isRequired,
  onCancel: func.isRequired,
  loading: bool
};
