import { arrayOf, bool, func, shape, string } from 'prop-types';
import React, { useMemo, useState } from 'react';

import { isValuePresent } from '@neslotech/ui-utils';

import { LEAVE_ALLOCATION_TYPE } from '../../../../../tool/prop-types';

import { FormAction } from '../../../../../common/component/form/form-action/FormAction';

import { Datepicker } from '../../../../../common/component/datepicker/Datepicker';
import { Form } from '../../../../../common/component/form/Form';
import { FormRow } from '../../../../../common/component/form/form-row/FormRow';
import { Input } from '../../../../../common/component/input/Input';
import { SearchOptions } from '../../../../../common/component/input/search/options/SearchOptions';
import { TextArea } from '../../../../../common/component/textarea/TextArea';

import { ReactComponent as ChevronIcon } from '../../../../../icon/chevron-icon.svg';

import './leave-allocation-form.scss';

const REQUIRED_FIELDS = Object.freeze(['hours', 'startDate', 'leaveCategoryId']);

const DEFAULT_FORM_STATE = {
  hours: 8,
  startDate: new Date(),
  description: '',
  leaveCategoryId: '',
  endDate: ''
};

export const LeaveAllocationForm = ({
  onSubmit,
  onClose,
  categories,
  isAdmin,
  users,
  loggedInUserId,
  allocation = {},
  isEditing = false,
  onRemove
}) => {
  const leaveAllocationOptions = categories.map((category) => ({
    value: category.id,
    label: category.name
  }));

  const usersOptions = users.map((user) => ({
    value: user.id,
    label: `${user.firstName} ${user.lastName}`
  }));

  const initialFormState = {
    hours: allocation.hours || DEFAULT_FORM_STATE.hours,
    startDate: allocation.startDate ? new Date(allocation.startDate) : DEFAULT_FORM_STATE.startDate,
    endDate: allocation.endDate ? new Date(allocation.endDate) : DEFAULT_FORM_STATE.endDate,
    description: allocation.description || DEFAULT_FORM_STATE.description,
    leaveCategoryId: allocation.leaveCategoryId || DEFAULT_FORM_STATE.leaveCategoryId
  };

  const [form, setForm] = useState(initialFormState);

  const isSubmitDisabled = useMemo(
    () => REQUIRED_FIELDS.some((field) => !isValuePresent(form[field])),
    [form]
  );

  const [selectedUserId, setSelectedUserId] = useState(allocation.assignee || loggedInUserId);

  const handleInputChange = (newState) => setForm({ ...form, ...newState });
  const handleUserChange = (user) => setSelectedUserId(user.userId);

  const handleModalClose = (e) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <section className="leave-allocation-form">
      <Form>
        <div className="leave-allocation-form__time-wrapper">
          <FormRow>
            <div className="leave-allocation-form__time-inputs">
              <Input name="hours" value={form.hours} onChange={handleInputChange} required />
              <span>Hours/Day</span>
            </div>
            <Datepicker
              label="Start Date"
              name="startDate"
              minDate={new Date(2021, 0)}
              maxDate={form.endDate}
              yearDropdownItemNumber={100}
              value={form.startDate}
              onChange={handleInputChange}
              required
            />
            <Datepicker
              label="End Date"
              name="endDate"
              minDate={form.startDate ?? new Date(1900, 0)}
              yearDropdownItemNumber={100}
              value={form.endDate}
              onChange={handleInputChange}
            />
          </FormRow>
          <div className="leave-allocation-form__time-actions">
            <span>Doesn’t Repeat</span>
            <ChevronIcon />
          </div>
        </div>
        <FormRow fullWidth>
          <SearchOptions
            label="Time Off"
            secondaryLabel="Time Off"
            name="leaveCategoryId"
            options={leaveAllocationOptions}
            value={form.leaveCategoryId}
            onChange={handleInputChange}
            buttonAlignEnd
            required
          />
        </FormRow>
        <FormRow>
          <TextArea
            label="Additional Information"
            name="description"
            value={form.description}
            onChange={handleInputChange}
          />
        </FormRow>
        {isAdmin && (
          <FormRow fullWidth>
            <SearchOptions
              label="Assigned To"
              secondaryLabel="Assigned To"
              name="userId"
              options={usersOptions}
              value={selectedUserId}
              onChange={handleUserChange}
              buttonAlignEnd
            />
          </FormRow>
        )}
      </Form>
      <div className="leave-allocation-form__actions">
        <FormAction
          label={isEditing ? 'Update' : 'Create'}
          disabled={isSubmitDisabled}
          onClick={() => onSubmit(form, selectedUserId)}
        />
        <FormAction label="Cancel" secondary onClick={(e) => handleModalClose(e)} />
        {isEditing && <button onClick={onRemove}>Delete</button>}
      </div>
    </section>
  );
};

LeaveAllocationForm.propTypes = {
  onSubmit: func.isRequired,
  onClose: func.isRequired,
  isAdmin: bool.isRequired,
  users: arrayOf(
    shape({
      firstName: string,
      lastName: string,
      id: string
    })
  ).isRequired,
  categories: arrayOf(
    shape({
      name: string,
      id: string
    })
  ).isRequired,
  loggedInUserId: string.isRequired,
  allocation: LEAVE_ALLOCATION_TYPE,
  isEditing: bool,
  onRemove: func.isRequired
};
