import { arrayOf, element, func, oneOf, oneOfType, shape, string } from 'prop-types';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { getClassNames } from '@neslotech/ui-utils';

import { ROLES } from '../../../../tool/constant';

import { ReactComponent as CalendarPlusIcon } from '../../../../icon/calendar-plus-icon.svg';
import { ReactComponent as CalendarIcon } from '../../../../icon/calendar-solid-icon.svg';
import { ReactComponent as HeartbeatIcon } from '../../../../icon/heartbeat-solid.svg';
import { ReactComponent as HourglassIcon } from '../../../../icon/hourglass-half-solid-icon.svg';
import { ReactComponent as UsersIcon } from '../../../../icon/users-icon.svg';

import './scheduling-settings-menu.scss';

const settingsItems = [
  {
    icon: <CalendarIcon />,
    path: '/scheduling/settings/timesheets',
    allowedRoles: [ROLES.ADMIN],
    name: 'Timesheet Management'
  },
  {
    icon: <UsersIcon />,
    path: '/scheduling/settings/access',
    allowedRoles: [ROLES.ADMIN],
    name: 'Manage Team'
  },
  {
    icon: <CalendarPlusIcon />,
    path: '/scheduling/settings/leave-categories',
    allowedRoles: [ROLES.ADMIN],
    name: 'Time Off'
  },
  {
    icon: <HeartbeatIcon />,
    path: '/scheduling/dashboard',
    allowedRoles: [ROLES.ADMIN],
    name: 'Status'
  },
  {
    icon: <HourglassIcon />,
    path: '/scheduling/dashboard',
    allowedRoles: [ROLES.ADMIN],
    name: 'Hours and Currency'
  }
];

const SettingItems = ({ menuItems, role }) => {
  const location = useLocation();

  if (!menuItems) {
    return null;
  }

  return (
    <nav>
      {menuItems
        .filter((item) => item.allowedRoles.includes(role))
        .map(({ path, icon, name }) => (
          <Link
            key={name}
            to={path}
            className={getClassNames('nav-menu-item', {
              active: location.pathname === path
            })}
          >
            <div>{icon}</div>
            <span>{name}</span>
          </Link>
        ))}
    </nav>
  );
};

SettingItems.propTypes = {
  menuItems: arrayOf(
    shape({
      icon: oneOfType([element, func]).isRequired,
      path: string.isRequired,
      allowedRoles: arrayOf(string).isRequired,
      name: string.isRequired
    })
  ).isRequired,
  role: string.isRequired
};

export const SchedulingSettingsMenu = ({ role }) => (
  <aside className="scheduling-settings-menu">
    <div>
      <header>
        <h3>Settings</h3>
        <span>{role}</span>
      </header>
      <SettingItems menuItems={settingsItems} role={role} />
    </div>
  </aside>
);

SchedulingSettingsMenu.propTypes = {
  role: oneOf([ROLES.ADMIN, ROLES.EMPLOYEE]).isRequired
};
