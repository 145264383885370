import { bool, func } from 'prop-types';
import React, { useState } from 'react';

import { getClassNames } from '@neslotech/ui-utils';

import './checkbox.scss';

const Checkbox = ({ checked: initialChecked = false, indeterminate = false, onChange }) => {
  const [checked, setChecked] = useState(initialChecked);

  const handleChange = (event) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
    onChange(isChecked);
  };

  return (
    <input
      className={getClassNames('checkbox', { indeterminate })}
      type="checkbox"
      checked={checked}
      onChange={handleChange}
    />
  );
};
Checkbox.propTypes = {
  checked: bool,
  indeterminate: bool,
  onChange: func.isRequired
};

export default Checkbox;
