import { arrayOf, func, instanceOf, node, oneOfType, shape, string } from 'prop-types';
import React from 'react';

import { noOp } from '@neslotech/ui-utils';

export const FileUpload = ({ fileTypes, fileRef, children, onChange }) => {
  const onFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      return;
    }

    onChange(selectedFile);
  };

  return (
    <div className="file-upload">
      {children}
      <input accept={fileTypes} type="file" hidden ref={fileRef} onChange={onFileChange} />
    </div>
  );
};

FileUpload.defaultProps = {
  onChange: noOp,
  fileTypes: 'image/*'
};

FileUpload.propTypes = {
  onChange: func,
  children: oneOfType([arrayOf(node), node]),
  fileTypes: oneOfType([arrayOf(string), string]),
  fileRef: oneOfType([
    func,
    shape({
      current: instanceOf(Element)
    })
  ])
};
