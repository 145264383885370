import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  archiveClient,
  loadAllClients,
  reinstateClient
} from '../../../state/action/scheduling/client.action';

import { useProgressLoader } from '../../../hook/useProgressLoader';

import { ClientList } from '../../../component/scheduling/client/ClientList';

const ClientListContainer = () => {
  const dispatch = useDispatch();
  const { setBusyState } = useProgressLoader();

  const clients = useSelector(({ client_store }) =>
    client_store.clients.filter((client) => client.name !== 'Internal')
  );
  const stateLoading = useSelector(({ client_store }) => client_store.loading);

  const [loading, setLoading] = useState(stateLoading ?? true);

  useEffect(() => {
    dispatch(loadAllClients(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleArchive = (clientId, onComplete) => {
    setBusyState(true);
    dispatch(
      archiveClient(clientId, () => {
        onComplete();
        setBusyState(false);
      })
    );
  };

  const handleReinstate = (clientId) => {
    setBusyState(true);
    dispatch(reinstateClient(clientId, () => setBusyState(false)));
  };

  return (
    <ClientList
      clients={clients}
      loading={loading}
      onArchive={handleArchive}
      onReinstate={handleReinstate}
    />
  );
};

export default ClientListContainer;
