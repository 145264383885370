/**
 * @module SnackAlert Component
 * @description
 * A single message that enters in from the edge of the screen.
 * Allows triggering of an exit animation before removing the SnackAlert.
 */
import { bool, string } from 'prop-types';
import React, { useEffect, useState } from 'react';

import {
  SNACK_CRITICAL,
  SNACK_INFO,
  SNACK_SUCCESS,
  SNACK_WARNING,
  getClassNames
} from '@neslotech/ui-utils';

import { ReactComponent as CheckIcon } from '../../../icon/check-icon.svg';
import { ReactComponent as ExclamationIcon } from '../../../icon/exclamation-icon.svg';
import { ReactComponent as InfoIcon } from '../../../icon/info-icon.svg';

import './snack-alert.scss';

const renderIcon = (status) => {
  switch (status) {
    case SNACK_SUCCESS:
      return <CheckIcon />;
    case SNACK_CRITICAL:
      return <ExclamationIcon />;
    case SNACK_INFO:
    case SNACK_WARNING:
      return <InfoIcon />;
    default:
      throw Error(`Snack Alert type is not recognized: ${status}`);
  }
};

/** On mount, add an `entering` modifier, to trigger the enter animation. */
export const SnackAlert = ({ status, title, closing }) => {
  const [entering, setEntering] = useState(true);

  // On initial load, remove `entering` to remove the enter animation. Clear timeout on cleanup
  useEffect(() => {
    const timeout = setTimeout(() => setEntering(false), 200);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <section className={`${getClassNames('snack', { entering, closing })} ${status}`}>
      <div className="snack__title">
        <span>{title}</span>
      </div>
      <div className="snack__icon">{renderIcon(status)}</div>
    </section>
  );
};

SnackAlert.defaultProps = {
  status: SNACK_SUCCESS,
  closing: false
};

SnackAlert.propTypes = {
  status: string,
  title: string.isRequired,
  closing: bool
};
