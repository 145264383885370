import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';

import { ApiRequest, HttpVerb, SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/ui-utils';

import { getAuthHeaders } from '../../../../tool/auth.util';
import { SCHEDULING_ENDPOINT } from '../../../tool/api/scheduling/scheduling-permissions.endpoint';

import {
  APPROVE_TIMESHEET,
  CREATE_TIMESHEET,
  EXPORT_TIMESHEET,
  LOAD_ALL_TIMESHEETS,
  LOAD_TIMESHEET,
  LOAD_USER_TIMESHEET,
  REJECT_TIMESHEET,
  SET_TIMESHEET,
  SET_TIMESHEETS,
  SET_USER_TIMESHEET
} from '../../../action/scheduling/timesheet/timesheet.action';
import { addSystemNotice } from '../../../action/system.action';

export function* performCreateTimesheet({ userId, onSuccess, onComplete }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      `${SCHEDULING_ENDPOINT}/users/${userId}/timesheets`,
      HttpVerb.POST,
      getAuthHeaders(),
      {}
    );

    yield call(axios, endpoint, axiosOptions);

    yield put(addSystemNotice('Timesheet submitted successfully', SNACK_SUCCESS));

    yield call(onSuccess);
  } catch ({ response }) {
    yield put(
      addSystemNotice(
        'There was an error submitting your timesheet. Please try again',
        SNACK_CRITICAL
      )
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForCreateTimesheet() {
  yield takeLatest(CREATE_TIMESHEET, performCreateTimesheet);
}

export function* performLoadAllTimesheets({ onComplete }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      `${SCHEDULING_ENDPOINT}/timesheets`,
      HttpVerb.GET,
      getAuthHeaders()
    );

    const { data } = yield call(axios, endpoint, axiosOptions);

    yield put({ type: SET_TIMESHEETS, timesheets: data });
    yield call(onComplete);
  } catch ({ response, config }) {
    yield put(addSystemNotice('Failed to load timesheets', SNACK_CRITICAL, config?.skipNotice));

    yield call(onComplete);
  }
}

export function* watchForLoadAllTimesheets() {
  yield takeLatest(LOAD_ALL_TIMESHEETS, performLoadAllTimesheets);
}

export function* performLoadUserTimesheet({ userId, onSuccess, onError }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      `${SCHEDULING_ENDPOINT}/users/${userId}/timesheets`,
      HttpVerb.GET,
      getAuthHeaders()
    );

    const { data } = yield call(axios, endpoint, axiosOptions);

    yield put({ type: SET_USER_TIMESHEET, userTimesheet: data });

    yield call(onSuccess);
  } catch ({ response, config }) {
    yield put(addSystemNotice('Failed to load user timesheet', SNACK_CRITICAL, config?.skipNotice));
    yield call(onError);
  }
}

export function* watchForLoadUserTimesheet() {
  yield takeLatest(LOAD_USER_TIMESHEET, performLoadUserTimesheet);
}

export function* performApproveTimesheet({ id, onSuccess, onComplete }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      `${SCHEDULING_ENDPOINT}/timesheets/${id}/approve`,
      HttpVerb.PUT,
      getAuthHeaders(),
      {}
    );

    yield call(axios, endpoint, axiosOptions);

    yield call(onSuccess);
  } catch ({ response, config }) {
    yield put(
      addSystemNotice(
        'Error approving time sheet. Please try again',
        SNACK_CRITICAL,
        config?.skipNotice
      )
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForApproveTimesheet() {
  yield takeLatest(APPROVE_TIMESHEET, performApproveTimesheet);
}

export function* performRejectTimesheet({ id, payload, onSuccess, onComplete }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      `${SCHEDULING_ENDPOINT}/timesheets/${id}`,
      HttpVerb.DELETE,
      getAuthHeaders(),
      payload
    );

    yield call(axios, endpoint, axiosOptions);

    yield put(addSystemNotice('Timesheet rejected successfully', SNACK_SUCCESS));

    yield call(onSuccess);
  } catch ({ response, config }) {
    yield put(
      addSystemNotice(
        'Error rejecting time sheet. Please try again',
        SNACK_CRITICAL,
        config?.skipNotice
      )
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForRejectTimesheet() {
  yield takeLatest(REJECT_TIMESHEET, performRejectTimesheet);
}

export function* performExportTimesheet({ payload, onSuccess, onComplete }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      `${SCHEDULING_ENDPOINT}/timesheets/download`,
      HttpVerb.POST,
      getAuthHeaders(),
      payload
    );

    const { data, headers } = yield call(axios, endpoint, axiosOptions);

    const blob = new Blob([data], { type: 'text/csv' });

    const contentDisposition = headers['content-disposition'];
    const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = regex.exec(contentDisposition);
    const filename = matches?.[1]?.replace(/['"]/g, '') || 'unknown';

    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();

    URL.revokeObjectURL(url);

    yield call(onSuccess);
  } catch ({ response, config }) {
    yield put(
      addSystemNotice(
        'Error exporting timesheets. Please try again',
        SNACK_CRITICAL,
        config?.skipNotice
      )
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForExportTimesheet() {
  yield takeLatest(EXPORT_TIMESHEET, performExportTimesheet);
}

export function* performLoadTimesheet({ timesheetId, onComplete }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      `${SCHEDULING_ENDPOINT}/timesheets/${timesheetId}`,
      HttpVerb.GET,
      getAuthHeaders()
    );

    const { data } = yield call(axios, endpoint, axiosOptions);

    yield put({ type: SET_TIMESHEET, timesheet: data });

    yield call(onComplete);
  } catch ({ response, config }) {
    yield put(
      addSystemNotice('Something went wrong, please try again.', SNACK_CRITICAL, config?.skipNotice)
    );

    yield call(onComplete);
  }
}

export function* watchForLoadTimesheet() {
  yield takeLatest(LOAD_TIMESHEET, performLoadTimesheet);
}

export default function* timesheetSaga() {
  yield all([
    watchForCreateTimesheet(),
    watchForLoadAllTimesheets(),
    watchForLoadUserTimesheet(),
    watchForApproveTimesheet(),
    watchForLoadTimesheet(),
    watchForRejectTimesheet(),
    watchForExportTimesheet()
  ]);
}
