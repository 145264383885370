import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';

import { ApiRequest, HttpVerb, SNACK_CRITICAL, SNACK_SUCCESS, isEmpty } from '@neslotech/ui-utils';

import { getAuthHeaders } from '../../../tool/auth.util';
import { RESPONSE_STATUS } from '../../../tool/constant';
import {
  SCHEDULING_ENDPOINT,
  getLoadAllSchedulingPermissionsRequest,
  getSaveSchedulingPermissionsRequest
} from '../../tool/api/scheduling/scheduling-permissions.endpoint';

import {
  LOAD_ALL_SCHEDULING_PERMISSIONS,
  LOAD_MY_SCHEDULING_PERMISSION,
  LOAD_USER_SCHEDULING_PERMISSION,
  SAVE_SCHEDULING_PERMISSIONS,
  SET_ALL_SCHEDULING_PERMISSIONS,
  SET_USER_SCHEDULING_PERMISSION
} from '../../action/scheduling/scheduling-permissions.action';
import { addSystemNotice } from '../../action/system.action';

export function* performSaveSchedulingPermissions({ payload, onSuccess, onError }) {
  try {
    const [endpoint, requestOptions] = getSaveSchedulingPermissionsRequest(payload);

    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('Team members have been updated successfully', SNACK_SUCCESS));

    yield call(onSuccess);
  } catch ({ response }) {
    yield put(
      addSystemNotice(
        'There was an error while selecting user access for scheduling',
        SNACK_CRITICAL
      )
    );
    yield call(onError);
  }
}

export function* watchForSaveSchedulingPermissionsRequest() {
  yield takeLatest(SAVE_SCHEDULING_PERMISSIONS, performSaveSchedulingPermissions);
}

export function* performLoadAllSchedulingPermissions({ navigate, onComplete }) {
  try {
    const [endpoint, requestOptions] = getLoadAllSchedulingPermissionsRequest();

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_ALL_SCHEDULING_PERMISSIONS, permissions: data });

    if (isEmpty(data)) {
      yield put(
        addSystemNotice('Permissions for scheduling have not been set up yet', SNACK_CRITICAL)
      );
      yield call(navigate, '/scheduling/setup/access');
    }

    yield call(onComplete);
  } catch ({ response, config }) {
    if (response?.status === RESPONSE_STATUS.NOT_FOUND) {
      yield put(
        addSystemNotice('Permissions for scheduling have not been set up yet', SNACK_CRITICAL)
      );
      yield call(navigate, '/scheduling/setup/access', { replace: true });
      return;
    }

    yield put(
      addSystemNotice(
        'There was an error while retrieving scheduling permissions',
        SNACK_CRITICAL,
        config?.skipNotice
      )
    );

    yield call(navigate, '/dashboard');

    yield call(onComplete);
  }
}

export function* watchForLoadSchedulingPermissionsRequest() {
  yield takeLatest(LOAD_ALL_SCHEDULING_PERMISSIONS, performLoadAllSchedulingPermissions);
}

export function* performLoadUserSchedulingPermission({ userId, navigate }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      `${SCHEDULING_ENDPOINT}/users/${userId}/permissions`,
      HttpVerb.GET,
      getAuthHeaders()
    );

    yield call(axios, endpoint, axiosOptions);

    yield put({ type: SET_USER_SCHEDULING_PERMISSION });
  } catch ({ response, config }) {
    if (response?.status === RESPONSE_STATUS.FORBIDDEN) {
      yield put(
        addSystemNotice('You do not have access to scheduling', SNACK_CRITICAL, config?.skipNotice)
      );
    } else {
      yield put(
        addSystemNotice(
          'Error while loading your scheduling permission, please contact an admin',
          SNACK_CRITICAL,
          config?.skipNotice
        )
      );
    }

    yield call(navigate, '/dashboard');
  }
}

export function* watchForLoadSchedulingUserPermissionRequest() {
  yield takeLatest(LOAD_USER_SCHEDULING_PERMISSION, performLoadUserSchedulingPermission);
}

export function* performLoadMySchedulingPermission({ userId }) {
  const { endpoint, axiosOptions } = new ApiRequest(
    `${SCHEDULING_ENDPOINT}/users/${userId}/permissions`,
    HttpVerb.GET,
    getAuthHeaders()
  );

  try {
    axiosOptions.skipForbidden = true;

    yield call(axios, endpoint, axiosOptions);

    yield put({ type: SET_USER_SCHEDULING_PERMISSION });
  } catch (_) {}
}

export function* watchForLoadSchedulingMyPermissionRequest() {
  yield takeLatest(LOAD_MY_SCHEDULING_PERMISSION, performLoadMySchedulingPermission);
}

export default function* schedulingPermissionsSaga() {
  yield all([
    watchForSaveSchedulingPermissionsRequest(),
    watchForLoadSchedulingPermissionsRequest(),
    watchForLoadSchedulingUserPermissionRequest(),
    watchForLoadSchedulingMyPermissionRequest()
  ]);
}
