import { arrayOf, bool, node, oneOfType } from 'prop-types';
import React from 'react';

import { getClassNames } from '@neslotech/ui-utils';

import './form-row.scss';

export const FormRow = ({ children, vertical = false, fullWidth = false, noMargin = false }) => (
  <div
    className={getClassNames('form-row', {
      vertical,
      'full-width': fullWidth,
      'no-margin': noMargin
    })}
  >
    {children}
  </div>
);

FormRow.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  vertical: bool,
  noMargin: bool,
  fullWidth: bool
};
