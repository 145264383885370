import { arrayOf, bool, func, number, object, oneOfType, shape, string } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { getClassNames, isEmpty, noOp } from '@neslotech/ui-utils';

import { Dropdown } from '../../../common/component/dropdown/Dropdown';
import { SearchInput } from '../../../common/component/input/search/SearchInput';
import { RadioSelector } from '../../../common/component/radio-selector/RadioSelector';

import { ReactComponent as ChevronIcon } from '../../../icon/chevron-icon.svg';
import { ReactComponent as PlusIcon } from '../../../icon/plus-icon.svg';

import './search-options-with-add.scss';

export const SearchOptionsWithAdd = ({
  name,
  options,
  label,
  secondaryLabel,
  value,
  error,
  required,
  disabled,
  onChange,
  onAdd
}) => {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isAdding, setIsAdding] = useState(false);

  const memoizedValue = useMemo(() => {
    const option = options.find((option) => option.value === value);
    return option ? option.label : searchValue;
  }, [options, value, searchValue]);

  const handleChange = useCallback(
    (val) => {
      const option = options.find((option) => option.value === val);
      onChange({ [name]: option ? val : searchValue });
      setSearchValue(option ? option.label : val);
      setOpen(false);
    },
    [name, options, onChange, searchValue]
  );

  const memoizedOptions = useMemo(() => {
    const filteredOptions = options
      .filter((option) =>
        isEmpty(searchValue) ? true : option.label.toLowerCase().includes(searchValue.toLowerCase())
      )
      .map(({ label, value: optionValue }) => ({
        text: (
          <RadioSelector
            label={label}
            checked={optionValue === value}
            onChange={() => handleChange(optionValue)}
          />
        ),
        onClick: () => handleChange(optionValue)
      }));

    if (isEmpty(filteredOptions) && !isEmpty(searchValue)) {
      filteredOptions.push({
        text: (
          <span className="search-options-with-add__no-results">
            <PlusIcon /> {searchValue}
          </span>
        ),
        onClick: () => {
          handleChange(searchValue);
          if (onAdd) {
            onAdd(searchValue, () => setIsAdding(true));
          }
        }
      });
    }

    return filteredOptions;
  }, [options, value, searchValue, handleChange, onAdd]);

  useEffect(() => {
    if (isAdding && !isEmpty(options)) {
      /* This code ensures that whenever a new option is successfully added, it automatically becomes the default selection.
        After the onAdd action completes successfully, the new option is added to the top of the options list via the Pusher library,
        and the useEffect hook sets it as the selected item. */
      handleChange(options[0].value);
      setIsAdding(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, isAdding]);

  return (
    <div className={getClassNames('search-options-with-add', { open })}>
      <Dropdown
        expandable={disabled}
        menuItems={memoizedOptions}
        placement="bottom-start"
        sameWidth
      >
        <SearchInput
          id={name}
          name={name}
          label={label}
          secondaryLabel={secondaryLabel}
          value={memoizedValue}
          error={error}
          onFocus={() => setOpen(true)}
          action={<ChevronIcon />}
          onActionClick={() => setOpen(!open)}
          onChange={onChange}
          onSearchChange={(val) => setSearchValue(val)}
          searchValue={searchValue}
          required={required}
        />
      </Dropdown>
    </div>
  );
};

SearchOptionsWithAdd.defaultProps = {
  label: '',
  secondaryLabel: '',
  value: '',
  error: null,
  required: false,
  disabled: false,
  onChange: noOp,
  onAdd: noOp
};

SearchOptionsWithAdd.propTypes = {
  name: string.isRequired,
  options: arrayOf(
    shape({
      label: string,
      value: oneOfType([number, string, object])
    })
  ).isRequired,
  label: string,
  secondaryLabel: string,
  value: oneOfType([number, string]),
  error: string,
  required: bool,
  disabled: bool,
  onChange: func,
  onAdd: func
};
