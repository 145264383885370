import { arrayOf, bool, func, node, oneOf, string } from 'prop-types';
import React from 'react';

import { noOp } from '@neslotech/ui-utils';

import './form-action.scss';

import { Button } from '../../button/Button.jsx';

export const FormAction = ({
  label,
  onClick,
  large,
  small,
  icon,
  disabled,
  loading,
  secondary
}) => (
  <div className="form-action">
    <Button
      loading={loading}
      type="submit"
      large={large}
      small={small}
      icon={icon}
      disabled={disabled}
      label={label}
      onClick={onClick}
      secondary={secondary}
    />
  </div>
);

FormAction.defaultProps = {
  onClick: noOp,
  large: false,
  small: false,
  disabled: false,
  loading: false,
  secondary: false
};

FormAction.propTypes = {
  label: string.isRequired,
  onClick: func,
  large: bool,
  small: bool,
  icon: oneOf([arrayOf(node), node]),
  disabled: bool,
  loading: bool,
  secondary: bool
};
