import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';

import { SNACK_CRITICAL } from '@neslotech/ui-utils';

import { removeAuthTokenCookie, setAuthTokenCookie } from '../../tool/auth.util';
import { getTokenVerificationRequest } from '../tool/api/auth.endpoints';

import { LOGOUT, VERIFY_TOKEN } from '../action/auth.action';
import { SET_CONTEXT_USER } from '../action/profile.action';
import { addSystemNotice } from '../action/system.action';

export function* performTokenVerification({ payload, navigate, onComplete, isLoggingIn }) {
  try {
    yield call(setAuthTokenCookie, payload.token);

    // get endpoint and http request options
    const [endpoint, requestOptions] = getTokenVerificationRequest();

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_CONTEXT_USER, contextUser: data });

    if (!isLoggingIn) {
      return;
    }

    // Navigate to dashboard page if user is active, complete profile page if user is not active.
    if (data.active) {
      yield call(navigate, '/dashboard');
    } else {
      yield call(navigate, '/complete-profile');
    }
  } catch ({ response, config }) {
    yield put(
      addSystemNotice('Your account could not be verified.', SNACK_CRITICAL, config?.skipNotice)
    );
  } finally {
    if (onComplete) {
      yield call(onComplete);
    }
  }
}

export function* watchForVerifyTokenRequest() {
  yield takeLatest(VERIFY_TOKEN, performTokenVerification);
}

export function* performLogout({ navigate }) {
  yield call(removeAuthTokenCookie);
  yield call(navigate, '/');
}

export function* watchForLogoutRequest() {
  yield takeLatest(LOGOUT, performLogout);
}

export default function* authSaga() {
  yield all([watchForVerifyTokenRequest(), watchForLogoutRequest()]);
}
