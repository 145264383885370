import { func } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TASK_CREATED, TASK_REMOVED } from '../../../../tool/pusher.helper';

import {
  addRealtimeTask,
  createTask,
  removeRealtimeTask,
  removeTask
} from '../../../../state/action/scheduling/task.action';

import { ChannelProvider } from '../../../../provider/Channel.provider';

import { useChannel } from '../../../../hook/useChannel';
import { useProgressLoader } from '../../../../hook/useProgressLoader';

import TaskList from '../../../../component/scheduling/project/task/TaskList';

const TaskListWithChannel = ({ tasks, onBack, onSave, onAdd, onRemove }) => {
  const dispatch = useDispatch();

  const { bindToEvent } = useChannel();

  useEffect(() => {
    bindToEvent(TASK_CREATED, (data) => dispatch(addRealtimeTask(data)));
    bindToEvent(TASK_REMOVED, (data) => dispatch(removeRealtimeTask(data)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TaskList tasks={tasks} onBack={onBack} onSave={onSave} onAdd={onAdd} onRemove={onRemove} />
  );
};

export const TaskListContainer = ({ onBack, onNext }) => {
  const dispatch = useDispatch();

  const { setBusyState } = useProgressLoader();

  const project = useSelector(({ project_store }) => project_store.project);
  const tasks = useSelector(({ task_store }) => task_store.tasks);

  const handleAddTask = (data, callback) => {
    setBusyState(true);
    dispatch(
      createTask(
        project.id,
        data,
        () => {
          setBusyState(false);
          callback();
        },
        () => setBusyState(false)
      )
    );
  };

  const handleRemoveTask = (taskId) => {
    setBusyState(true);
    dispatch(removeTask(project.id, taskId, () => setBusyState(false)));
  };

  const handleNext = () => {
    onNext();
  };

  return (
    <ChannelProvider channelName={project.id}>
      <TaskListWithChannel
        tasks={tasks}
        onBack={onBack}
        onSave={handleNext}
        onAdd={handleAddTask}
        onRemove={handleRemoveTask}
      />
    </ChannelProvider>
  );
};

TaskListContainer.propTypes = {
  onBack: func.isRequired,
  onNext: func.isRequired
};
