import { bool, func, shape, string } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { getClassNames, noOp } from '@neslotech/ui-utils';

import { ROLES } from '../../../tool/constant';

import { loadMySchedulingPermission } from '../../../state/action/scheduling/scheduling-permissions.action';

import { useDevice } from '../../../hook/useDevice';

import './nav-menu.scss';

const MENU_ITEMS = [
  {
    text: 'Dashboard',
    path: '/dashboard',
    allowedRoles: [ROLES.ADMIN, ROLES.EMPLOYEE]
  },
  {
    text: 'Employee Directory',
    path: '/employees/directory',
    allowedRoles: [ROLES.ADMIN, ROLES.EMPLOYEE]
  },
  {
    text: 'User Management',
    path: '/user-management',
    allowedRoles: [ROLES.ADMIN]
  },
  {
    text: 'Kudos',
    path: '/kudos',
    allowedRoles: [ROLES.ADMIN, ROLES.EMPLOYEE]
  },
  {
    text: 'Kudos Management',
    path: '/kudos-management',
    allowedRoles: [ROLES.ADMIN]
  },
  {
    text: 'Scheduling Management',
    path: '/scheduling/dashboard',
    allowedRoles: [ROLES.ADMIN, ROLES.EMPLOYEE]
  },
  {
    text: 'Notifications',
    path: '/notifications',
    allowedRoles: [ROLES.ADMIN, ROLES.EMPLOYEE]
  }
];

const NavMenuItems = ({ menuItems, role, loggedInUserId }) => {
  const { mobile } = useDevice();
  const dispatch = useDispatch();
  const location = useLocation();

  const userSchedulingPermissionLoaded = useSelector(
    ({ scheduling_permissions_store }) => scheduling_permissions_store.userLoaded
  );

  useEffect(() => {
    if (!userSchedulingPermissionLoaded && !location.pathname.includes('/scheduling')) {
      dispatch(loadMySchedulingPermission(loggedInUserId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSchedulingPermissionLoaded]);

  if (!menuItems) {
    return null;
  }

  return (
    <ul>
      {menuItems
        .filter((item) => item.allowedRoles.includes(role))
        .filter((item) => {
          if (item.text !== 'Scheduling Management') {
            return true;
          }
          return role === ROLES.ADMIN || userSchedulingPermissionLoaded;
        })
        .filter((item) => mobile || item.text !== 'Notifications')
        .map(({ allowedRoles, path, text }, index) => (
          <li key={index}>
            <Link to={path}>{text}</Link>
          </li>
        ))}
    </ul>
  );
};

export const NavMenu = ({ open = false, onClose = noOp, loggedInUser = {} }) => (
  <>
    <nav className={getClassNames('nav-menu', { open })}>
      <div className="nav-menu__close-action">
        <button onClick={onClose}>Close</button>
      </div>
      <NavMenuItems
        menuItems={MENU_ITEMS}
        role={loggedInUser?.role}
        loggedInUserId={loggedInUser?.id}
      />
    </nav>
    <div className={getClassNames('nav-menu__overlay', { open })} />
  </>
);

NavMenu.propTypes = {
  loggedInUser: shape({
    role: string,
    id: string
  }),
  open: bool,
  onClose: func
};
