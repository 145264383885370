import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';

import { SNACK_CRITICAL } from '@neslotech/ui-utils';

import { mapErrors } from '../../../tool/error.util';
import {
  getCreateKudosCommentReplyRequest,
  getCreateKudosNominationCommentRequest,
  getLoadNominationCommentRepliesRequest,
  getLoadNominationCommentsRequest,
  getRemoveNominationCommentRequest,
  getUpdateNominationCommentRequest
} from '../../tool/api/kudos/kudos.endpoint';

import {
  CREATE_KUDOS_COMMENT_REPLY,
  CREATE_KUDOS_NOMINATION_COMMENT,
  LOAD_NOMINATION_COMMENTS,
  LOAD_NOMINATION_COMMENT_REPLIES,
  REMOVE_NOMINATION_COMMENT,
  SET_NOMINATION_COMMENTS,
  SET_NOMINATION_COMMENT_REPLIES,
  UPDATE_NOMINATION_COMMENT
} from '../../action/kudos/comment.action';
import { SET_KUDOS_ERRORS } from '../../action/kudos/kudos.action';
import { addSystemNotice } from '../../action/system.action';

export function* performCreateKudosNominationComment({
  nominationId,
  payload,
  onSuccess,
  onError
}) {
  try {
    const [endpoint, requestOptions] = getCreateKudosNominationCommentRequest(
      nominationId,
      payload
    );

    yield call(axios, endpoint, requestOptions);

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch ({ response, config }) {
    yield put({ type: SET_KUDOS_ERRORS, errors: mapErrors(response?.data) });

    yield put(
      addSystemNotice(
        // eslint-disable-next-line
        "Your comment couldn't be created, please try again.",
        SNACK_CRITICAL,
        config?.skipNotice
      )
    );
    if (onError) {
      yield call(onError);
    }
  }
}

export function* watchForCreateKudosNominationCommentRequest() {
  yield takeLatest(CREATE_KUDOS_NOMINATION_COMMENT, performCreateKudosNominationComment);
}

export function* performLoadNominationComments({ nominationId }) {
  const [endpoint, requestOptions] = getLoadNominationCommentsRequest(nominationId);

  const { data } = yield call(axios, endpoint, requestOptions);

  yield put({ type: SET_NOMINATION_COMMENTS, nominationComments: data });
}

export function* watchForLoadNominationComments() {
  yield takeLatest(LOAD_NOMINATION_COMMENTS, performLoadNominationComments);
}

export function* performLoadCommentReplies({ nominationId, commentId }) {
  const [endpoint, requestOptions] = getLoadNominationCommentRepliesRequest(
    nominationId,
    commentId
  );

  const { data } = yield call(axios, endpoint, requestOptions);

  yield put({ type: SET_NOMINATION_COMMENT_REPLIES, commentReplies: data });
}

export function* watchForLoadNominationCommentReplies() {
  yield takeLatest(LOAD_NOMINATION_COMMENT_REPLIES, performLoadCommentReplies);
}

export function* performCreateKudosCommentReply({
  nominationId,
  commentId,
  payload,
  onSuccess,
  onError
}) {
  try {
    const [endpoint, requestOptions] = getCreateKudosCommentReplyRequest(
      nominationId,
      commentId,
      payload
    );

    yield call(axios, endpoint, requestOptions);

    yield call(onSuccess);
  } catch ({ response, config }) {
    yield put({ type: SET_KUDOS_ERRORS, errors: mapErrors(response?.data) });
    yield put(
      addSystemNotice(
        // eslint-disable-next-line
        "Your reply couldn't be created, please try again.",
        SNACK_CRITICAL,
        config?.skipNotice
      )
    );

    yield call(onError);
  }
}

export function* watchForCreateCommentReplyRequest() {
  yield takeLatest(CREATE_KUDOS_COMMENT_REPLY, performCreateKudosCommentReply);
}

export function* performRemoveNominationComment({
  nominationId,
  commentId,
  onSuccess,
  onComplete
}) {
  try {
    const [endpoint, requestOptions] = getRemoveNominationCommentRequest(nominationId, commentId);

    yield call(axios, endpoint, requestOptions);

    yield call(onSuccess);
  } catch ({ response, config }) {
    yield put(
      addSystemNotice('System error, please try again.', SNACK_CRITICAL, config?.skipNotice)
    );
  } finally {
    if (onComplete) {
      yield call(onComplete);
    }
  }
}

export function* watchForRemoveNominationComment() {
  yield takeLatest(REMOVE_NOMINATION_COMMENT, performRemoveNominationComment);
}

export function* performUpdateNominationComment({
  nominationId,
  commentId,
  data,
  onSuccess,
  onComplete
}) {
  try {
    const [endpoint, requestOptions] = getUpdateNominationCommentRequest(
      nominationId,
      commentId,
      data
    );

    yield call(axios, endpoint, requestOptions);

    yield call(onSuccess);
  } catch ({ response, config }) {
    yield put(
      addSystemNotice('System error, please try again.', SNACK_CRITICAL, config?.skipNotice)
    );
  } finally {
    if (onComplete) {
      yield call(onComplete);
    }
  }
}

export function* watchForUpdateNominationComment() {
  yield takeLatest(UPDATE_NOMINATION_COMMENT, performUpdateNominationComment);
}

export default function* kudosCommentsSaga() {
  yield all([
    watchForCreateKudosNominationCommentRequest(),
    watchForLoadNominationComments(),
    watchForCreateCommentReplyRequest(),
    watchForRemoveNominationComment(),
    watchForLoadNominationCommentReplies(),
    watchForUpdateNominationComment()
  ]);
}
