import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadMostPopularCategories } from '../../state/action/kudos/kudos.action';

import PopularCategoriesCard from '../../component/kudos/popular-categories/PopularCategoriesCard';

const emptyColor = '#d9d9d9';

const PopularCategoriesContainer = () => {
  const dispatch = useDispatch();

  const popularCategories = useSelector(({ kudos_store }) => kudos_store.popularCategories);

  useEffect(() => {
    dispatch(loadMostPopularCategories());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const segments = useMemo(() => {
    const isEmpty = popularCategories.every((category) => category.frequency < 1);

    return popularCategories.map((item) => ({
      id: item.category.id,
      name: item.category.name,
      value: isEmpty ? 1 : item.frequency,
      color: isEmpty ? emptyColor : item.category.colorCode
    }));
  }, [popularCategories]);

  return <PopularCategoriesCard segments={segments} />;
};

export default PopularCategoriesContainer;
