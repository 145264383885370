import { noOp } from '@neslotech/ui-utils';

const STORE_NAME = '[TIMESHEET_STORE]';

export const CREATE_TIMESHEET = `${STORE_NAME} Create timesheet`;
export const createTimesheet = (userId, onSuccess, onComplete) => ({
  type: CREATE_TIMESHEET,
  userId,
  onSuccess,
  onComplete
});

export const LOAD_ALL_TIMESHEETS = `${STORE_NAME} Load all timesheets`;
export const SET_TIMESHEETS = `${STORE_NAME} Set timesheets`;
export const loadAllTimesheets = (onComplete = noOp) => ({
  type: LOAD_ALL_TIMESHEETS,
  onComplete
});

export const LOAD_USER_TIMESHEET = `${STORE_NAME} Load user timesheet`;
export const SET_USER_TIMESHEET = `${STORE_NAME} Set user timesheet`;
export const loadUserTimesheet = (userId, onSuccess, onError) => ({
  type: LOAD_USER_TIMESHEET,
  userId,
  onSuccess,
  onError
});

export const LOAD_TIMESHEET = `${STORE_NAME} Load timesheet`;
export const SET_TIMESHEET = `${STORE_NAME} Set timesheet`;
export const loadTimesheet = (timesheetId, onComplete = noOp) => ({
  type: LOAD_TIMESHEET,
  timesheetId,
  onComplete
});

export const APPROVE_TIMESHEET = `${STORE_NAME} Approve timesheet`;
export const approveTimesheet = (id, onSuccess, onComplete) => ({
  type: APPROVE_TIMESHEET,
  id,
  onSuccess,
  onComplete
});

export const REJECT_TIMESHEET = `${STORE_NAME} Reject timesheet`;
export const rejectTimesheet = (id, payload, onSuccess, onComplete) => ({
  type: REJECT_TIMESHEET,
  id,
  payload,
  onSuccess,
  onComplete
});

export const EXPORT_TIMESHEET = `${STORE_NAME} Export timesheet`;
export const exportTimesheet = (payload, onSuccess, onComplete) => ({
  type: EXPORT_TIMESHEET,
  payload,
  onSuccess,
  onComplete
});
