import { arrayOf, func, string } from 'prop-types';
import React, { useRef, useState } from 'react';

import { noOp } from '@neslotech/ui-utils';

import { Tooltip } from '../../../common/layout/portal/tooltip/Tooltip';
import { CATEGORY_TYPE } from '../../../tool/prop-types';
import CategoryTooltip from '../tooltip/CategoryTooltip';

import { useDevice } from '../../../hook/useDevice';

import { ReactComponent as QuestionMarkIcon } from '../../../icon/question-mark.svg';

import CategoryTag from '../category-tag/CategoryTag';

import './category-select.scss';

const renderError = (error) => error && <small className="input__error">{error}</small>;

const CategorySelect = ({ categories, disabledItems, label, value, error, onSelectionChange }) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const tooltipRef = useRef();
  const { mobile } = useDevice();

  const toggleTooltip = () => {
    setTooltipVisible((prevState) => !prevState);
  };

  return (
    <div className="category-select">
      <label className="category-select__label">
        {label}
        <QuestionMarkIcon ref={tooltipRef} onClick={toggleTooltip} />
      </label>
      <div className="category-select__field-container">
        <fieldset>
          {categories.map((category) => {
            const categoryId = category.id;
            const categoryPoints = category.points;
            return (
              <div key={categoryId} className="category-select__radio-group">
                <CategoryTag
                  category={category}
                  outline={value !== categoryId}
                  onClick={() => onSelectionChange({ categoryId, categoryPoints })}
                  hover={value !== categoryId}
                  disabled={disabledItems.includes(categoryId)}
                />
              </div>
            );
          })}
        </fieldset>
      </div>
      {renderError(error)}
      <Tooltip
        zIndex={mobile ? null : 10000}
        parent={tooltipRef}
        fullWidth={mobile}
        offset={mobile ? 0 : 279}
        isVisible={isTooltipVisible}
      >
        <div className="category-select__tooltip">
          <CategoryTooltip categories={categories} />
        </div>
      </Tooltip>
    </div>
  );
};

CategorySelect.defaultProps = {
  categories: [],
  disabledItems: [],
  value: '',
  error: '',
  onSelectionChange: noOp
};

CategorySelect.propTypes = {
  categories: arrayOf(CATEGORY_TYPE),
  disabledItems: arrayOf(string),
  label: string.isRequired,
  value: string,
  error: string,
  onSelectionChange: func
};
export default CategorySelect;
