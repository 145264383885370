import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isEmpty } from '@neslotech/ui-utils';

import { loadKudosManagementCategories } from '../../state/action/kudos/management.action';

import KudosCategoryManagementProvider from '../../provider/KudosCategoryManagement.provider';

import { Loader } from '../../common/component/loader/Loader';
import KudosCategoryManagement from '../../component/kudos/management/KudosCategoryManagement';
import AppLayout from '../../component/layout/AppLayout';

export const KudosCategoryManagementContainer = () => {
  const categories = useSelector(({ kudos_management_store }) => kudos_management_store.categories);
  const stateLoading = useSelector(({ kudos_management_store }) => kudos_management_store.loading);
  const [isCategoriesLoading, setIsCategoriesLoading] = useState(stateLoading ?? false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(categories)) {
      setIsCategoriesLoading(true);
      dispatch(loadKudosManagementCategories(() => setIsCategoriesLoading(false)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  if (isCategoriesLoading) {
    return (
      <div className="kudos-management__loader">
        <Loader dark />
      </div>
    );
  }

  return (
    <AppLayout fullWidthBreadcrumb>
      <KudosCategoryManagementProvider>
        <KudosCategoryManagement />
      </KudosCategoryManagementProvider>
    </AppLayout>
  );
};

export default KudosCategoryManagementContainer;
