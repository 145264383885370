import { arrayOf, bool, node, oneOfType, string } from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { scrollIntoView } from '@neslotech/ui-utils';

import FilterProvider from '../../../provider/Filter.provider';

import { useDevice } from '../../../hook/useDevice';
import { useFilters } from '../../../hook/useFilter';

import { Button } from '../../component/button/Button';
import { Dropdown } from '../../component/dropdown/Dropdown';
import { FiltersWrapper } from '../../component/filter/FiltersWrapper';
import { Input } from '../../component/input/Input';

import { ReactComponent as BurgerMenuIcon } from '../../../icon/burger-menu-icon.svg';
import { ReactComponent as CloseIcon } from '../../../icon/close-icon.svg';
import { ReactComponent as SearchIcon } from '../../../icon/search-icon.svg';

import './filter-layout.scss';

const FilterSection = ({ label, hideFiltering }) => {
  const [closed, setClosed] = useState(true);

  const { clearSearch, search, filtering, setSearch, onClearFilters, filters } = useFilters();
  const { mobile } = useDevice();

  const appliedFiltersCount = useMemo(() => {
    if (filters) {
      return Object.values(filters).flatMap((obj) => obj.filterValues).length;
    }
    return 0;
  }, [filters]);

  const areFiltersApplied = useMemo(() => appliedFiltersCount > 0, [appliedFiltersCount]);

  const filterButtonLabel = useMemo(() => {
    if (!areFiltersApplied) {
      return <>Filter</>;
    }

    return (
      <>
        Filter <span>{appliedFiltersCount}</span>
      </>
    );
  }, [areFiltersApplied, appliedFiltersCount]);

  if (hideFiltering) {
    return (
      <Input
        action={search ? <CloseIcon onClick={clearSearch} /> : <SearchIcon />}
        label={label}
        onChange={setSearch}
        value={search}
      />
    );
  }

  return (
    <section>
      <Input
        action={search ? <CloseIcon onClick={clearSearch} /> : <SearchIcon />}
        label={label}
        onChange={setSearch}
        value={search}
      />
      <Dropdown
        closed={closed}
        dropdownChildren={<FiltersWrapper />}
        placement={mobile ? 'bottom-end' : 'bottom-start'}
      >
        {!mobile ? (
          <Button label={filterButtonLabel} secondary onClick={() => setClosed(false)} />
        ) : (
          <div className="filter-layout__mobile-menu">
            <button onClick={() => setClosed(false)}>
              <BurgerMenuIcon />
            </button>
            {areFiltersApplied && <span>{appliedFiltersCount}</span>}
          </div>
        )}
      </Dropdown>
      {filtering && !mobile && (
        <div className="filter-layout__mobile-action">
          <Button label="Clear All" onClick={onClearFilters} text />
        </div>
      )}
    </section>
  );
};

export const FilterLayout = ({ label, action, children, hideFiltering }) => {
  const { mobile } = useDevice();
  const filterActionsRef = useRef(null);

  useEffect(() => {
    scrollIntoView(filterActionsRef.current, { behavior: 'auto', block: 'end' });
  }, []);

  return (
    <article className="filter-layout">
      <FilterProvider>
        <header ref={filterActionsRef} className="filter-layout__actions">
          <FilterSection label={label} hideFiltering={hideFiltering} />
          {!mobile && action}
        </header>
        {children}
      </FilterProvider>
    </article>
  );
};

FilterLayout.defaultProps = {
  label: 'Search for something',
  hideFiltering: false
};

FilterLayout.propTypes = {
  label: string,
  action: oneOfType([arrayOf(node), node]),
  children: oneOfType([arrayOf(node), node]).isRequired,
  hideFiltering: bool
};
