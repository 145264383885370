import { arrayOf, bool, func, shape, string } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { isValuePresent } from '@neslotech/ui-utils';

import { filterElement, searchElement } from '../../../../tool/filter.util';
import { TIMESHEET_SUMMARY_TYPE } from '../../../../tool/prop-types';

import { useFilters } from '../../../../hook/useFilter';

import { Button } from '../../../../common/component/button/Button';
import ButtonGroup from '../../../../common/component/button/ButtonGroup';
import { Datepicker } from '../../../../common/component/datepicker/Datepicker';
import { SearchOptions } from '../../../../common/component/input/search/options/SearchOptions';
import { Table } from '../../../../common/component/table/Table';

import { FilterLayout } from '../../../../common/layout/filter/FilterLayout';
import { Modal } from '../../../../common/layout/portal/Modal';
import { ModalLayout } from '../../../../common/layout/portal/ModalLayout';

import './timesheet-list.scss';

const TABLE_COLUMNS = [
  { Header: '', accessor: 'profileImageSrc', disableSortBy: true },
  { Header: 'Name', accessor: 'name' },
  { Header: 'Start Date', accessor: 'startDate' },
  { Header: 'End Date', accessor: 'endDate' },
  { Header: 'Total Hours', accessor: 'totalHours' },
  { Header: 'Status', accessor: 'status' }
];

const DEFAULT_FILTERS = Object.freeze({ status: { filterValues: ['Submitted'] } });

const FILTER_OPTIONS = Object.freeze([
  {
    name: 'Status',
    active: false,
    singleSelect: true,
    values: [
      { value: 'Submitted', selected: true },
      { value: 'Approved', selected: false }
    ]
  }
]);

const TimesheetTable = ({ timesheets, loading }) => {
  const { filters, search, setDefaultOptions, setFilters } = useFilters();
  const navigate = useNavigate();

  useEffect(() => {
    setDefaultOptions(FILTER_OPTIONS);
    setFilters(DEFAULT_FILTERS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rowData = useMemo(() => {
    return timesheets
      .map(({ profileImageSrc, startDate, name, status, endDate, totalHours, id }) => ({
        profileImageSrc: (
          <img src={profileImageSrc} referrerPolicy="no-referrer" alt="Profile icon" />
        ),
        name,
        startDate,
        endDate,
        totalHours,
        status,
        onClick: () => navigate(`${id}`)
      }))
      .filter((timesheet) => filterElement(timesheet, filters))
      .filter((timesheet) => searchElement(timesheet.name, search));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timesheets, filters, search]);

  return (
    <div className="timesheet-list__table">
      <Table
        cols={TABLE_COLUMNS}
        rowData={rowData}
        loading={loading}
        emptyTitle="There are no timesheets"
      />
    </div>
  );
};

TimesheetTable.protoTypes = {
  timesheets: arrayOf(TIMESHEET_SUMMARY_TYPE).isRequired,
  loading: bool.isRequired
};

const DEFAULT_FORM_STATE = {
  startDate: new Date(),
  endDate: new Date(),
  clientId: null
};

const REQUIRED_FIELDS = Object.freeze(['startDate', 'endDate']);

export const TimesheetList = ({ timesheets = [], loading = false, onExport, clients = [] }) => {
  const [openDateModal, setOpenDateModal] = useState(false);
  const [openClientModal, setOpenClientModal] = useState(false);
  const [form, setForm] = useState(DEFAULT_FORM_STATE);

  const clientOptions = useMemo(
    () =>
      clients.map((client) => ({
        label: client.name,
        value: client.id
      })),
    [clients]
  );

  const isSubmitDisabled = useMemo(
    () => REQUIRED_FIELDS.some((field) => !isValuePresent(form[field])),
    [form]
  );

  const handleInputChange = (newState) => {
    setForm({ ...form, ...newState });
  };

  const handleNextFromDateModal = () => {
    setOpenDateModal(false);
    setOpenClientModal(true);
  };

  return (
    <div className="timesheet-list">
      <FilterLayout
        label="Search for something"
        action={<Button onClick={() => setOpenDateModal(true)} label="Export" />}
      >
        <TimesheetTable timesheets={timesheets} loading={loading} />
      </FilterLayout>

      {openDateModal && (
        <Modal className="timesheet-list__modal">
          <ModalLayout title="Select Date Range" onClose={() => setOpenDateModal(null)}>
            <span>Select a date range for the timesheets you want to export </span>
            <div className="timesheet-list__date-range">
              <Datepicker
                label="Start Date"
                name="startDate"
                minDate={new Date(2000, 0)}
                maxDate={form.endDate}
                yearDropdownItemNumber={100}
                value={form.startDate}
                onChange={handleInputChange}
                required
              />
              <Datepicker
                label="End Date"
                name="endDate"
                minDate={form.startDate || new Date(1900, 0)}
                yearDropdownItemNumber={100}
                value={form.endDate}
                onChange={handleInputChange}
              />
            </div>
            <ButtonGroup>
              <Button label="Next" onClick={handleNextFromDateModal} />
              <Button label="Cancel" onClick={() => setOpenDateModal(false)} secondary />
            </ButtonGroup>
          </ModalLayout>
        </Modal>
      )}

      {openClientModal && (
        <Modal className="timesheet-list__modal">
          <ModalLayout title="Select Client" onClose={() => setOpenClientModal(false)}>
            <span>Select a client for the timesheets you want to export</span>
            <div className="timesheet-list__client">
              <SearchOptions
                label="Select a client"
                secondaryLabel="Select a client"
                name="clientId"
                options={clientOptions}
                value={form.clientId}
                onChange={handleInputChange}
                buttonAlignEnd
              />
            </div>
            <ButtonGroup>
              <Button
                disabled={isSubmitDisabled}
                label="Export"
                onClick={() => onExport(form, () => setOpenClientModal(false))}
              />
              <Button label="Cancel" onClick={() => setOpenClientModal(false)} secondary />
            </ButtonGroup>
          </ModalLayout>
        </Modal>
      )}
    </div>
  );
};

TimesheetList.protoTypes = {
  timesheets: arrayOf(TIMESHEET_SUMMARY_TYPE),
  loading: bool,
  clients: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired
    })
  ),
  onExport: func.isRequired
};
