import { arrayOf, bool, func, shape, string } from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { getClassNames, noOp } from '@neslotech/ui-utils';

import { getEmployeeProfileURL } from '../../employee-directory/employee-directory.helper';

import { ROLES } from '../../../tool/constant';

import { Dropdown } from '../../../common/component/dropdown/Dropdown';

import { ReactComponent as EditIcon } from '../../../icon/edit-icon.svg';
import { ReactComponent as EllipsisIcon } from '../../../icon/ellipsis-icon.svg';
import { ReactComponent as TrashIcon } from '../../../icon/trash-icon.svg';

import Dialog from '../../../common/layout/portal/Dialog';
import { CommentInput } from './input/CommentInput';

import './nomination-card-comment-section.scss';

const NominationCardCommentSection = ({
  userImage,
  onCommentSubmit,
  nominationId,
  showAllComments,
  focusCommentField,
  comments,
  replies,
  onReplySubmit,
  onLoadReplies,
  loggedInUserId,
  loggedInUserRole,
  onRemoveComment,
  onUpdateNominationComment
}) => {
  const [showReplyInput, setShowReplyInput] = useState(false);
  const [showReplies, setShowReplies] = useState(false);
  const [focusReplyField, setFocusReplyField] = useState(false);
  const [comment, setComment] = useState('');
  const [editComment, setEditComment] = useState('');
  const [editing, setEditing] = useState(false);
  const [reply, setReply] = useState('');
  const [replyCommentId, setReplyCommentId] = useState(null);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [editCommentId, setEditCommentId] = useState(null);
  const [commentId, setCommentId] = useState(null);
  const [sender, setSender] = useState();
  const [type, setType] = useState('');

  const onCommentChange = (val) => {
    setComment(val);
  };

  const onEditCommentChange = (val) => {
    setEditComment(val);
  };

  const onReplyChange = (val) => {
    setReply(val);
  };

  const filteredComments = comments.filter((comment) => comment.nominationId === nominationId);

  const handleCommentSubmit = () => {
    onCommentSubmit(nominationId, comment.trim(), setComment);
  };

  const handleReplySubmit = (commentId) => {
    onReplySubmit(nominationId, commentId, reply.trim(), setReply);
  };

  const getMenuItems = (isComment, showCommentActions, setDialogOpen, onRemoveComment, comment) => {
    let menuItems = [];

    if (loggedInUserId === comment.createdBy) {
      menuItems.push({
        text: isComment ? 'Edit Comment' : 'Edit Reply',
        icon: <EditIcon />,
        onClick: () => {
          setEditComment(comment.message);
          setEditing(true);
          setEditCommentId(comment.id);
        }
      });
    }

    if (showCommentActions) {
      menuItems.push({
        text: isComment ? 'Delete Comment' : 'Delete Reply',
        icon: <TrashIcon />,
        onClick: () => {
          if (loggedInUserId !== comment.createdBy) {
            setSender(comment.sender);
            setCommentId(comment.id);
            setDialogOpen(true);
            setType(isComment ? 'comment' : 'reply');
          } else {
            onRemoveComment(nominationId, comment.id);
          }
        }
      });
    }

    return menuItems;
  };

  return (
    <div className="nomination-card-comment">
      <div id="input">
        <img src={userImage} referrerPolicy="no-referrer" alt="Profile icon" />
        <CommentInput
          id={nominationId}
          focusField={focusCommentField}
          value={comment}
          label="Write a comment"
          name="comment"
          onActionClick={handleCommentSubmit}
          onChange={onCommentChange}
          action="Send"
        />
      </div>

      {showAllComments &&
        filteredComments.map((comment) => {
          const commentId = comment.id;
          const isReplyVisible = showReplies && replyCommentId === commentId;
          const isEditingComment = editing && editCommentId === commentId;
          const showCommentActions =
            loggedInUserId === comment.createdBy || loggedInUserRole === ROLES.ADMIN;

          const menuItems = getMenuItems(
            true,
            showCommentActions,
            setDialogOpen,
            onRemoveComment,
            comment
          );

          const handleEditComment = () => {
            onUpdateNominationComment(nominationId, commentId, editComment, setEditing, 'comment');
            setEditComment(comment.message);
            setEditCommentId(null);
          };

          const handleCancelEditComment = () => {
            setEditing(false);
            setEditComment(comment.message);
          };

          const filteredReplies = replies.filter((reply) => reply.parentCommentId === commentId);

          return (
            <div key={commentId} className="comments">
              <div className="comments__content">
                <Link to={getEmployeeProfileURL(comment.createdBy)}>
                  <img
                    src={comment.profileImageSrc}
                    referrerPolicy="no-referrer"
                    alt="Profile icon"
                  />
                </Link>
                <div>
                  <div>
                    <Link to={getEmployeeProfileURL(comment.createdBy)}>
                      <span>{comment.sender}</span>
                    </Link>
                    {showCommentActions && (
                      <Dropdown menuItems={menuItems} offset={[32, 0]} placement="bottom-end">
                        <EllipsisIcon />
                      </Dropdown>
                    )}
                  </div>
                  <CommentInput
                    id={commentId}
                    name="comment"
                    value={editCommentId === commentId ? editComment : comment.message}
                    onChange={onEditCommentChange}
                    focusField={isEditingComment}
                    disabled={!isEditingComment}
                    resizable
                  />
                </div>
              </div>
              <div className="comments__actions">
                {!isEditingComment && (
                  <>
                    <button
                      onClick={() => {
                        setShowReplyInput(true);
                        setFocusReplyField(true);
                        setReplyCommentId(commentId);
                      }}
                    >
                      Reply
                    </button>
                    {comment.totalReplies > 0 && (
                      <button
                        onClick={() => {
                          onLoadReplies(nominationId, commentId);
                          setShowReplies(true);
                          setShowReplyInput(true);
                          setReplyCommentId(commentId);
                        }}
                      >
                        {comment.totalReplies}&nbsp;
                        {comment.totalReplies === 1 ? 'Reply' : 'Replies'}
                      </button>
                    )}
                  </>
                )}
                {isEditingComment && (
                  <>
                    <button
                      className={getClassNames('comments__actions', { edit: isEditingComment })}
                      onClick={handleEditComment}
                    >
                      Save Changes
                    </button>
                    <button onClick={handleCancelEditComment}>Cancel</button>
                  </>
                )}
              </div>
              {((showReplyInput && replyCommentId === commentId) || isReplyVisible) && (
                <div className="comments__reply-input">
                  <img src={userImage} referrerPolicy="no-referrer" alt="Profile icon" />
                  <CommentInput
                    id={commentId}
                    focusField={focusReplyField}
                    label="Write a reply"
                    name="reply"
                    value={reply}
                    onChange={onReplyChange}
                    onActionClick={() => handleReplySubmit(commentId)}
                    action="Send"
                  />
                </div>
              )}

              {isReplyVisible &&
                filteredReplies.map((reply) => {
                  const replyId = reply.id;

                  const isEditingComment = editing && editCommentId === replyId;

                  const replyMenuItems = getMenuItems(
                    false,
                    showCommentActions,
                    setDialogOpen,
                    onRemoveComment,
                    reply
                  );

                  const handleEditReply = () => {
                    onUpdateNominationComment(
                      nominationId,
                      replyId,
                      editComment,
                      setEditing,
                      'reply'
                    );
                    setEditComment(reply.message);
                    setEditCommentId(null);
                  };

                  const handleCancelEditReply = () => {
                    setEditing(false);
                    setEditComment(reply.message);
                  };

                  return (
                    <div className="replies" key={reply.id}>
                      <div className="replies__content">
                        <Link to={getEmployeeProfileURL(reply.createdBy)}>
                          <img
                            src={reply.profileImageSrc}
                            referrerPolicy="no-referrer"
                            alt="Profile icon"
                          />
                        </Link>
                        <div>
                          <div>
                            <Link to={getEmployeeProfileURL(reply.createdBy)}>
                              <span>{reply.sender}</span>
                            </Link>
                            {showCommentActions && (
                              <Dropdown
                                menuItems={replyMenuItems}
                                offset={[32, 0]}
                                placement="bottom-end"
                              >
                                <EllipsisIcon />
                              </Dropdown>
                            )}
                          </div>
                          <CommentInput
                            id={replyId}
                            name="comment"
                            value={editCommentId === replyId ? editComment : reply.message}
                            onChange={onEditCommentChange}
                            focusField={isEditingComment}
                            disabled={!isEditingComment}
                            resizable
                          />
                        </div>
                      </div>
                      <div className="replies__actions">
                        {isEditingComment && (
                          <>
                            <button
                              className={getClassNames('comments__actions', {
                                edit: isEditingComment
                              })}
                              onClick={handleEditReply}
                            >
                              Save Changes
                            </button>
                            <button onClick={handleCancelEditReply}>Cancel</button>
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          );
        })}
      {isDialogOpen && loggedInUserRole === ROLES.ADMIN && (
        <Dialog
          heading="Are you sure?"
          message={
            <>
              You are about to remove a <span>{type}</span> made by <span>{sender}</span>. Please
              confirm.
            </>
          }
          isOpen={isDialogOpen}
          primaryText="Confirm"
          secondaryText="Cancel"
          onPrimaryClick={() => {
            onRemoveComment(nominationId, commentId, sender);
            setDialogOpen(false);
          }}
          onSecondaryClick={() => setDialogOpen(false)}
          onClose={() => setDialogOpen(false)}
        />
      )}
    </div>
  );
};

NominationCardCommentSection.defaultProps = {
  userImage: '',
  loggedInUserRole: '',
  loggedInUserId: '',
  showAllComments: false,
  focusCommentField: false,
  onRemoveComment: noOp,
  onLoadReplies: noOp,
  comments: [],
  replies: [],
  onUpdateNominationComment: noOp
};

NominationCardCommentSection.propTypes = {
  userImage: string,
  nominationId: string.isRequired,
  onCommentSubmit: func.isRequired,
  onReplySubmit: func.isRequired,
  onLoadReplies: func,
  loggedInUserRole: string,
  loggedInUserId: string,
  comments: arrayOf(
    shape({
      id: string.isRequired,
      nominationId: string.isRequired,
      profileImageSrc: string.isRequired,
      sender: string.isRequired,
      message: string.isRequired,
      createdBy: string.isRequired
    })
  ),
  replies: arrayOf(
    shape({
      id: string.isRequired,
      nominationId: string.isRequired,
      profileImageSrc: string.isRequired,
      sender: string.isRequired,
      message: string.isRequired,
      createdBy: string.isRequired,
      parentCommentId: string.isRequired
    })
  ),
  focusCommentField: bool,
  onRemoveComment: func,
  onUpdateNominationComment: func
};

export default NominationCardCommentSection;
