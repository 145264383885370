import { arrayOf, func, node, oneOfType } from 'prop-types';
import React from 'react';

import { noOp } from '@neslotech/ui-utils';

import './mobile-card.scss';

export const MobileCard = ({ onClick, children }) => (
  <article className="mobile-card" onClick={onClick} onKeyDown={noOp}>
    {children}
  </article>
);

MobileCard.defaultProps = {
  onClick: noOp
};

MobileCard.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  onClick: func
};
