import { bool, func, instanceOf, oneOfType, shape, string } from 'prop-types';
import React, { createRef, useEffect, useMemo, useState } from 'react';

import { getClassNames, noOp } from '@neslotech/ui-utils';

import CommentInputAction from './action/CommentInputAction';

import InputError from '../../../../common/component/input/error/InputError';

import './comment-input.scss';

export const CommentInput = ({
  name,
  label,
  inputRef,
  error,
  onChange,
  onFocus,
  onActionClick,
  value,
  focusField,
  id,
  action,
  disabled,
  resizable
}) => {
  const wrapperRef = createRef();
  const [filled, setFilled] = useState(false);
  const memoizedLabel = useMemo(() => {
    return label;
  }, [label]);

  const handleFocus = () => {
    wrapperRef.current?.focus();
    onFocus();
  };

  useEffect(() => {
    if (focusField) {
      wrapperRef.current.querySelector(`[id="${id}"]`)?.focus();
    }
    // eslint-disable-next-line
  }, [focusField, id]);

  const handleInputChange = (e) => {
    onChange(e.target.value);
    setFilled(e.target.value.trim() !== '');
  };

  return (
    <div
      className={getClassNames('comment-input', {
        error,
        resizable
      })}
    >
      <fieldset ref={wrapperRef} className="comment-input__wrapper">
        {disabled && <p>{value}</p>}
        {!disabled && (
          <textarea
            rows={1}
            id={id}
            onChange={(e) => onChange(e.target.value)}
            onFocus={handleFocus}
            onKeyDown={handleInputChange}
            ref={inputRef}
            name={name}
            value={value}
            placeholder={memoizedLabel}
            disabled={disabled}
          />
        )}
        <CommentInputAction action={action} onActionClick={onActionClick} filled={filled} />
      </fieldset>
      {!!error && <InputError error={error} />}
    </div>
  );
};

CommentInput.defaultProps = {
  onChange: noOp,
  onFocus: noOp,
  onActionClick: noOp,
  value: '',
  error: '',
  inputRef: noOp,
  action: '',
  disabled: false,
  label: '',
  resizable: false
};

CommentInput.propTypes = {
  name: string.isRequired,
  label: string,
  inputRef: oneOfType([
    func,
    shape({
      current: instanceOf(Element)
    })
  ]),
  error: string,
  onChange: func,
  onFocus: func,
  onActionClick: func,
  value: string,
  focusField: bool,
  action: string,
  disabled: bool,
  id: string.isRequired,
  resizable: bool
};
