import createSagaMiddleware from '@redux-saga/core';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './state/reducer';
import authSaga from './state/saga/auth.saga';
import directorySaga from './state/saga/directory.saga';
import kudosCommentsSaga from './state/saga/kudos/comment.saga';
import kudosSaga from './state/saga/kudos/kudos.saga';
import managementSaga from './state/saga/kudos/management.saga';
import kudosReportSaga from './state/saga/kudos/report.saga';
import notificationsSaga from './state/saga/notification.saga';
import profileSaga from './state/saga/profile.saga';
import clientSaga from './state/saga/scheduling/client.saga';
import leaveAllocationSaga from './state/saga/scheduling/leave-allocation/leave-allocation.saga';
import projectSaga from './state/saga/scheduling/project.saga';
import schedulingPermissionsSaga from './state/saga/scheduling/scheduling-permissions.saga';
import allocationSaga from './state/saga/scheduling/schedulling-allocation.saga';
import leaveCategorySaga from './state/saga/scheduling/settings/leave-category.saga';
import taskAllocationSaga from './state/saga/scheduling/task-allocation/task-allocation.saga';
import taskSaga from './state/saga/scheduling/task.saga';
import timesheetSaga from './state/saga/scheduling/timesheet/timesheet.saga';
import userSaga from './state/saga/user.saga';

import App from './App';
import reportWebVitals from './reportWebVitals';

const sagaMiddleware = createSagaMiddleware();

const middlewareEnhancer = applyMiddleware(sagaMiddleware);

const isProduction = process.env.NODE_ENV === 'production';

const composedEnhancers = isProduction
  ? middlewareEnhancer
  : composeWithDevTools(middlewareEnhancer);

const store = createStore(rootReducer, composedEnhancers);

sagaMiddleware.run(authSaga);
sagaMiddleware.run(profileSaga);
sagaMiddleware.run(userSaga);
sagaMiddleware.run(directorySaga);
sagaMiddleware.run(kudosSaga);
sagaMiddleware.run(managementSaga);
sagaMiddleware.run(notificationsSaga);
sagaMiddleware.run(kudosCommentsSaga);
sagaMiddleware.run(kudosReportSaga);
sagaMiddleware.run(projectSaga);
sagaMiddleware.run(schedulingPermissionsSaga);
sagaMiddleware.run(clientSaga);
sagaMiddleware.run(taskSaga);
sagaMiddleware.run(leaveCategorySaga);
sagaMiddleware.run(leaveAllocationSaga);
sagaMiddleware.run(taskAllocationSaga);
sagaMiddleware.run(allocationSaga);
sagaMiddleware.run(timesheetSaga);

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
