import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';

import { SNACK_CRITICAL } from '@neslotech/ui-utils';

import { mapErrors } from '../../tool/error.util';
import {
  getActivateProfileRequest,
  getLoadBasicProfileRequest,
  getLoadProfileRequest,
  getSaveProfileInfoRequest,
  getUpdateProfileInfoRequest
} from '../tool/api/profile.endpoint';

import {
  ACTIVATE_PROFILE,
  LOAD_BASIC_PROFILE,
  LOAD_CONTEXT_USER,
  LOAD_PROFILE,
  SAVE_PROFILE_INFO,
  SET_CONTEXT_USER,
  SET_ERRORS,
  SET_USER_BASIC_PROFILE,
  SET_USER_PROFILE,
  UPDATE_PROFILE_INFO
} from '../action/profile.action';
import { addSystemNotice } from '../action/system.action';

export function* performSaveProfileInfo({ id, info, key, onSuccess, onComplete }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getSaveProfileInfoRequest(id, info, key);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch ({ response, config }) {
    yield put({ type: SET_ERRORS, errors: mapErrors(response?.data) });
    yield put(addSystemNotice('Your info could not be saved.', SNACK_CRITICAL, config?.skipNotice));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForSaveProfileInfoRequest() {
  yield takeLatest(SAVE_PROFILE_INFO, performSaveProfileInfo);
}

export function* performUpdateProfileInfo({ userId, info, key, onSuccess, onComplete }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getUpdateProfileInfoRequest(userId, info, key);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch ({ response, config }) {
    yield put({ type: SET_ERRORS, errors: mapErrors(response?.data) });
    yield put(addSystemNotice('Your info could not be saved.', SNACK_CRITICAL, config?.skipNotice));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateProfileInfoRequest() {
  yield takeLatest(UPDATE_PROFILE_INFO, performUpdateProfileInfo);
}

export function* performActivateProfile({ id, onSuccess, onComplete }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getActivateProfileRequest(id);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_CONTEXT_USER, contextUser: data });

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch ({ response, config }) {
    yield put(
      addSystemNotice('Your account could not be activated.', SNACK_CRITICAL, config?.skipNotice)
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForActivateProfileRequest() {
  yield takeLatest(ACTIVATE_PROFILE, performActivateProfile);
}

export function* performLoadBasicProfile({ userId, onComplete }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadBasicProfileRequest(userId);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_USER_BASIC_PROFILE, profile: data });
  } catch ({ response, config }) {
    yield put(
      addSystemNotice('The profile could not be found.', SNACK_CRITICAL, config?.skipNotice)
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadBasicProfileRequest() {
  yield takeLatest(LOAD_BASIC_PROFILE, performLoadBasicProfile);
}

export function* performLoadContextUser({ userId, onComplete }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadBasicProfileRequest(userId);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_CONTEXT_USER, contextUser: data });
  } catch ({ response, config }) {
    yield put(
      addSystemNotice('The profile could not be found.', SNACK_CRITICAL, config?.skipNotice)
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadContextUserRequest() {
  yield takeLatest(LOAD_CONTEXT_USER, performLoadContextUser);
}

export function* performLoadProfile({ userId, onComplete }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadProfileRequest(userId);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_USER_PROFILE, profile: data });
  } catch ({ response, config }) {
    yield put(
      addSystemNotice('The profile could not be found.', SNACK_CRITICAL, config?.skipNotice)
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadProfileRequest() {
  yield takeLatest(LOAD_PROFILE, performLoadProfile);
}

export default function* profileSaga() {
  yield all([
    watchForSaveProfileInfoRequest(),
    watchForUpdateProfileInfoRequest(),
    watchForActivateProfileRequest(),
    watchForLoadProfileRequest(),
    watchForLoadBasicProfileRequest(),
    watchForLoadContextUserRequest()
  ]);
}
