import React, { useEffect, useMemo, useRef } from 'react';

import { isEmpty } from '@neslotech/ui-utils';

import { SKELETON_ENTITY_PLACEHOLDERS } from '../../../tool/loader.helper';

import { searchElement } from '../../../tool/filter.util';

import { useDevice } from '../../../hook/useDevice';
import { useFilters } from '../../../hook/useFilter';
import { useKudosManagement } from '../../../hook/useKudosManagement';

import AddCategoryContainer from '../../../container/kudos-management/add/AddCategory.container';
import ToggleKudosCategoryContainer from '../../../container/kudos-management/toggle-category/ToggleKudosCategory.container';

import { Button } from '../../../common/component/button/Button';
import SkeletonLoader from '../../../common/component/loader/skeleton/SkeletonLoader';
import NoResults from '../../../common/component/no-results/NoResults';

import { FilterLayout } from '../../../common/layout/filter/FilterLayout';
import CategoryCard from '../../kudos-management/category-card/CategoryCard';

import './kudos-category-management.scss';

const Categories = () => {
  const {
    loading,
    categories,
    selectedCategory,
    setSelectedCategory,
    onUpdateCategory,
    categoryToBeUpdated,
    setCategoryToBeUpdated,
    errors,
    setCategoryFilter,
    categoryFilter,
    setIsCategoriesLoading
  } = useKudosManagement();
  const { search, setDefaultOptions, filters } = useFilters();
  const skeletonRef = useRef();

  useEffect(() => {
    setDefaultOptions(
      Object.freeze([
        {
          name: 'Type',
          active: false,
          singleSelect: true,
          values: [
            { value: 'Archived', selected: false },
            { value: 'Active', selected: true }
          ]
        }
      ])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredCategories = useMemo(() => {
    return categories.filter(
      (category) => searchElement(category.name, search) || searchElement(category.points, search)
    );
  }, [categories, search]);

  useEffect(() => {
    if (!filters?.type) {
      setCategoryFilter('active');

      if (categoryFilter === 'archived') {
        setIsCategoriesLoading(true);
      }
      return;
    }

    const newFilterValues = filters.type.filterValues[0].toLowerCase();

    if (newFilterValues !== categoryFilter) {
      setIsCategoriesLoading(true);
      setCategoryFilter(newFilterValues);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const onSave = (form) => {
    onUpdateCategory(selectedCategory, form, () => setSelectedCategory(''));
  };

  return (
    <SkeletonLoader loading={loading} elementRef={skeletonRef}>
      <section className="kudos-management__categories" ref={skeletonRef}>
        {(loading ? SKELETON_ENTITY_PLACEHOLDERS : filteredCategories).map((item) => {
          const isFocused = item.id === selectedCategory;
          return (
            <CategoryCard
              key={item.id}
              category={item}
              onActionClick={
                isFocused
                  ? onSave
                  : (form) => setCategoryToBeUpdated({ categoryId: form.id, active: item.active })
              }
              actionText={isFocused ? 'Save Changes' : item.active ? 'Archive' : 'Reinstate'}
              focused={isFocused}
              inactive={isEmpty(selectedCategory) ? false : !isFocused}
              onClose={() => setSelectedCategory('')}
              onFocus={() => setSelectedCategory(item.id)}
              cancel={isFocused}
              errors={isFocused ? errors : undefined}
            />
          );
        })}
        {isEmpty(filteredCategories) && (
          <NoResults
            emptyTitle="No matching categories were found"
            emptySubtitle={
              filters
                ? 'Try removing some filters to see more results'
                : 'Try editing your search to see more results'
            }
          />
        )}
        {categoryToBeUpdated && (
          <ToggleKudosCategoryContainer selectedCategory={categoryToBeUpdated} />
        )}
      </section>
    </SkeletonLoader>
  );
};

const KudosCategoryManagement = () => {
  const { setIsModalOpen, loading, isModalOpen } = useKudosManagement();
  const { mobile } = useDevice();

  if (mobile) {
    return (
      <>
        {!isModalOpen && (
          <div className="kudos-management">
            <FilterLayout label="Search for something">
              <Button label="Add a new category" onClick={() => setIsModalOpen(!loading)} />
              <Categories />
            </FilterLayout>
          </div>
        )}
        {isModalOpen && <AddCategoryContainer />}
      </>
    );
  }

  return (
    <>
      <div className="kudos-management">
        <FilterLayout
          label="Search for something"
          action={<Button label="Add a new category" onClick={() => setIsModalOpen(!loading)} />}
        >
          <Categories />
        </FilterLayout>
      </div>
      <AddCategoryContainer />
    </>
  );
};

export default KudosCategoryManagement;
