import { noOp } from '@neslotech/ui-utils';

import { getSkeletonPlaceholders } from '../../../tool/loader.helper';

import { ROLES, ROLE_OPTIONS } from '../../../tool/constant';
import { filterElement, searchElement } from '../../../tool/filter.util';

import { Select } from '../../../common/component/select/Select';
import { TableImage } from '../../../common/component/table/TableImage';

import { ReactComponent as TrashIcon } from '../../../icon/trash-icon.svg';

export const USER_MAN_COLS = Object.freeze([
  { Header: '', accessor: 'image', disableSortBy: true },
  { Header: 'First Name', accessor: 'firstName' },
  { Header: 'Last Name', accessor: 'lastName' },
  { Header: 'Email Address', accessor: 'emailAddress' },
  { Header: 'Department', accessor: 'department' },
  { Header: 'Role', accessor: 'role' },
  { Header: 'Status', accessor: 'status' },
  { Header: '', accessor: 'action', disableSortBy: true }
]);

const mapToBasicUsers = (users) =>
  users.map((user) => ({
    id: user.id,
    image: user.image ?? user.defaultImage,
    firstName: user.person.firstName,
    lastName: user.person.lastName,
    emailAddress: user.email,
    role: user.role,
    department: user.person.department,
    status: user.active ? 'Active' : 'Inactive'
  }));

const getAction = (user, setUserToRemove) => {
  if (user.role === ROLES.ADMIN) {
    return null;
  }

  return (
    <button
      onClick={(e) => {
        e.stopPropagation();
        setUserToRemove(user);
      }}
    >
      <TrashIcon />
    </button>
  );
};

export const mapUsers = (users, search, filters) => {
  return mapToBasicUsers(users)
    .filter((user) => filterElement(user, filters))
    .filter(
      (user) =>
        searchElement(user.firstName, search) ||
        searchElement(user.lastName, search) ||
        searchElement(user.emailAddress, search)
    );
};

export const mapRowData = (
  users,
  navigate,
  search,
  filters,
  setUserToRemove,
  setUserToChange,
  loading
) => {
  if (loading) {
    return getSkeletonPlaceholders([], 8);
  }

  return (
    // map to basic users first to allow filtering on multiple properties
    mapToBasicUsers(users)
      .filter((user) => filterElement(user, filters))
      .filter(
        (user) =>
          searchElement(user.firstName, search) ||
          searchElement(user.lastName, search) ||
          searchElement(user.emailAddress, search)
      )
      .map((user) => ({
        ...user,
        image: <TableImage image={user.image} name={`${user.firstName} ${user.lastName}`} />,
        role: (
          <Select
            options={ROLE_OPTIONS}
            onChange={(role) => setUserToChange({ ...user, role })}
            label=""
            value={user.role}
            borderless
            small
          />
        ),
        action: getAction(user, setUserToRemove),
        onClick:
          user.status === 'Inactive'
            ? noOp
            : () => navigate(`/user-management/${user.id}/${user.firstName} ${user.lastName}`)
      }))
  );
};

export const USER_MANAGEMENT_FILTER_OPTIONS = Object.freeze([
  {
    name: 'Role',
    active: false,
    values: [
      {
        value: 'Admin',
        selected: false
      },
      {
        value: 'Employee',
        selected: false
      },
      {
        value: 'Operations',
        selected: false
      }
    ]
  },
  {
    name: 'Department',
    active: false,
    values: [
      {
        value: 'Engineers',
        selected: false
      },
      {
        value: 'Designers',
        selected: false
      },
      {
        value: 'Executives',
        selected: false
      },
      {
        value: 'Administration',
        selected: false
      }
    ]
  }
]);
