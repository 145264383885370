import { arrayOf, bool, func, node, oneOf, oneOfType, string } from 'prop-types';
import React from 'react';

import { getClassNames, noOp } from '@neslotech/ui-utils';

import { Loader } from '../loader/Loader';

import './button.scss';

const renderIcon = (icon) => icon && <span className="button__icon">{icon}</span>;

export const Button = ({
  type,
  fit,
  small,
  large,
  secondary,
  text,
  loading,
  disabled,
  icon,
  iconPosition,
  onClick,
  label,
  borderless
}) => (
  <button
    type={type}
    className={getClassNames('button', {
      fit,
      small,
      large,
      secondary,
      text,
      disabled,
      borderless
    })}
    onClick={onClick}
    disabled={disabled}
  >
    {loading && <Loader dark={secondary} />}
    {!loading && (
      <span
        className={getClassNames('button__content', {
          left: iconPosition === 'left',
          right: iconPosition === 'right'
        })}
      >
        {label}
        {renderIcon(icon)}
      </span>
    )}
  </button>
);

Button.defaultProps = {
  type: 'button',
  small: false,
  large: false,
  secondary: false,
  text: false,
  loading: false,
  disabled: false,
  iconPosition: 'left',
  onClick: noOp,
  fit: false,
  borderless: false
};

Button.propTypes = {
  type: string,
  small: bool,
  large: bool,
  secondary: bool,
  text: bool,
  loading: bool,
  disabled: bool,
  icon: oneOfType([arrayOf(node), node]),
  iconPosition: oneOf(['left', 'right']),
  onClick: func,
  label: oneOfType([string, node]).isRequired,
  fit: bool,
  borderless: bool
};
