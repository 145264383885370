import { func } from 'prop-types';
import React, { useMemo, useState } from 'react';

import { isValuePresent } from '@neslotech/ui-utils';

import { TIMESHEET_STATUS } from '../../../tool/constant';
import { TIMESHEET_TYPE } from '../../../tool/prop-types';

import { Button } from '../../../common/component/button/Button';
import { TextArea } from '../../../common/component/textarea/TextArea';

import { Modal } from '../../../common/layout/portal/Modal';
import { ModalLayout } from '../../../common/layout/portal/ModalLayout';
import { TimesheetTable } from './table/TimesheetTable';

import './timesheet.scss';

const DEFAULT_STATE = {
  message: ''
};

const REQUIRED_FIELDS = Object.freeze(['message']);

export const Timesheet = ({ onApprove, onReject, timesheet }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [state, setState] = useState(DEFAULT_STATE);

  const isRejectDisabled = useMemo(() => {
    return REQUIRED_FIELDS.some((field) => !isValuePresent(state[field]));
  }, [state]);

  const handleInputChange = (newState) => setState({ ...state, ...newState });

  return (
    <div className="scheduling-timesheet">
      <div>
        <h5>{timesheet.username}</h5>
        {timesheet.status === TIMESHEET_STATUS.APPROVED && (
          <span className="scheduling-timesheet__status">{TIMESHEET_STATUS.APPROVED}</span>
        )}
      </div>
      <TimesheetTable timesheet={timesheet} />

      {timesheet.status === TIMESHEET_STATUS.SUBMITTED && (
        <div className="scheduling-timesheet__actions">
          <Button label="Approve" onClick={() => onApprove(timesheet.id)} />
          <Button label="Reject" secondary onClick={() => setIsModalOpen(true)} />
        </div>
      )}

      {isModalOpen && (
        <Modal className="scheduling-timesheet__modal">
          <ModalLayout title="Reject Timesheet Submission" onClose={() => setIsModalOpen(false)}>
            <div className="scheduling-timesheet__modal-content">
              <span>Provide a reason for rejecting timesheet</span>
              <TextArea
                label="Enter Reason Here"
                name="message"
                value={state.message}
                onChange={handleInputChange}
              />
              <div className="scheduling-timesheet__reject-actions">
                <Button
                  disabled={isRejectDisabled}
                  label="Reopen"
                  onClick={() => onReject(timesheet.id, state)}
                />
                <Button label="Cancel" secondary onClick={() => setIsModalOpen(false)} />
              </div>
            </div>
          </ModalLayout>
        </Modal>
      )}
    </div>
  );
};

Timesheet.propTypes = {
  timesheet: TIMESHEET_TYPE.isRequired,
  onReject: func.isRequired,
  onApprove: func.isRequired
};
