import { howLongAgo, toDateAndTime } from '@neslotech/ui-utils';

import profileIcon from '../icon/profile-icon.svg';

export const mapNominations = (id, nominations, directoryUsers) => {
  return (nominations ?? []).map((nomination) => {
    let recipient = directoryUsers.find((user) => user.id === nomination.recipientId);
    let sender = directoryUsers.find((user) => user.id === nomination.createdBy);

    if (!recipient) {
      console.warn('The recipient could not be found: ', nomination.recipientId);
      recipient = {
        id: nomination.recipientId,
        firstName: 'Unknown',
        lastName: 'User'
      };
    }

    if (!sender) {
      console.warn('The sender could not be found: ', nomination.createdBy);
      sender = {
        id: nomination.createdBy,
        firstName: 'Unknown',
        lastName: 'User'
      };
    }

    return {
      id: nomination.id,
      senderId: sender.id,
      receiverId: recipient.id,
      profileImageSrc: sender.image ?? sender.defaultImage ?? profileIcon,
      receiver: recipient.id === id ? 'You' : `${recipient.firstName} ${recipient.lastName}`,
      sender: sender.id === id ? 'You' : `${sender.firstName} ${sender.lastName}`,
      message: nomination.message,
      timeSent: howLongAgo(nomination.createdDate, true).toLowerCase(),
      date: toDateAndTime(nomination.createdDate),
      category: nomination.category,
      liked: nomination.liked,
      totalLikes: nomination.totalLikes,
      totalComments: nomination.totalComments
    };
  });
};

export const mapNominationComments = (nominationComments, directoryUsers) => {
  return (nominationComments ?? []).map((comment) => mapNominationComment(comment, directoryUsers));
};

export const mapNominationComment = (comment, directoryUsers) => {
  let sender = directoryUsers.find((user) => user.id === comment.createdBy);

  if (!sender) {
    console.warn('The sender could not be found: ', comment.createdBy);
    sender = {
      id: comment.createdBy,
      firstName: 'Unknown',
      lastName: 'User'
    };
  }

  return {
    id: comment.id,
    profileImageSrc: sender.image ?? sender.defaultImage ?? profileIcon,
    sender: `${sender.firstName} ${sender.lastName}`,
    message: comment.message,
    nominationId: comment.nominationId,
    createdBy: comment.createdBy,
    totalReplies: comment.totalReplies ?? 0,
    parentCommentId: comment.parentCommentId
  };
};

export const mapLeadersToUser = (leaders, directoryUsers) =>
  (leaders ?? []).map((leader) => {
    const user = directoryUsers.find((user) => user.id === leader.userId);

    if (!user) {
      throw Error('The user could not be found');
    }

    return {
      id: user.id,
      name: `${user.firstName} ${user.lastName}`,
      image: user.image ?? user.defaultImage
    };
  });
