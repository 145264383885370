import React from 'react';
import { Link, Route } from 'react-router-dom';

import { ROLES } from '../tool/constant';

import ProtectedRoute from '../provider/ProtectedRoute';

import CompleteProfileDetailsContainer from '../container/auth/complete-profile/details/CompleteProfileDetails.container';
import LoginContainer from '../container/auth/login/Login.container';
import DashboardContainer from '../container/dashboard/Dashboard.container';

import AppTitle from '../common/component/app-title/AppTitle';
import Logout from '../component/auth/logout/Logout';
import NotFound from '../component/error/NotFound';

import { employeeDirectoryRoutes } from './employee-directory.route';
import { kudosManagementRoutes } from './kudos-management.route';
import { kudosRoutes } from './kudos.route';
import { notificationRoutes } from './notification.route';
import { schedulingRoutes } from './scheduling.route';
import { userManagementRoutes } from './user-management.route';
import { userRoutes } from './user.route';

export const appRoutes = () => (
  <>
    <Route exact path="/logout" element={<Logout />} />
    <Route
      path="/"
      errorElement={<NotFound />}
      handle={{
        crumb: () => <Link to="/dashboard">Dashboard</Link>,
        pageTitle: 'Login'
      }}
    >
      <Route
        exact
        path="/"
        element={
          <AppTitle>
            <LoginContainer />
          </AppTitle>
        }
      />
      <Route
        exact
        path="complete-profile"
        handle={{
          pageTitle: 'Complete Profile'
        }}
        element={
          <ProtectedRoute allowedRoles={[ROLES.ADMIN, ROLES.EMPLOYEE]}>
            <CompleteProfileDetailsContainer />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="dashboard"
        handle={{
          crumb: () => <Link to="/dashboard">Dashboard</Link>,
          pageTitle: 'Dashboard'
        }}
        element={
          <ProtectedRoute allowedRoles={[ROLES.ADMIN, ROLES.EMPLOYEE]}>
            <DashboardContainer />
          </ProtectedRoute>
        }
      />
      {userRoutes()}
      {userManagementRoutes()}
      {employeeDirectoryRoutes()}
      {kudosRoutes()}
      {notificationRoutes()}
    </Route>
    {kudosManagementRoutes()}
    {schedulingRoutes()}
  </>
);
