import {
  SET_TIMESHEET,
  SET_TIMESHEETS,
  SET_USER_TIMESHEET
} from '../../../action/scheduling/timesheet/timesheet.action';

export const initialState = {
  timesheets: [],
  timesheet: {},
  userTimesheet: {}
};

export function timesheetReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TIMESHEETS:
      const { timesheets } = action;
      return {
        ...state,
        timesheets
      };
    case SET_USER_TIMESHEET:
      const { userTimesheet } = action;
      return {
        ...state,
        userTimesheet
      };
    case SET_TIMESHEET:
      const { timesheet } = action;
      return {
        ...state,
        timesheet
      };
    default:
      return state;
  }
}
