import { func } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isEmpty } from '@neslotech/ui-utils';

import {
  clearKudosErrors,
  loadActiveCategories,
  loadRemainingPoints
} from '../../../../state/action/kudos/kudos.action';

import GiveKudosProvider from '../../../../provider/GiveKudos.provider';

import { useAuth } from '../../../../hook/useAuth';
import { useDevice } from '../../../../hook/useDevice';
import { useKudos } from '../../../../hook/useKudos';

import GiveKudos from '../../../../component/kudos/management/give-kudos/GiveKudos';

import { Modal } from '../../../../common/layout/portal/Modal';
import { ModalLayout } from '../../../../common/layout/portal/ModalLayout';

const GiveKudosForm = ({ categories, userOptions, loading, onClose }) => {
  const { onSubmit } = useKudos();

  const handleOnSubmit = () => onSubmit(onClose);

  return (
    <GiveKudos
      categories={categories}
      userOptions={userOptions}
      onCancel={onClose}
      onSubmit={handleOnSubmit}
      loading={loading}
    />
  );
};

const GiveKudosContainer = ({ onClose }) => {
  const { id } = useAuth();
  const { mobile } = useDevice();

  const dispatch = useDispatch();

  const remainingPoints = useSelector(({ kudos_store }) => kudos_store.remainingPoints);
  const categories = useSelector(({ kudos_store }) => kudos_store.categories);
  const directoryUsers = useSelector(({ directory_store }) => directory_store.directoryUsers);

  const kudosStateLoading = useSelector(({ kudos_store }) => kudos_store.loading);

  const [isRemainingPointsLoading, setIsRemainingPointsLoading] = useState(
    kudosStateLoading ?? true
  );
  const [isCategoriesLoading, setIsCategoriesLoading] = useState(kudosStateLoading ?? true);

  useEffect(() => {
    if (!!id && !remainingPoints) {
      dispatch(loadRemainingPoints(id, () => setIsRemainingPointsLoading(false)));
    } else {
      setIsRemainingPointsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (isEmpty(categories)) {
      dispatch(loadActiveCategories(() => setIsCategoriesLoading(false)));
    } else {
      setIsCategoriesLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  const memoizedUserOptions = useMemo(() => {
    return (directoryUsers ?? [])
      .filter((person) => person.id !== id && person.active)
      .map((person) => ({
        label: `${person.firstName} ${person.lastName}`,
        value: person.id
      }));
  }, [directoryUsers, id]);

  const modalLoading = useMemo(
    () => isRemainingPointsLoading || isCategoriesLoading,
    [isRemainingPointsLoading, isCategoriesLoading]
  );

  const handleOnCloseClick = () => {
    dispatch(clearKudosErrors());
    onClose();
  };

  if (mobile) {
    return (
      <GiveKudosProvider>
        <section className="give-kudos-modal">
          <GiveKudosForm
            categories={categories}
            userOptions={memoizedUserOptions}
            loading={modalLoading}
            onClose={handleOnCloseClick}
          />
        </section>
      </GiveKudosProvider>
    );
  }

  return (
    <GiveKudosProvider>
      <Modal className="give-kudos-modal">
        <ModalLayout onClose={handleOnCloseClick} large>
          <GiveKudosForm
            categories={categories}
            userOptions={memoizedUserOptions}
            loading={modalLoading}
            onClose={handleOnCloseClick}
          />
        </ModalLayout>
      </Modal>
    </GiveKudosProvider>
  );
};

GiveKudosContainer.propTypes = {
  onClose: func.isRequired
};

export default GiveKudosContainer;
