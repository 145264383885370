/**
 * @module Loader Component
 * @description
 * To be displayed when waiting for a response from an asynchronous call.
 */
import { bool } from 'prop-types';
import React from 'react';

import { getClassNames } from '@neslotech/ui-utils';

import './loader.scss';

export const Loader = ({ dark, positioned }) => (
  <div className={getClassNames('loader', { dark, positioned })}>
    <div className="loader-ellipsis">
      <div />
      <div />
      <div />
    </div>
  </div>
);

Loader.defaultProps = {
  dark: false,
  positioned: false
};

Loader.propTypes = {
  dark: bool,
  positioned: bool
};
