/**
 * @module SelectMenu Component
 * @description
 * Utilised by `Select` to display a menu.
 */
import { arrayOf, func, node, object, oneOfType, shape, string } from 'prop-types';
import React, { useState } from 'react';

import { getClassNames, noOp } from '@neslotech/ui-utils';

const MenuItem = ({
  text,
  icon,
  bordered,
  justified,
  onClick,
  expandable,
  expandedItems,
  selectItem
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <li className={getClassNames('dropdown-menu__item', { bordered, justified })}>
      <button
        className={`${icon ? 'icon' : ''}`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          if (expandable) {
            setExpanded(!expanded);
          } else {
            onClick();
            selectItem();
          }
        }}
      >
        {icon}
        <span>{text}</span>
      </button>
      {expanded && <section className="dropdown-menu__expanded-items">{expandedItems}</section>}
    </li>
  );
};

export const DropdownMenu = ({ menuItems, selectItem }) => (
  <ul className="dropdown-menu">
    {menuItems.map(({ text, icon, bordered, onClick, expandable, expandedItems }, index) => (
      <MenuItem
        key={index}
        text={text}
        icon={icon}
        bordered={bordered}
        onClick={onClick}
        expandable={expandable}
        expandedItems={expandedItems}
        selectItem={selectItem}
      />
    ))}
  </ul>
);

DropdownMenu.defaultProps = {
  selectItem: noOp
};

DropdownMenu.propTypes = {
  menuItems: arrayOf(
    shape({
      text: oneOfType([string, object, node]),
      onClick: func
    })
  ).isRequired,
  selectItem: func
};
