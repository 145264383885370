import { number, shape, string } from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { getClassNames } from '@neslotech/ui-utils';

import { Tooltip } from '../../../../../../common/layout/portal/tooltip/Tooltip';
import { determineTextColour } from '../../../../../../tool/color.util';
import { LEAVE_ALLOCATION, ROLES } from '../../../../../../tool/constant';
import { PROJECT_METADATA_TYPE, USER_TYPE } from '../../../../../../tool/prop-types';
import { calculateHeight } from '../../../../../../tool/scheduling.util';

// eslint-disable-next-line max-len
import { SchedulingAllocationFormContainer } from '../../../../../../container/scheduling/allocation/form/SchedulingAllocationForm.container';

import './scheduling-allocation.scss';

export const SchedulingAllocation = ({ task, colour, loggedInUser, project }) => {
  const [openModal, setOpenModal] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const tooltipRef = useRef();

  const isAdmin = loggedInUser.role === ROLES.ADMIN;
  const isLeaveAllocation = task.allocationType === LEAVE_ALLOCATION;
  const isOwnTask = task.assignee === loggedInUser.id;

  const canInteract = isAdmin || (!isLeaveAllocation && isOwnTask);
  const cursorStyle = canInteract ? 'pointer' : 'default';

  const textColour = useMemo(() => determineTextColour(colour), [colour]);

  useEffect(() => {
    if (tooltipRef.current) {
      const { offsetWidth, scrollWidth } = tooltipRef.current;
      setIsTruncated(scrollWidth > offsetWidth);
    }
  }, [task.title]);

  return (
    <>
      <button
        className={getClassNames('scheduling-allocation', {
          leave: isLeaveAllocation,
          'under-one-hour': task.hours < 1
        })}
        style={{
          backgroundColor: colour,
          height: `${calculateHeight(task.hours, false)}px`,
          cursor: cursorStyle,
          color: textColour
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          if (canInteract) {
            setOpenModal(true);
          }
        }}
      >
        <span ref={tooltipRef}>
          {!isLeaveAllocation && `${project?.name}/`}
          {task.title}
        </span>
        <span>{task.hours}h</span>
      </button>

      {openModal && (
        <SchedulingAllocationFormContainer
          allocation={task}
          selectedTab={isLeaveAllocation ? 'Time Off' : 'Allocation'}
          onClose={() => setOpenModal(false)}
          isEditing
        />
      )}

      {isTruncated && (
        <Tooltip parent={tooltipRef} offset={8}>
          <div className="scheduling-allocation__tooltip">
            <span>{task.title}</span>
          </div>
        </Tooltip>
      )}
    </>
  );
};

SchedulingAllocation.propTypes = {
  task: shape({
    title: string.isRequired,
    hours: number.isRequired
  }).isRequired,
  colour: string,
  loggedInUser: USER_TYPE,
  project: PROJECT_METADATA_TYPE
};
