import { bool, func, string } from 'prop-types';
import React from 'react';

import { noOp } from '@neslotech/ui-utils';

import './radio-selector.scss';

export const RadioSelector = ({ onChange = noOp, checked = false, label }) => (
  <div className="radio-selector">
    <label htmlFor={label}>{label}</label>
    <label className="radio-selector__control">
      <input
        id={label}
        type="radio"
        onChange={onChange}
        checked={checked}
        name={label}
        className="radio-selector__input"
      />
      {checked && <div className="radio-selector__checked" />}
    </label>
  </div>
);

RadioSelector.propTypes = {
  onChange: func,
  checked: bool,
  label: string.isRequired
};
