import { bool, func } from 'prop-types';
import React from 'react';

import { noOp } from '@neslotech/ui-utils';

import './unread-indicator.scss';

export const UnreadIndicator = ({ onClick, isRead }) => (
  <button className="unread-indicator" onClick={onClick}>
    {!isRead && <div className="unread-indicator__read" />}
  </button>
);

UnreadIndicator.defaultProps = {
  onClick: noOp,
  isRead: false
};

UnreadIndicator.propTypes = {
  onClick: func,
  isRead: bool
};
