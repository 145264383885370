import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { isEmpty } from '@neslotech/ui-utils';

import { saveSchedulingPermissions } from '../../../state/action/scheduling/scheduling-permissions.action';
import { loadUsers } from '../../../state/action/user.action';

import { useProgressLoader } from '../../../hook/useProgressLoader';
import { useSchedulingPermissions } from '../../../hook/useSchedulingPermissions';

import SchedulingUserAccess from '../../../component/scheduling/access/SchedulingUserAccess';

const SchedulingUserAccessContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setBusyState } = useProgressLoader();
  const { schedulingPermissionIds, allSchedulingPermissionsLoading } = useSchedulingPermissions();

  const stateLoading = useSelector(({ directory_store }) => directory_store.loading);
  const users = useSelector(({ directory_store }) =>
    directory_store.directoryUsers?.filter((user) => user.active === true)
  );

  const [loading, setLoading] = useState(stateLoading ?? true);

  const stopLoading = () => setLoading(false);

  useEffect(() => {
    dispatch(loadUsers(stopLoading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const noPermissions = isEmpty(schedulingPermissionIds);

  const onSubmit = (data) => {
    setBusyState(true);
    dispatch(
      saveSchedulingPermissions(
        data,
        () => {
          setBusyState(false);
          if (noPermissions) {
            navigate('/scheduling/projects');
          }
        },
        () => setBusyState(false)
      )
    );
  };

  return (
    <SchedulingUserAccess
      users={users}
      permissions={schedulingPermissionIds}
      loading={loading || allSchedulingPermissionsLoading}
      onSubmit={onSubmit}
      setup={noPermissions}
    />
  );
};

export default SchedulingUserAccessContainer;
