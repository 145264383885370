import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { UNLINK_PROJECT } from '../../../../../tool/pusher.helper';

import {
  loadClient,
  unlinkProject,
  unlinkRealtimeProject,
  updateClient
} from '../../../../../state/action/scheduling/client.action';

import { ChannelProvider } from '../../../../../provider/Channel.provider';

import { useChannel } from '../../../../../hook/useChannel';
import { useProgressLoader } from '../../../../../hook/useProgressLoader';

import { Loader } from '../../../../../common/component/loader/Loader';
import ClientEditForm from '../../../../../component/scheduling/client/form/update/ClientEditForm';

const ClientEditFormWithChannel = ({ client, onSubmit, onUnlinkProject }) => {
  const dispatch = useDispatch();

  const { bindToEvent } = useChannel();

  useEffect(() => {
    bindToEvent(UNLINK_PROJECT, (data) => dispatch(unlinkRealtimeProject(data)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ClientEditForm client={client} onSubmit={onSubmit} onUnlinkProject={onUnlinkProject} />;
};

export const ClientEditContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { setBusyState } = useProgressLoader();

  const client = useSelector(({ client_store }) => client_store.client);
  const stateLoading = useSelector(({ client_store }) => client_store.loading);

  const [loading, setLoading] = useState(stateLoading ?? true);

  useEffect(() => {
    dispatch(loadClient(id, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (client) => {
    setBusyState(true);
    dispatch(updateClient(id, client, navigate, () => setBusyState(false)));
  };

  const handleUnlinkProject = (projectId) => {
    setBusyState(true);
    dispatch(unlinkProject(id, projectId, () => setBusyState(false)));
  };

  if (loading) {
    return (
      <div id="client-edit-form-loader">
        <Loader dark />
      </div>
    );
  }

  return (
    <ChannelProvider channelName={id}>
      <ClientEditFormWithChannel
        client={client}
        onSubmit={handleSubmit}
        onUnlinkProject={handleUnlinkProject}
      />
    </ChannelProvider>
  );
};
