import { bool, func, instanceOf, node, number, oneOf, oneOfType, shape, string } from 'prop-types';
import React, { createRef, useEffect, useMemo, useState } from 'react';

import { getClassNames, noOp } from '@neslotech/ui-utils';

import InputAction from './action/InputAction';

import InputError from './error/InputError';
import InputLabel from './label/InputLabel';

import './input.scss';

const getChangeValue = (name, value) => (name ? { [name]: value } : value);

export const Input = ({
  id,
  type,
  name,
  label,
  inputRef,
  error,
  onChange,
  onFocus,
  action,
  onActionClick,
  disabled,
  borderless,
  required,
  value,
  min,
  max,
  placeholder
}) => {
  const wrapperRef = createRef();

  const [active, setActive] = useState(false);
  const [filled, setFilled] = useState(!!value);

  const memoizedLabel = useMemo(() => {
    return label ? (required ? `${label}*` : label) : undefined;
  }, [required, label]);

  useEffect(() => {
    setFilled(!!value);
  }, [value]);

  const handleChange = (e) => {
    let val = e.target.value;
    val = getChangeValue(name, val);
    onChange(val);
  };

  const handleFocus = () => {
    wrapperRef.current?.focus();
    setActive(true);
    onFocus();
  };

  const handleBlur = () => {
    setActive(false);
    setFilled(!!value);
  };

  return (
    <div
      className={getClassNames('input', {
        error,
        active,
        filled,
        disabled,
        borderless
      })}
    >
      <fieldset ref={wrapperRef} className="input__wrapper">
        <input
          id={id ?? name}
          onChange={disabled ? noOp : handleChange}
          onFocus={disabled ? noOp : handleFocus}
          onBlur={disabled ? noOp : handleBlur}
          ref={inputRef}
          type={type}
          name={name}
          value={value}
          placeholder={memoizedLabel ?? placeholder}
          min={min}
          max={max}
          required={required}
          disabled={disabled}
        />
        {label && memoizedLabel && (
          <InputLabel
            label={memoizedLabel}
            id={id ?? name}
            disabled={disabled}
            active={active}
            filled={filled}
            error={!!error}
          />
        )}
        {!error && (
          <InputAction
            action={action}
            onActionClick={disabled ? noOp : onActionClick}
            disabled={disabled}
          />
        )}
      </fieldset>
      {!!error && <InputError error={error} />}
    </div>
  );
};

Input.defaultProps = {
  type: 'text',
  onChange: noOp,
  onFocus: noOp,
  onActionClick: noOp,
  disabled: false,
  borderless: false,
  value: ''
};

Input.propTypes = {
  type: oneOf(['text', 'number', 'email', 'password']),
  name: string,
  label: string,
  placeholder: string,
  inputRef: oneOfType([
    func,
    shape({
      current: instanceOf(Element)
    })
  ]),
  error: string,
  onChange: func,
  onFocus: func,
  onActionClick: func,
  action: oneOfType([string, node]),
  disabled: bool,
  borderless: bool,
  value: oneOfType([number, string])
};
