import { isEmpty } from '@neslotech/ui-utils';

export const searchElement = (value, search) => {
  if (isEmpty(search)) {
    return true;
  }

  if (!value) {
    return false;
  }

  return String(value).toLowerCase().includes(search.toLowerCase());
};

export const filterElement = (element, filters) => {
  if (!filters) {
    return true;
  }

  return Object.keys(filters).every((key) => {
    const value = filters[key];
    if (isEmpty(value)) {
      return true;
    }

    return value.filterValues?.some(
      (filterValue) => filterValue.toLowerCase() === element[key]?.toLowerCase()
    );
  });
};

export const isEmptyFilter = (filters) => {
  if (!filters) {
    return true;
  }

  const keys = Object.keys(filters);
  if (isEmpty(keys)) {
    return true;
  }

  return keys.every((key) => isEmpty(filters[key]));
};
